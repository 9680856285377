import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import BoxContainer from 'components/BoxContainer';
import Listagem, { IRow } from 'components/Listagem';

import { FilterHolder, FilterItemHolder } from 'styles/others';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Select from 'components/Select';
import Input from 'components/Input';
import Button from 'components/Button';
import { RiFilterOffFill } from 'react-icons/ri';
import { FaSearch } from 'react-icons/fa';
import api from 'services/api';
import { AxiosError, AxiosResponse } from 'axios';
import { IDepartamentosDTO, ISelectDTO } from 'utils/DTOS';
import { useToast } from 'hooks/toast';
import { Container, TitleHolder, ListHolder } from '../styles';

export interface IFilter {
  nome?: string;
  departamento?: string;
}

const Serviços: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [filter, setFilter] = useState<IFilter | undefined>(undefined);
  const [departamentos, setDepartamentos] = useState<ISelectDTO[]>([]);

  const { addToast } = useToast();

  const onFilter = useCallback((data: IFilter) => {
    setFilter(data);
  }, []);

  const clearFilter = useCallback(() => {
    formRef.current?.clearField('nome');
    formRef.current?.clearField('departamento');
    setFilter(undefined);
  }, []);

  const getDepartamentos = useCallback(async () => {
    await api
      .get('/departamentos')
      .then(async (res: AxiosResponse) => {
        const tmp_departamentos: IDepartamentosDTO[] = res.data;
        const final_departamentos: ISelectDTO[] = [];

        for (let i = 0; i < tmp_departamentos.length; i++) {
          const tmp_departamento = tmp_departamentos[i];

          final_departamentos.push({
            label: tmp_departamento.nome,
            value: tmp_departamento.id.toString(),
          });
        }

        setDepartamentos(final_departamentos);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os departamentos, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getDepartamentos();
  }, [getDepartamentos]);

  const rows: IRow[] = [
    {
      label: '#',
      data: 'id',
    },
    {
      label: 'Nome',
      data: 'nome',
    },
    {
      label: 'Escopo',
      data: 'escopo',
    },
    {
      label: 'Departamento',
      data: '__departamento__.nome',
    },
    {
      label: 'Recorrente',
      data: 'recorrente',
    },
    {
      label: 'Pontos',
      data: 'pontos',
    },
    {
      label: 'Ativo',
      data: 'fg_ativo',
    },
  ];

  return (
    <Container>
      <TitleHolder>
        Serviços:
        <button
          type="button"
          onClick={() => history.push('/cadastros/servicos/novo')}
        >
          Novo serviço
        </button>
      </TitleHolder>
      <FilterHolder>
        <Form initialData={filter} ref={formRef} onSubmit={onFilter}>
          <FilterItemHolder>
            <label htmlFor="nome">Nome</label>
            <Input name="nome" id="nome" placeholder="Digite aqui..." />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="departamento">Departamento</label>
            <Select
              options={departamentos}
              name="departamento"
              id="departamento"
              placeholder="Digite aqui..."
            />
          </FilterItemHolder>
          <FilterItemHolder className="submit">
            <Button onClick={() => clearFilter()} type="button">
              <RiFilterOffFill size={18} />
            </Button>
            <Button type="submit">
              <FaSearch size={18} />
            </Button>
          </FilterItemHolder>
        </Form>
      </FilterHolder>
      <ListHolder>
        <BoxContainer borderColor="quaternary">
          <Listagem
            filter={filter}
            rows={rows}
            module="servicos"
            moduleLabel="serviços"
            template="100px 1.5fr 4.5fr 1fr 100px 100px 100px"
            getUrl="/servicos/list"
            editUrl="/cadastros/servicos/servico"
            indexUrl="/cadastros/servicos/servico"
            deleteUrl="/servicos/servico"
          />
          {/* <PaginationHolder>
            div.
          </PaginationHolder> */}
        </BoxContainer>
      </ListHolder>
    </Container>
  );
};

export default Serviços;
