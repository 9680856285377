import React from 'react';
import { useHistory } from 'react-router';

import { useToast } from 'hooks/toast';
import BoxContainer from 'components/BoxContainer';
import {
  FaArrowRight,
  FaBuilding,
  FaDiaspora,
  FaHandshake,
  FaSearchLocation,
  FaStreetView,
  FaUser,
  FaUsers,
} from 'react-icons/fa';
import { GrServices } from 'react-icons/gr';
import { MdPayment } from 'react-icons/md';
import { useAuth } from 'hooks/auth';
import LogList from './LogList';

import {
  Container,
  TitleHolder,
  ListHolder,
  ModulesHolder,
  Module,
} from './styles';

const Projeto: React.FC = () => {
  const history = useHistory();

  const { cod_perfil } = useAuth().usuario;

  return (
    <Container>
      <TitleHolder>Cadastros:</TitleHolder>
      <ListHolder>
        <ModulesHolder>
          {cod_perfil !== 4 && (
            <>
              <Module onClick={() => history.push('/cadastros/usuarios')}>
                <FaUser size={20} />
                <span>Usuário</span>
                <span className="arrowRight">
                  <FaArrowRight size={20} />
                </span>
              </Module>
              <Module onClick={() => history.push('/cadastros/departamentos')}>
                <FaUsers size={20} />
                <span>Departamentos</span>
                <span className="arrowRight">
                  <FaArrowRight size={20} />
                </span>
              </Module>
              <Module
                onClick={() => history.push('/cadastros/formas-pagamento')}
              >
                <MdPayment size={20} />
                <span>Formas de Pagamento</span>
                <span className="arrowRight">
                  <FaArrowRight size={20} />
                </span>
              </Module>
              <Module onClick={() => history.push('/cadastros/terceiros')}>
                <FaHandshake size={20} />
                <span>Terceiros</span>
                <span className="arrowRight">
                  <FaArrowRight size={20} />
                </span>
              </Module>
              <Module
                onClick={() => history.push('/cadastros/setores-cliente')}
              >
                <FaBuilding size={20} />
                <span>Setores de Cliente</span>
                <span className="arrowRight">
                  <FaArrowRight size={20} />
                </span>
              </Module>
            </>
          )}
          <Module onClick={() => history.push('/cadastros/servicos')}>
            <GrServices size={20} />
            <span>Serviços</span>
            <span className="arrowRight">
              <FaArrowRight size={20} />
            </span>
          </Module>
          <Module onClick={() => history.push('/cadastros/lps')}>
            <FaSearchLocation size={20} />
            <span>LPS</span>
            <span className="arrowRight">
              <FaArrowRight size={20} />
            </span>
          </Module>
          <Module onClick={() => history.push('/cadastros/clientes')}>
            <FaStreetView size={20} />
            <span>Clientes</span>
            <span className="arrowRight">
              <FaArrowRight size={20} />
            </span>
          </Module>
        </ModulesHolder>
        {/* <BoxContainer borderColor="quaternary">
          <LogList />
          <PaginationHolder>
            div.
          </PaginationHolder>
        </BoxContainer> */}
      </ListHolder>
    </Container>
  );
};

export default Projeto;
