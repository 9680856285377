import React from 'react';
import { useHistory } from 'react-router';

import BoxContainer from 'components/BoxContainer';
import Listagem, { IRow } from 'components/Listagem';

import { Container, TitleHolder, ListHolder } from '../styles';

const FormasPagamento: React.FC = () => {
  const history = useHistory();

  const rows: IRow[] = [
    {
      label: '#',
      data: 'id',
    },
    {
      label: 'Forma de Pagamento',
      data: 'forma_pagamento',
    },
  ];

  return (
    <Container>
      <TitleHolder>
        Formas de Pagamento:
        <button
          type="button"
          onClick={() => history.push('/cadastros/formas-pagamento/novo')}
        >
          Nova forma de pagamento
        </button>
      </TitleHolder>
      <ListHolder>
        <BoxContainer borderColor="quaternary">
          <Listagem
            rows={rows}
            module="formas-pagamento"
            moduleLabel="formas de pagamentos"
            template="100px 4fr"
            editUrl="/cadastros/formas-pagamento/forma-pagamento"
            indexUrl="/cadastros/formas-pagamento/forma-pagamento"
            deleteUrl="/formas-pagamento/forma-pagamento"
          />
          {/* <PaginationHolder>
            div.
          </PaginationHolder> */}
        </BoxContainer>
      </ListHolder>
    </Container>
  );
};

export default FormasPagamento;
