/* eslint-disable no-param-reassign */
import { AxiosError, AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Switch from 'react-switch';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useToast } from 'hooks/toast';
import api from 'services/api';
import {
  ISelectDTO,
  ITarefasDTO,
  IDepartamentosDTO,
  IClientesDTO,
  IUsuariosDTO,
  IServicosDTO,
  ILPSDTO,
} from 'utils/DTOS';

import { RiFilterOffFill } from 'react-icons/ri';
import { FaSearch } from 'react-icons/fa';
import Button from 'components/Button';
import { TarefasKanban } from 'components/TarefasKanban';
import Spinner from 'components/Spinner';
import NovaTarefaModal from 'components/NovaTarefaModal';
import BoxContainer from 'components/BoxContainer';
import ListagemNoGetModal, { IRow } from 'components/Listagem/modal';
import { format } from 'date-fns';
import TarefaModal from 'components/TarefaModal';
import Select from 'components/Select';
import { formatBRDate } from 'utils/formatData';
import { GiEarthCrack } from 'react-icons/gi';
import {
  Container,
  TitleHolder,
  SwitchKanban,
  SwitchFiltro,
  KanbanHolder,
  ListagemHolder,
} from './styles';

const Projeto: React.FC = () => {
  const { addToast } = useToast();
  const [tarefas, setTarefas] = useState<ITarefasDTO[]>([]);
  const [tarefasDep, setTarefasDep] = useState<ITarefasDTO[]>([]);
  const [departamentos, setDepartamentos] = useState<ISelectDTO[]>([]);
  const [departamento, setFilteredDepartamento] = useState<string | undefined>(
    undefined
  );
  const filterRef = useRef<FormHandles>(null);
  const [kanbanActive, setKanbanActive] = useState<boolean>(false);
  const [departamentoAtivo, setDepartamentoAtivo] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [update2, setUpdate2] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [clientes, setClientes] = useState<ISelectDTO[]>([]);
  const [lps, setLps] = useState<ISelectDTO[]>([]);
  const [servicos, setServicos] = useState<ISelectDTO[]>([]);
  const [usuarios, setUsuarios] = useState<ISelectDTO[]>([]);

  const rows: IRow[] = [
    {
      label: 'Nome',
      data: 'nome',
    },
    {
      label: 'Status',
      data: 'status',
    },
    {
      label: 'Data Prazo',
      data: 'data_prazo_formated',
      date_original: 'data_prazo',
    },
    {
      label: 'Data Entrega',
      data: 'data_entrega_formated',
      date_original: 'data_entrega',
    },
    {
      label: 'Responsáveis',
      data: 'users',
    },
    {
      label: 'Cliente',
      data: '__cliente__.nome',
    },
  ];

  const getTarefas = useCallback(async () => {
    setLoading(true);

    await api
      .get('/tarefas/list')
      .then(async (res: AxiosResponse) => {
        res.data.forEach((tarefa: any) => {
          if (tarefa.data_prazo) {
            tarefa.data_prazo_formated = formatBRDate(tarefa.data_prazo);
          }
          if (tarefa.data_entrega) {
            // eslint-disable-next-line no-param-reassign

            tarefa.data_entrega_formated = formatBRDate(tarefa.data_entrega);
          }
          let users = '';
          for (let i = 0; i < tarefa.__usuarios__.length; i++) {
            const usuario = tarefa.__usuarios__[i];

            if (i !== tarefa.__usuarios__.length - 1) {
              users += `${usuario.nome}, `;
            } else {
              users += `${usuario.nome}`;
            }
          }
          tarefa.users = users;
        });

        setTarefas(res.data);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: 'Ocorreu um erro ao buscar as tarefas, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, departamentoAtivo, update, update2]);

  useEffect(() => {
    getTarefas();
  }, [getTarefas]);

  const getTarefasDepartamento = useCallback(async () => {
    setLoading(true);

    if (!departamentoAtivo) {
      return;
    }

    const url =
      departamento !== undefined
        ? `/tarefas/list-departamentos?departamento=${departamento}`
        : '/tarefas/list-departamentos';

    await api
      .get(url)
      .then(async (res: AxiosResponse) => {
        res.data.forEach((tarefa: any) => {
          if (tarefa.data_prazo) {
            tarefa.data_prazo_formated = formatBRDate(tarefa.data_prazo);
          }
          if (tarefa.data_entrega) {
            tarefa.data_entrega_formated = formatBRDate(tarefa.data_entrega);
          }
          let users = '';
          for (let i = 0; i < tarefa.__usuarios__.length; i++) {
            const usuario = tarefa.__usuarios__[i];

            if (i !== tarefa.__usuarios__.length - 1) {
              users += `${usuario.nome}, `;
            } else {
              users += `${usuario.nome}`;
            }
          }
          tarefa.users = users;
        });

        setTarefasDep(res.data);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: 'Ocorreu um erro ao buscar as tarefas, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, departamentoAtivo, departamento, update, update2]);

  useEffect(() => {
    getTarefasDepartamento();
  }, [getTarefasDepartamento]);

  const getDepartamentos = useCallback(async () => {
    setLoading(true);

    await api
      .get('/departamentos')
      .then(async (res: AxiosResponse) => {
        const tmp_departamentos: IDepartamentosDTO[] = res.data;
        const final_departamentos: ISelectDTO[] = [];

        for (let i = 0; i < tmp_departamentos.length; i++) {
          const tmp_departamento = tmp_departamentos[i];

          final_departamentos.push({
            label: tmp_departamento.nome,
            value: tmp_departamento.id.toString(),
          });
        }

        setDepartamentos(final_departamentos);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os departamentos, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast]);

  useEffect(() => {
    getDepartamentos();
  }, [getDepartamentos]);

  const handleFilter = useCallback(data => {
    setFilteredDepartamento(data.departamento);
  }, []);

  const clearFilter = useCallback(() => {
    filterRef.current?.clearField('departamento');
    setFilteredDepartamento(undefined);
  }, []);

  const getClientes = useCallback(async () => {
    await api
      .get('/clientes/wlps')
      .then(async (res: AxiosResponse) => {
        const tmp_clientes: IClientesDTO[] = res.data;
        const final_clientes: ISelectDTO[] = [];

        for (let i = 0; i < tmp_clientes.length; i++) {
          const tmp_cliente = tmp_clientes[i];

          final_clientes.push({
            label: tmp_cliente.nome,
            value: tmp_cliente.id.toString(),
          });
        }

        setClientes(final_clientes);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os clientes, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  const getUsuarios = useCallback(async () => {
    await api
      .get('/usuarios')
      .then(async (res: AxiosResponse) => {
        const tmp_usuarios: IUsuariosDTO[] = res.data;
        const final_usuarios: ISelectDTO[] = [];

        for (let i = 0; i < tmp_usuarios.length; i++) {
          const tmp_usario = tmp_usuarios[i];

          final_usuarios.push({
            label: tmp_usario.nome,
            value: tmp_usario.id.toString(),
          });
        }

        setUsuarios(final_usuarios);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os usuários, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  const getServicos = useCallback(async () => {
    await api
      .get('/servicos/ativos')
      .then(async (res: AxiosResponse) => {
        setServicos(res.data);
        const tmp_servicos: IServicosDTO[] = res.data;
        const final_servicos: ISelectDTO[] = [];

        for (let i = 0; i < tmp_servicos.length; i++) {
          const tmp_Servico = tmp_servicos[i];

          if (!tmp_Servico.nome) {
            return;
          }

          final_servicos.push({
            label: tmp_Servico.nome,
            value: tmp_Servico.id.toString(),
          });
        }

        setServicos(final_servicos);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os serviços, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  const getLps = useCallback(async () => {
    await api
      .get('/lps')
      .then(async (res: AxiosResponse) => {
        const tmp_lps: ILPSDTO[] = res.data;
        const final_lps: ISelectDTO[] = [];

        for (let i = 0; i < tmp_lps.length; i++) {
          const tmp_lpss = tmp_lps[i];

          final_lps.push({
            label: tmp_lpss.nome,
            value: tmp_lpss.id.toString(),
          });
        }

        setLps(final_lps);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: 'Ocorreu um erro ao buscar os lps, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getClientes();
  }, [getClientes]);

  useEffect(() => {
    getServicos();
  }, [getServicos]);

  useEffect(() => {
    getLps();
  }, [getLps]);

  useEffect(() => {
    getUsuarios();
  }, [getUsuarios]);

  return (
    <Container>
      <TitleHolder>
        Tarefas:
        <SwitchKanban>
          Visualização de Kanban
          <Switch
            checked={kanbanActive}
            onChange={setKanbanActive}
            className="switch"
            onColor="#61f534"
          />
        </SwitchKanban>
        <NovaTarefaModal setUpdate={setUpdate} update={update}>
          Nova tarefa
        </NovaTarefaModal>
      </TitleHolder>
      <SwitchFiltro className="filtro">
        Minhas tarefas
        <Switch
          checked={departamentoAtivo}
          onChange={setDepartamentoAtivo}
          className="switch"
          onColor="#f58634"
        />
        Tarefas do Departamento
        {departamentoAtivo && (
          <Form ref={filterRef} onSubmit={handleFilter}>
            <Select
              name="departamento"
              options={departamentos}
              placeholder="Departamento"
            />
            <Button onClick={() => clearFilter()} type="button">
              <RiFilterOffFill size={18} />
            </Button>
            <Button type="submit">
              <FaSearch size={18} />
            </Button>
          </Form>
        )}
      </SwitchFiltro>
      {kanbanActive ? (
        <KanbanHolder>
          <KanbanHolder>
            {loading ? (
              <Spinner size={50} />
            ) : (
              <TarefasKanban
                setUpdate={setUpdate}
                update={update}
                tarefas={tarefas}
                extraData={{
                  clientes,
                  lps,
                  servicos,
                  usuarios,
                }}
              />
            )}
          </KanbanHolder>
        </KanbanHolder>
      ) : (
        <ListagemHolder>
          <BoxContainer borderColor="quaternary">
            {departamentoAtivo ? (
              <ListagemNoGetModal
                data={tarefasDep}
                rows={rows}
                module="tarefas"
                template="0.6fr 240px 0.4fr 0.4fr 0.5fr 1fr"
                Modal={TarefaModal}
                setUpdate={setUpdate}
                update={update}
                setUpdate2={setUpdate2}
                update2={update2}
                loading={loading}
                deleteUrl="/tarefas/tarefa"
                breakLine
                extraData={{
                  clientes,
                  lps,
                  servicos,
                  usuarios,
                }}
              />
            ) : (
              <ListagemNoGetModal
                data={tarefas}
                rows={rows}
                module="tarefas"
                template="0.6fr 240px 0.4fr 0.4fr 0.5fr 1fr"
                Modal={TarefaModal}
                setUpdate={setUpdate}
                update={update}
                setUpdate2={setUpdate2}
                update2={update2}
                loading={loading}
                deleteUrl="/tarefas/tarefa"
                breakLine
                extraData={{
                  clientes,
                  lps,
                  servicos,
                  usuarios,
                }}
              />
            )}
          </BoxContainer>
        </ListagemHolder>
      )}
    </Container>
  );
};

export default Projeto;
