/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';

import Input from 'components/Input';
import { IPagamentosProjetoDTO, ISelectDTO } from 'utils/DTOS';
import Textarea from 'components/Input/textarea';
import Checkbox from 'components/Checkbox';
import InputCurrency from 'components/Input/currency';

// Styled components
import Select from 'components/Select';
import { Title, FormContainer, FormRow, InputHolder } from './styles';

interface IModalAttatchment {
  pagamento: IPagamentosProjetoDTO;
}

const PagamentoProjetoModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);

  const { children, pagamento } = props;

  const condicoes_pagamento: ISelectDTO[] = [
    {
      label: 'Parcela',
      value: 0,
    },
    {
      label: 'Medição',
      value: 1,
    },
    {
      label: 'Gatilho',
      value: 2,
    },
  ];

  const gatilhos = [
    {
      label: 'Protocolo',
      value: 0,
    },
    {
      label: 'Conclusão',
      value: 1,
    },
    {
      label: 'Entrega Parcial',
      value: 3,
    },
    {
      label: 'Outros 1',
      value: 4,
    },
    {
      label: 'Outros 2',
      value: 5,
    },
    {
      label: 'Outros 3',
      value: 2,
    },
  ];

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      <button
        type="button"
        style={{ height: '100%' }}
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal secundary"
        wrapClassName="wrapForm"
      >
        <Title>
          Edição de Pagamento - #{pagamento && pagamento.id.toString()} -
          {` ${pagamento.des_status}`}
        </Title>
        <FormContainer>
          <Form initialData={pagamento} ref={formRef} onSubmit={console.log}>
            <FormRow>
              <InputHolder>
                <label htmlFor="valor">Valor</label>
                <InputCurrency name="valor" readonly />
              </InputHolder>
              <InputHolder>
                <label htmlFor="condicao_pagamento">
                  Condição de pagamento
                </label>
                <Select
                  name="condicao_pagamento"
                  options={condicoes_pagamento}
                  readonly
                />
              </InputHolder>
            </FormRow>
            {pagamento.condicao_pagamento === 0 ? (
              <FormRow>
                <InputHolder>
                  <label htmlFor="vencimento">Vencimento</label>
                  <Input type="date" name="vencimento" readonly />
                </InputHolder>
              </FormRow>
            ) : pagamento.condicao_pagamento === 1 ? (
              <FormRow>
                <InputHolder>
                  <label htmlFor="vencimento">Vencimento</label>
                  <Input type="date" name="vencimento" readonly />
                </InputHolder>
                <InputHolder>
                  <label htmlFor="porcentagem">Porcentagem</label>
                  <Input type="number" name="porcentagem" readonly />
                </InputHolder>
              </FormRow>
            ) : (
              <>
                <FormRow>
                  <InputHolder>
                    <label htmlFor="gatilho">Gatilho*</label>
                    <Select name="gatilho" options={gatilhos} readonly />
                  </InputHolder>
                  <InputHolder>
                    <label htmlFor="vencimento">Previsão*</label>
                    <Input name="vencimento" type="date" readonly />
                  </InputHolder>
                </FormRow>
                <FormRow>
                  <InputHolder>
                    <label htmlFor="obs_gatilho">Observação do Gatilho</label>
                    <Textarea
                      name="obs_gatilho"
                      placeholder="Observação do Gatilho"
                      readonly
                    />
                  </InputHolder>
                </FormRow>
              </>
            )}
            <FormRow>
              <InputHolder>
                <label htmlFor="valor_liquido">Valor Líquido</label>
                <InputCurrency name="valor_liquido" readonly />
              </InputHolder>
              <InputHolder>
                <label htmlFor="data_pagamento">Data do Pagamento</label>
                <Input type="date" name="data_pagamento" readonly />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="nf">Nota Fiscal</label>
                <Input placeholder="Nº NF" name="nf" readonly />
              </InputHolder>
              <InputHolder>
                <label htmlFor="cepom">Cepom</label>
                <Input placeholder="Cepom" name="cepom" readonly />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <Checkbox
                  options={[
                    {
                      label: 'Stand-by',
                      value: 'true',
                      name: 'stand-by',
                      marked: !!pagamento.standBy,
                    },
                  ]}
                  name="stand-by"
                  readonly
                />
              </InputHolder>
              <InputHolder>
                <Checkbox
                  options={[
                    {
                      label: 'Cancelado',
                      value: 'true',
                      name: 'Cancelado',
                      marked: !!pagamento.cancelado,
                    },
                  ]}
                  name="cancelado"
                  readonly
                />
              </InputHolder>
              <InputHolder>
                <Checkbox
                  options={[
                    {
                      label: 'Possuí vinculo financeiro?',
                      value: 'true',
                      name: 'vinculo_financeiro',
                      marked: !!pagamento.vinculo_financeiro,
                    },
                  ]}
                  name="vinculo_financeiro"
                  readonly
                />
              </InputHolder>
            </FormRow>
          </Form>
        </FormContainer>
      </Modal>
    </>
  );
};

export default PagamentoProjetoModal;
