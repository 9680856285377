import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router';

import FormConstructor, { IInput, ISaveBtn } from 'components/FormConstructor';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import { IDepartamentosDTO, ISelectDTO } from 'utils/DTOS';
import getValidationErrors from 'utils/getValidationErrors';

import { Container, Title, Desc } from '../novo-styles';

const NovoServico: React.FC = () => {
  const module = 'servico';
  const module_label = 'Serviços';
  const [departamentos, setDepartamentos] = useState<ISelectDTO[]>([]);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const saveBtn: ISaveBtn = {
    label: 'Enviar',
    loading,
  };

  const inputs: IInput[] = [
    {
      name: 'nome',
      label: 'Nome',
      tipo: 'input',
      required: true,
    },
    {
      name: 'escopo',
      label: 'Escopo',
      tipo: 'textarea',
      required: true,
    },
    {
      name: 'departamento_id',
      label: 'Departamento',
      tipo: 'select',
      selectData: departamentos,
      required: true,
    },
    {
      name: 'recorrente',
      label: 'Recorrente',
      tipo: 'checkbox',
      required: true,
    },
    {
      name: 'pontos',
      label: 'Pontos',
      tipo: 'input',
      type: 'number',
      required: true,
    },
  ];

  const history = useHistory();

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: any) => {
      // eslint-disable-next-line no-param-reassign
      data.recorrente = data.recorrente[0] === 'true';
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          escopo: Yup.string().required('Escopo obrigatório'),
          departamento_id: Yup.string().required('Departamento obrigatório'),
          recorrente: Yup.string().required('Recorrência obrigatória'),
          pontos: Yup.string().required('Pontuação obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api
          .post(`/${module}s`, data)
          .then((res: AxiosResponse) => {
            const { id } = res.data;
            addToast({
              type: 'success',
              title: `${module_label}${
                id && ` de id: ${id}`
              } criado com sucesso`,
            });
            history.push(`/cadastros/${module}s`);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: err.message || `Erro na criação do ${module}`,
          description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [addToast, history]
  );

  const getDepartamentos = useCallback(async () => {
    await api
      .get('/departamentos')
      .then(async (res: AxiosResponse) => {
        const tmp: IDepartamentosDTO[] = res.data;
        const final: ISelectDTO[] = [];

        for (let i = 0; i < tmp.length; i++) {
          const tmp1 = tmp[i];

          final.push({
            label: tmp1.nome,
            value: tmp1.id.toString(),
          });
        }

        setDepartamentos(final);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: `Ocorreu um erro ao buscar os departamentos, tente novamente.`,
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getDepartamentos();
  }, [getDepartamentos]);

  return (
    <Container>
      <Title>Novo {module_label}:</Title>
      <Desc>
        Campos com <b>* (asterisco)</b> são obrigatórios
      </Desc>
      <FormConstructor
        color="quaternary"
        formColumns="1fr"
        formRef={formRef}
        inputs={inputs}
        saveBtn={saveBtn}
        submitFnc={handleSubmit}
      />
    </Container>
  );
};

export default NovoServico;
