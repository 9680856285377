import styled, { css } from 'styled-components';

import Button from 'components/Button';
import BoxContainer from 'components/BoxContainer';

interface ContainerProps {
  error?: boolean;
  ok?: boolean;
}

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 2%;
  }
`;

export const Title = styled.h1`
  width: 100%;
  padding: 0 15px 5px;
  margin-bottom: 0;

  border-bottom: 1px solid rgb(175 175 175 / 30%);

  color: var(--text-color-2);
  font-size: 2.4rem;
  font-weight: 600;
`;

export const Desc = styled.span`
  width: 100%;
  padding: 5px 15px 0;

  color: var(--text-color-3);
  font-size: 1.1rem;
  font-weight: 300;

  b {
    font-weight: 600;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 30px 10px;

  form {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const SectionTitle = styled.h2`
  width: 100%;
  padding: 0 15px 5px;

  margin: 40px 0 10px;

  /* &:first-of-type {
    margin-top: 0px;
  } */

  border-bottom: 1px solid rgb(175 175 175 / 30%);

  color: var(--text-color-2);
  font-size: 1.8rem;
  font-weight: 500;
`;

export const FormRow = styled.div`
  width: 100%;
  height: 100%;

  margin-bottom: 20px;
  padding: 0 15px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 0;
  }
`;

export const InputHolder = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  margin: 0 10px;

  &:first-of-type {
    margin-left: 0px;
  }
  &:last-of-type {
    margin-right: 0px;
  }

  .checkboxHolder {
    height: 44px;
    justify-content: center;
    align-items: flex-start;
    span {
      font-size: 1.4rem;
    }
    input[type='checkbox'] {
      transform: scale(1.2);
      margin-right: 10px;
    }
  }

  label {
    font-size: 1.15rem;
    margin-bottom: 3px;
  }

  @media (max-width: 768px) {
    width: 100% !important;
    margin: 10px 0;
  }
`;

export const ValorHolder = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-of-type {
    margin-bottom: 10px;
  }

  label {
    width: 25%;
  }

  .inputHolder {
    width: 75%;
  }
`;

export const SaldoHolder = styled.div<ContainerProps>`
  width: 100%;
  height: 38px;
  padding: 20px 16px;
  flex: 1 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: 2px solid #777;
  border-radius: 8px;

  box-shadow: 0 0 4px var(--shadow-color);
  background: var(--box-bg-color);

  span {
    color: var(--text-color-3);
    font-size: 1.3rem;
  }

  ${props =>
    props.error &&
    css`
      span {
        color: var(--error-color);
      }
      border-color: var(--error-color);
    `}
  ${props =>
    props.ok &&
    css`
      span {
        color: var(--success-color);
      }
      border-color: var(--success-color);
    `}
`;

export const NovoPagamento = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background: var(--box-bg-color);
  box-shadow: 2px 2px 4px var(--shadow-color);
  border-radius: 8px;

  width: 65%;
  height: 100%;
  padding: 20px;

  margin-left: 15px;

  button.adicionar {
    width: 100%;
    margin-top: 26px;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: var(--main-color);
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    width: 100% !important;
    flex-direction: column;
  }
`;

export const AddButton = styled.button`
  margin-top: 10px;

  padding: 2px 50px;

  background-color: var(--primary-color);

  font-size: 1.2rem;
  color: var(--text-color-1);

  border-radius: 5px;
  box-shadow: 0 0 10px var(--shadow-color);

  transition: 0.3s all;

  &:hover {
    box-shadow: 0 0 20px var(--shadow-color);
  }
`;

export const SaveButton = styled(Button)`
  margin-top: 100px;

  width: calc(50% + 30px);
  height: 50px;

  background-color: var(--primary-color);
  color: var(--text-color-1);
  border: none;
  border-radius: 30px;
`;
