/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, {
  useState,
  useCallback,
  SetStateAction,
  useRef,
  useEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';
import * as Yup from 'yup';

import Input from 'components/Input';
import Spinner from 'components/Spinner';
import getValidationErrors from 'utils/getValidationErrors';
import Select from 'components/Select';
import Textarea from 'components/Input/textarea';
import { ISelectDTO } from 'utils/DTOS';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

// Styled components
import {
  Title,
  FormContainer,
  SaveButton,
  FormRow,
  InputHolder,
} from './styles';

interface IModalAttatchment {
  update?: boolean;
  setUpdate?: React.Dispatch<SetStateAction<boolean>>;
  projeto_id?: string;
  usuarios: ISelectDTO[];
}

const RegistrarChatModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [usuariosDiff, setUsuariosDiff] = useState<ISelectDTO[]>([]);

  const { children, setUpdate, update, projeto_id, usuarios } = props;

  const { usuario } = useAuth();

  const getUsuarios = useCallback(() => {
    setUsuariosDiff(
      usuarios.filter(act => act.value !== usuario.id.toString())
    );
  }, [usuarios, usuario]);

  useEffect(() => {
    getUsuarios();
  }, [getUsuarios]);

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      data.projeto_id = projeto_id;
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          titulo: Yup.string().required('Título obrigatório'),
          mensagem: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        api
          .post(`/chats`, data)
          .then(res => {
            addToast({
              type: 'success',
              title: `Registro de chat no projeto: #${projeto_id} criado com sucesso}`,
            });
            setUpdate && setUpdate(!update);
            handleCancel();
          })
          .catch(err => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description:
                'Ocorreu um erro ao registrar o chat, cheque as informações e tente novamente.',
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro no registro de chat',
          description:
            'Ocorreu um erro ao registrar o chat, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update, handleCancel]
  );

  return (
    <>
      <button
        type="button"
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal secundary"
        wrapClassName="wrapForm"
      >
        <Title>Registrar novo chat - Projeto #{projeto_id}</Title>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormRow>
              <InputHolder>
                <label htmlFor="titulo">Título *</label>
                <Input name="titulo" placeholder="Título" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="usuario_marcado_id">Marcar usuário</label>
                <Select options={usuariosDiff} name="usuario_marcado_id" />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="mensagem">Mensagem</label>
                <Textarea placeholder="Mensagem" name="mensagem" />
              </InputHolder>
            </FormRow>
            <SaveButton
              type="button"
              onClick={() => formRef.current?.submitForm()}
            >
              {loading ? <Spinner size={15} /> : 'Registrar'}
            </SaveButton>
          </Form>
        </FormContainer>
      </Modal>
    </>
  );
};

export default RegistrarChatModal;
