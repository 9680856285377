/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Checkbox from 'components/Checkbox';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import Input from 'components/Input';
import NovoHistoricoModal from 'components/NovoHistoricoModal';
import Spinner from 'components/Spinner';
import { parseISO, format } from 'date-fns';
import { useToast } from 'hooks/toast';
import React, { SetStateAction, useCallback, useRef, useState, useEffect } from 'react';
import { FaSave, FaTrash } from 'react-icons/fa';
import { IoIosAdd } from 'react-icons/io';
import api from 'services/api';
import { IPagamentosProjetoDTO, IProjetosDTO } from 'utils/DTOS';
import { formatBRDate } from 'utils/formatData';
import InputCurrency from 'components/Input/currency';
import { AxiosResponse, AxiosError } from 'axios';
import { formatDateInput } from 'utils/formatData';

import {
  RowCentered,
  RowSpacedBetween,
  SetorHolder,
  Title,
  HistoricosHolder,
  Historico,
  NovoBtn,
  RowFlex,
  InputHolder,
  SaldoHolder,
} from './styles';

interface IProps {
  update: boolean;
  setUpdate: React.Dispatch<SetStateAction<boolean>>;
  projeto_data: IProjetosDTO;
  perfilUsuario: number;
}

interface IPagamentoProjeto extends IPagamentosProjetoDTO {
  editing: boolean;
  vencimentoString: string;
}

const AbaComercial: React.FC<IProps> = props => {
  const { projeto_data, setUpdate, update, perfilUsuario } = props;

  const { id: projeto_id, historicos } = projeto_data;

  const [loading, setLoading] = useState<boolean>(false);
  const [saldo, setSaldo] = useState<number>(0);
  const [pagamentos, setPagamentos] = useState<IPagamentoProjeto[]>([]);

  const valorTotalRef = useRef<FormHandles>(null);
  const dataEncerramentoRef = useRef<FormHandles>(null);

  const {addToast} = useToast();

  const editValorTotal = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        dat.valor_total = tratarValorTotal(dat.valor_total);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Valor total editado com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar o valor total do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição do valor total',
          description:
            'Ocorreu um erro ao editar o valor total do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editDataEncerramento = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        dat.recorrente[0] === 'true'
          ? (dat.recorrente = true)
          : (dat.recorrente = false);

        api
          .put(`/projetos/projeto/${projeto_data.id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Data de encerramento editada com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar a data de encerramento do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição da data de encerramento',
          description:
            'Ocorreu um erro ao editar a data de encerramento do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_data, setUpdate, update]
  );

  const editDataEncerramentoContrato = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        api
          .put(`/projetos/projeto/${projeto_data.id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Data de Encerramento de Contrato editada com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar a Data de Encerramento de Contrato`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição da Data de Encerramento de Contrato',
          description:
            'Ocorreu um erro ao editar a Data de Encerramento de Contrato do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_data, setUpdate, update]
  );

  const tratarValorTotal = useCallback((valor: string) => {
    const valorString = valor
      .replace('R$ ', '')
      .replace('.', '')
      .replace(',', '.');

    const split = valorString.split('.');

    if (
      split.length > 1 &&
      split[split.length - 1] &&
      split[split.length - 1].length === 3
    ) {
      split[split.length - 1] = split[split.length - 1].substring(
        0,
        split[split.length - 1].length - 1
      );
    }

    const valorFinal = parseFloat(`${split[0]}.${split[1]}`);

    return valorFinal || 0;
  }, []);

  const getPagamentos = useCallback(async () => {
    setLoading(true);
    if (!projeto_id) {
      return;
    }
    await api
      .get(`/pagamentos-projetos/projeto/${projeto_id}`)
      .then(async (res: AxiosResponse) => {
        res.data.forEach((dat: any) => {
          dat.editing = false;
          dat.vencimentoString = formatBRDate(dat.vencimento);
          dat.vencimento = dat.vencimento
            ? formatDateInput(dat.vencimento)
            : undefined;
        });
        setPagamentos(res.data);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os pagamentos do projeto, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, projeto_id, update]);

  useEffect(() => {
    getPagamentos();
  }, [getPagamentos]);

  const ajustarSaldo = useCallback(
    (vtotal?: number) => {
      if (!valorTotalRef.current) {
        return;
      }

      let valor_total = 0;

      if (!vtotal) {
        valor_total = tratarValorTotal(
          valorTotalRef.current.getFieldValue('valor_total')
        );
      } else {
        valor_total = vtotal;
      }

      let tmp_saldo = valor_total;

      for (let i = 0; i < pagamentos.length; i++) {
        const pagamento = pagamentos[i];

        tmp_saldo -= pagamento.valor;
      }

      setSaldo(tmp_saldo);
    },
    [tratarValorTotal, pagamentos, setSaldo]
  );

  useEffect(() => {
    ajustarSaldo();
  }, [ajustarSaldo]);

  const valorTotalChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      ajustarSaldo(tratarValorTotal(e.target.value));
    },
    [ajustarSaldo, tratarValorTotal]
  );

  return (
    <SetorHolder>
      {perfilUsuario !== 4 && (
        <RowFlex id="valor-total">
          <Form
            ref={valorTotalRef}
            onSubmit={editValorTotal}
            initialData={projeto_data && projeto_data}
          >
            <label htmlFor="valor_total">
              <Title>Valor total:</Title>
            </label>

            <SaldoHolder
              ok={!saldo || saldo === 0}
              error={!!saldo && saldo !== 0}
            >
              <span>
                Saldo:{' '}
                {saldo ? `R$ ${saldo.toFixed(2).replace('.', ',')}` : `R$ 0,00`}
              </span>
            </SaldoHolder>

            <InputCurrency
              style={{ width: '30%' }}
              name="valor_total"
              onChange={e => valorTotalChange(e)}
              placeholder="Digite o valor total"
            />
            <NovoBtn
              onClick={e => {
                e.preventDefault();
                valorTotalRef.current?.submitForm();
              }}
              className="justSvg"
              style={{ marginLeft: 20, width: '5%' }}
              type="button"
            >
              <FaSave size={25} />
            </NovoBtn>
          </Form>
        </RowFlex>
      )}
      {projeto_data.recorrente ? (
        <RowFlex id="data_encerramento">
          <Form
            ref={dataEncerramentoRef}
            onSubmit={editDataEncerramento}
            initialData={projeto_data && projeto_data}
          >
            {loading ? (
              <Spinner size={10} />
            ) : (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <InputHolder style={{ width: '30%' }}>
                  <label htmlFor="recorrente">Recorrência:</label>
                  <Checkbox
                    options={[
                      {
                        label: 'Recorrente',
                        value: 'true',
                        name: 'recorrente',
                        marked: projeto_data && projeto_data.recorrente,
                      },
                    ]}
                    name="recorrente"
                  />
                </InputHolder>
                <InputHolder style={{ width: '30%' }}>
                  <label htmlFor="tempo_contrato_mes">Tempo de Contrato / Mês:</label>
                  <Input type="number" name="tempo_contrato_mes" />
                </InputHolder>
                <InputHolder style={{ width: '30%' }}>
                  <label htmlFor="data_encerramento">Data de Encerramento:</label>
                  <Input type="date" name="data_encerramento" placeholder="Data de Encerramento" />
                </InputHolder>
                <InputHolder style={{ width: '4%' }}>
                  <NovoBtn
                    style={{ marginTop: '26px' }}
                    className="justSvg"
                    onClick={e => {
                      e.preventDefault();
                      dataEncerramentoRef.current?.submitForm();
                    }}
                    type="button"
                  >
                    <FaSave size={25} />
                  </NovoBtn>
                </InputHolder>
              </div>
            )}
          </Form>
        </RowFlex>
      ) : (
        <RowFlex id="data_encerramento">
          <Form
            ref={dataEncerramentoRef}
            onSubmit={editDataEncerramentoContrato}
            initialData={projeto_data && projeto_data}
          >
            <Title style={{ marginRight: 20, width: '20%' }}>
              <label htmlFor="data_encerramento">Data de Encerramento de Contrato:</label>
            </Title>
            <Input type="date" name="data_encerramento" placeholder="Data de Encerramento de Contrato" />
            <NovoBtn
              className="justSvg"
              onClick={e => {
                e.preventDefault();
                dataEncerramentoRef.current?.submitForm();
              }}
              type="button"
              style={{ marginLeft: 20, width: 50 }}
            >
              <FaSave size={25} />
            </NovoBtn>
          </Form>
        </RowFlex>
      )}
      <RowSpacedBetween style={{ marginBottom: 10 }} id="historico">
        <Title>Histórico:</Title>
        <NovoHistoricoModal
          setUpdate={setUpdate}
          update={update}
          projeto_id={projeto_data.id.toString()}
        >
          <NovoBtn type="button">
            <IoIosAdd size={25} />
            Novo
          </NovoBtn>
        </NovoHistoricoModal>
      </RowSpacedBetween>
      <RowCentered id="historico-tabela">
        <HistoricosHolder>
          {historicos.length !== 0 ? historicos.map(historico => {
            const dataContatoFormatted = formatBRDate(historico.data_contato.toString());

            return (
              <Historico key={historico.id}>
                <div className="text">
                  <strong>
                    Assunto: <span>{historico.assunto}</span>
                  </strong>
                  <p>{historico.texto}</p>
                </div>
                <div className="contact">
                  <span className="date">
                    {dataContatoFormatted && formatBRDate(dataContatoFormatted)}
                  </span>
                  <span className="contato">{historico.pessoa_contato}</span>
                  <span className="delete">
                    <ConfirmDeleteModal
                      delete_url="/historicos/historico"
                      id={historico.id.toString()}
                      module="historico"
                      setUpdate={setUpdate}
                      update={update}
                    >
                      <FaTrash />
                    </ConfirmDeleteModal>
                  </span>
                </div>
              </Historico>
              )})
            : 'Nenhum histórico registrado'}
        </HistoricosHolder>
      </RowCentered>
    </SetorHolder>
  );
};

export default AbaComercial;
