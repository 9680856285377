import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import BoxContainer from 'components/BoxContainer';
import Listagem, { IRow } from 'components/Listagem';

import { FilterHolder, FilterItemHolder } from 'styles/others';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Select from 'components/Select';
import Input from 'components/Input';
import Button from 'components/Button';
import { RiFilterOffFill } from 'react-icons/ri';
import { FaSearch } from 'react-icons/fa';
import { Container, TitleHolder, ListHolder } from '../styles';

export interface IFilter {
  nome?: string;
  endereco?: string;
  cidade?: string;
}

const LPS: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [filter, setFilter] = useState<IFilter | undefined>(undefined);

  const onFilter = useCallback((data: IFilter) => {
    setFilter(data);
  }, []);

  const clearFilter = useCallback(() => {
    formRef.current?.clearField('nome');
    formRef.current?.clearField('endereco');
    formRef.current?.clearField('cidade');
    setFilter(undefined);
  }, []);

  const rows: IRow[] = [
    {
      label: '#',
      data: 'id',
    },
    {
      label: 'Nome',
      data: 'nome',
    },
    {
      label: 'Endereço',
      data: 'endereco',
    },
    {
      label: 'Bairro',
      data: 'bairro',
    },
    {
      label: 'Cidade',
      data: 'cidade',
    },
    {
      label: 'CEP',
      data: 'cep',
    },
    {
      label: 'Contato Téc.',
      data: 'contato_tecnico',
    },
    {
      label: 'Telefone Téc.',
      data: 'telefone_tecnico',
    },
    {
      label: 'E-mail Téc.',
      data: 'email_tecnico',
      style: {
        textTransform: 'none',
      },
    },
  ];

  return (
    <Container>
      <TitleHolder>
        LPS:
        <button
          type="button"
          onClick={() => history.push('/cadastros/lps/novo')}
        >
          Novo lps
        </button>
      </TitleHolder>
      <FilterHolder>
        <Form initialData={filter} ref={formRef} onSubmit={onFilter}>
          <FilterItemHolder>
            <label htmlFor="nome">Nome</label>
            <Input name="nome" id="nome" placeholder="Digite aqui..." />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="endereco">Endereço</label>
            <Input name="endereco" id="endereco" placeholder="Digite aqui..." />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="cidade">Cidade</label>
            <Input name="cidade" id="cidade" placeholder="Digite aqui..." />
          </FilterItemHolder>
          <FilterItemHolder className="submit">
            <Button onClick={() => clearFilter()} type="button">
              <RiFilterOffFill size={18} />
            </Button>
            <Button type="submit">
              <FaSearch size={18} />
            </Button>
          </FilterItemHolder>
        </Form>
      </FilterHolder>
      <ListHolder>
        <BoxContainer borderColor="quaternary">
          <Listagem
            filter={filter}
            rows={rows}
            module="lps"
            template="100px 2.5fr 2.5fr 1.5fr 1.5fr 1.5fr 2fr 2fr 3.5fr"
            editUrl="/cadastros/lps/lps-id"
            indexUrl="/cadastros/lps/lps-id"
            deleteUrl="/lps/lps-id"
          />
          {/* <PaginationHolder>
            div.
          </PaginationHolder> */}
        </BoxContainer>
      </ListHolder>
    </Container>
  );
};

export default LPS;
