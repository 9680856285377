import React, { useState, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';

import Input from 'components/Input';
import { ITerceirosProjetoDTO } from 'utils/DTOS';

// Styled components
import Textarea from 'components/Input/textarea';
import InputCurrency from 'components/Input/currency';
import { Title, FormContainer, FormRow, InputHolder } from './styles';

interface IModalAttatchment {
  terceiro: ITerceirosProjetoDTO;
  novalue?: boolean;
}

const TerceiroModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);

  const { children, terceiro: terceiroProj, novalue } = props;

  const { __terceiro__: terceiro } = terceiroProj;

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      <button
        type="button"
        style={{ height: '100%' }}
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal secundary"
        wrapClassName="wrapForm"
      >
        <Title>
          Informações do serviço/terceiro - #{terceiro.id.toString()}
        </Title>
        <FormContainer>
          <Form initialData={terceiroProj} ref={formRef} onSubmit={console.log}>
            {!novalue && (
              <FormRow>
                <InputHolder>
                  <label htmlFor="valor">Valor</label>
                  <InputCurrency name="valor" readonly />
                </InputHolder>
              </FormRow>
            )}
            <FormRow>
              <InputHolder>
                <label htmlFor="descricao">Descrição do serviço</label>
                <Textarea name="descricao" readonly />
              </InputHolder>
            </FormRow>
          </Form>
          <Form initialData={terceiro} ref={formRef} onSubmit={console.log}>
            <FormRow>
              <InputHolder>
                <label htmlFor="nome">Nome</label>
                <Input name="nome" readonly />
              </InputHolder>
              <InputHolder>
                <label htmlFor="cpf_cnpj">CPF/CNPJ</label>
                <Input name="cpf_cnpj" readonly />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="telefone">Telefone</label>
                <Input name="telefone" readonly />
              </InputHolder>
              <InputHolder>
                <label htmlFor="email">E-mail</label>
                <Input name="email" readonly />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="banco">Banco</label>
                <Input name="banco" readonly />
              </InputHolder>
              <InputHolder>
                <label htmlFor="agencia">Agência</label>
                <Input name="agencia" readonly />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="conta_corrente">Conta Corrente</label>
                <Input name="conta_corrente" readonly />
              </InputHolder>
              <InputHolder>
                <label htmlFor="chave_pix">Chave PIX</label>
                <Input name="chave_pix" readonly />
              </InputHolder>
            </FormRow>
          </Form>
        </FormContainer>
      </Modal>
    </>
  );
};

export default TerceiroModal;
