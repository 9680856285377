/* eslint-disable no-param-reassign */
import EditInTable from 'components/EditInTable';
import Spinner from 'components/Spinner';
import { useToast } from 'hooks/toast';
import React, { SetStateAction, useCallback } from 'react';
import { FaCheck, FaPencilAlt, FaTimes, FaTrash } from 'react-icons/fa';

import { InputProps } from 'components/EditInTable';
import { Line, List, LineItem } from 'styles/others';
import { IPagamentosProjetoDTO, ISelectDTO } from 'utils/DTOS';
import { v4 } from 'uuid';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import PagamentoProjetoModal from 'components/PagamentoProjetoModal';

interface IProps {
  loading: boolean;
  update: boolean;
  setUpdate: React.Dispatch<SetStateAction<boolean>>;
  pagamentos: IPagamentoProjeto[];
  setPagamentos: React.Dispatch<SetStateAction<IPagamentoProjeto[]>>;
}

interface IPagamentoProjeto extends IPagamentosProjetoDTO {
  editing: boolean;
  vencimentoString: string;
}

const PagamentosProjetoList: React.FC<IProps> = props => {
  const { loading, pagamentos, setPagamentos, update, setUpdate } = props;

  const { addToast } = useToast();

  const toggleEdit = useCallback(
    (id: number, state: number) => {
      const tmp_pagamentos = pagamentos.filter(
        pagamento => pagamento.id !== id
      );

      const editing = pagamentos.find(pagamento => pagamento.id === id);

      if (!editing) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao editar o pagamento, tente novamente.',
        });
        return;
      }

      editing.editing = !editing.editing;

      state === 1 && setUpdate(!update);

      setPagamentos([editing, ...tmp_pagamentos]);
    },
    [addToast, pagamentos, update]
  );

  const condicoesData: ISelectDTO[] = [
    {
      label: 'Parcela',
      value: '0',
    },
    {
      label: 'Medição',
      value: '1',
    },
    {
      label: 'Gatilho',
      value: '2',
    },
  ];

  const inputData: InputProps[] = [
    {
      data: 'id',
      name: 'id',
      label: '#',
      tipo: 'input',
      noInput: true,
    },
    {
      data: 'des_condicao_pagamento',
      label: 'Condição de pagamento',
      name: 'des_condicao_pagamento',
      tipo: 'select',
      selectData: condicoesData,
      noInput: true,
    },
    {
      data: 'valor',
      label: 'Valor',
      name: 'valor',
      tipo: 'currency',
    },
    {
      data: 'vencimento',
      label: 'Vencimento',
      name: 'vencimento',
      tipo: 'input',
      type: 'date',
    },
    {
      data: 'nf',
      label: 'NF',
      name: 'nf',
      tipo: 'input',
    },
    {
      data: 'vinculo_financeiro',
      label: 'Vínculo Financeiro',
      name: 'vinculo_financeiro',
      tipo: 'checkbox',
      noInput: true,
    },
  ];

  return (
    <>
      {loading ? (
        <Spinner size={15} />
      ) : pagamentos.length !== 0 ? (
        <List>
          <Line
            style={{
              gridTemplateColumns: '100px 2.5fr 2fr 2fr 1.5fr 0.5fr 100px',
            }}
            className="indice"
          >
            <LineItem className="indice">#</LineItem>
            <LineItem className="indice">Condição</LineItem>
            <LineItem className="indice">Valor</LineItem>
            <LineItem className="indice">Vencimento</LineItem>
            <LineItem className="indice">NF</LineItem>
            <LineItem className="indice">V.F.</LineItem>
            <LineItem className="indice">Ações</LineItem>
          </Line>
          {pagamentos.map((pagamento, i) =>
            pagamento.editing ? (
              <EditInTable
                key={v4()}
                update={update}
                setUpdate={setUpdate}
                data={pagamento}
                inputData={inputData}
                template="100px 2.5fr 2fr 2fr 1.5fr 0.5fr 100px"
                edit_url="/pagamentos-projetos/pagamento-projeto"
                module="pagamentos"
              />
            ) : (
              <Line
                style={{
                  gridTemplateColumns: '100px 2.5fr 2fr 2fr 1.5fr 0.5fr 100px',
                }}
                key={pagamento.id}
              >
                <LineItem id="index">
                  <PagamentoProjetoModal pagamento={pagamento}>
                    {pagamento.id}
                  </PagamentoProjetoModal>
                </LineItem>
                <LineItem
                  title={pagamento.des_gatilho && pagamento.des_gatilho}
                  id="condicao"
                >
                  <PagamentoProjetoModal pagamento={pagamento}>
                    {pagamento.des_condicao_pagamento}
                  </PagamentoProjetoModal>
                </LineItem>
                <LineItem id="valor">
                  <PagamentoProjetoModal pagamento={pagamento}>
                    {`R$ ${parseFloat(pagamento.valor.toString())
                      .toFixed(2)
                      .replace('.', ',')}`}
                  </PagamentoProjetoModal>
                </LineItem>
                <LineItem id="vencimento">
                  <PagamentoProjetoModal pagamento={pagamento}>
                    {pagamento.vencimentoString}
                  </PagamentoProjetoModal>
                </LineItem>
                <LineItem id="nf">
                  <PagamentoProjetoModal pagamento={pagamento}>
                    {pagamento.nf ? pagamento.nf : '...'}
                  </PagamentoProjetoModal>
                </LineItem>
                <LineItem id="vinc_fin">
                  <PagamentoProjetoModal pagamento={pagamento}>
                    {pagamento.vinculo_financeiro ? (
                      <FaCheck color="green" />
                    ) : (
                      <FaTimes color="red" />
                    )}
                  </PagamentoProjetoModal>
                </LineItem>
                <LineItem id="acoes">
                  <FaPencilAlt onClick={() => toggleEdit(pagamento.id, 0)} />
                  <ConfirmDeleteModal
                    delete_url="/pagamentos-projetos/pagamento-projeto"
                    id={pagamento.id.toString()}
                    module="pagamentos"
                    setUpdate={setUpdate}
                    update={update}
                  >
                    <FaTrash />
                  </ConfirmDeleteModal>
                </LineItem>
              </Line>
            )
          )}
        </List>
      ) : (
        'Nenhum pagamento encontrado para esse projeto'
      )}
    </>
  );
};

export default PagamentosProjetoList;
