/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, {
  useState,
  useCallback,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';
import * as Yup from 'yup';

import Input from 'components/Input';
import { IPagamentosProjetoDTO, ISelectDTO } from 'utils/DTOS';
import Select from 'components/Select';
import Textarea from 'components/Input/textarea';
import Checkbox from 'components/Checkbox';
import Spinner from 'components/Spinner';
import getValidationErrors from 'utils/getValidationErrors';
import InputCurrency from 'components/Input/currency';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

// Styled components
import {
  Title,
  FormContainer,
  SaveButton,
  FormRow,
  InputHolder,
} from './styles';

interface IModalAttatchment {
  update: boolean;
  setUpdate: React.Dispatch<SetStateAction<boolean>>;
  pagamento: IPagamentosProjetoDTO;
  clientes: ISelectDTO[];
  servicos: ISelectDTO[];
}

const ModalEdicaoProjetoFinanceiro: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const { children, setUpdate, update, pagamento, clientes, servicos } = props;

  const tratarValor = useCallback((valor: string) => {
    const valorString = valor
      .replace('R$ ', '')
      .replace('.', '')
      .replace(',', '.');

    const split = valorString.split('.');

    if (
      split.length > 1 &&
      split[split.length - 1] &&
      split[split.length - 1].length === 3
    ) {
      split[split.length - 1] = split[split.length - 1].substring(
        0,
        split[split.length - 1].length - 1
      );
    }

    const valorFinal = parseFloat(`${split[0]}.${split[1]}`);

    return valorFinal;
  }, []);

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      data.cliente_id = null;
      data.cliente_nome = null;
      data.cliente_telefone = null;
      data.cliente_email = null;
      data.servico_id = null;
      data.observacao = null;
      data.faturar_por = null;
      data.recebimento = null;

      data.valor = tratarValor(data.valor);
      data.valor_liquido = tratarValor(data.valor_liquido);

      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          vencimento: Yup.date().typeError('Formato do campo inválido'),
          nf: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        api
          .put(`/pagamentos-projetos/pagamento-projeto/${pagamento.id}`, data)
          .then(res => {
            addToast({
              type: 'success',
              title: `Pagamento #${pagamento.id} atualizado com sucesso}`,
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description:
                'Ocorreu um erro ao editar o pagamento, cheque as informações e tente novamente.',
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na edição do pagamento',
          description:
            'Ocorreu um erro ao editar o pagamento, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, pagamento, setUpdate, update, handleCancel]
  );

  return (
    <>
      <button
        type="button"
        style={{ height: '100%' }}
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal secundary"
        wrapClassName="wrapForm"
      >
        <Title>
          Edição de Pagamento - #{pagamento && pagamento.id.toString()}
        </Title>
        <FormContainer>
          <Form initialData={pagamento} ref={formRef} onSubmit={handleSubmit}>
            <FormRow>
              <InputHolder>
                <label htmlFor="cliente_id">Cliente</label>
                <Select name="cliente_id" options={clientes} readonly />
              </InputHolder>
              <InputHolder>
                <label htmlFor="servico_id">Serviço</label>
                <Select name="servico_id" options={servicos} readonly />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="cliente_telefone">Telefone do Cliente</label>
                <Input name="cliente_telefone" readonly />
              </InputHolder>
              <InputHolder>
                <label htmlFor="cliente_email">E-mail do Cliente</label>
                <Input name="cliente_email" readonly />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="observacao">Observação</label>
                <Textarea
                  name="observacao"
                  placeholder="Digite aqui a observação"
                  readonly
                />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="faturar_por">Faturar por</label>
                <Select
                  name="faturar_por"
                  readonly
                  options={[
                    {
                      value: '0',
                      label: 'Tecnologia',
                    },
                    {
                      value: '1',
                      label: 'Gestão',
                    },
                  ]}
                />
              </InputHolder>
              <InputHolder>
                <label htmlFor="recebimento">Recebimento</label>
                <Select
                  name="recebimento"
                  readonly
                  options={[
                    {
                      value: '0',
                      label: 'Boleto',
                    },
                    {
                      value: '1',
                      label: 'Depósito',
                    },
                    {
                      value: '2',
                      label: 'Outros',
                    },
                  ]}
                />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="observacao">Observação NF</label>
                <Textarea
                  name="observacao_nf"
                  placeholder="Digite aqui a observação da NF"
                  readonly
                />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <Checkbox
                  options={[
                    {
                      label: 'Possuí vinculo financeiro?',
                      value: 'true',
                      name: 'vinculo_financeiro',
                      marked: !!pagamento.vinculo_financeiro,
                    },
                  ]}
                  name="vinculo_financeiro"
                />
              </InputHolder>
            </FormRow>
            {pagamento.obs_gatilho && (
              <FormRow>
                <InputHolder>
                  <label htmlFor="obs_gatilho">Observação do Gatilho</label>
                  <Textarea
                    name="obs_gatilho"
                    placeholder="Digite aqui a observação"
                  />
                </InputHolder>
              </FormRow>
            )}
            <FormRow>
              <InputHolder>
                <label htmlFor="vencimento">Vencimento</label>
                <Input type="date" name="vencimento" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="nf">Nota Fiscal</label>
                <Input placeholder="Nº NF" name="nf" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="cepom">Cepom</label>
                <Input placeholder="Cepom" name="cepom" />
              </InputHolder>
              <InputHolder>
                <Checkbox
                  options={[
                    {
                      label: 'Stand-by',
                      value: 'true',
                      name: 'stand-by',
                      marked: !!pagamento.standBy,
                    },
                  ]}
                  name="stand-by"
                />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="data_pagamento">Data do Pagamento</label>
                <Input type="date" name="data_pagamento" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="valor">Valor</label>
                <InputCurrency name="valor" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="valor_liquido">Valor Líquido</label>
                <InputCurrency name="valor_liquido" />
              </InputHolder>
            </FormRow>
            <SaveButton
              type="button"
              onClick={() => formRef.current?.submitForm()}
            >
              {loading ? <Spinner size={15} /> : 'Salvar'}
            </SaveButton>
          </Form>
        </FormContainer>
      </Modal>
    </>
  );
};

export default ModalEdicaoProjetoFinanceiro;
