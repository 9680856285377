import styled, { css } from 'styled-components';

interface ContainerProps {
  error?: boolean;
  ok?: boolean;
}

export const SetorHolder = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const RowSpacedBetween = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 0 80px;

  form {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .selectHolder,
    .checkboxHolder {
      width: 30%;
    }
  }

  &.grid3 {
    display: grid;
    grid-gap: 0 30px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;

    form {
      .selectHolder,
      .checkboxHolder {
        width: 100%;
      }
    }
  }
`;

export const RowFlex = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin: 0 0 80px;

  form {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &.gatilhos {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    label {
      width: 30%;
      margin-right: 10px;
    }

    .selectHolder {
      width: 70%;
    }
  }
`;

export const RowCentered = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 0 80px;

  form {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  #acoes {
    svg {
      margin: 0 5px;
      cursor: pointer;
    }
  }
`;

export const Title = styled.h3`
  color: var(--text-color-2);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 0px;

  span.secondSelect {
  }
`;

export const Gatilhos = styled.span`
  width: 100%;
  color: var(--text-color-3);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
`;

export const LabelGatilho = styled.label`
  width: 100% !important;
  margin-top: 26px !important;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  h3 {
    margin-right: 10px;
    min-width: 130px;
  }

  button {
    width: 55px !important;
  }
`;

export const GridGatilho = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: start;
  grid-template-columns: 1fr 1fr 1fr 1.2fr;
  grid-gap: 5px;

  label {
    font-size: 14px !important;
    margin-right: 0px !important;
  }
`;

export const InputHolder = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  &:first-of-type {
    margin-left: 0px;
  }
  &:last-of-type {
    margin-right: 0px;
  }

  .checkboxHolder {
    height: 44px;
    justify-content: center;
    align-items: flex-start;
    span {
      font-size: 1.4rem;
    }
    input[type='checkbox'] {
      transform: scale(1.2);
      margin-right: 10px;
    }
  }

  label {
    width: 100% !important;
    font-size: 1.15rem;
    margin-bottom: 3px;
  }

  @media (max-width: 768px) {
    width: 100% !important;
    margin: 10px 0;
  }
`;

export const NovoBtn = styled.button`
  background: var(--main-color);
  color: var(--text-color-1);

  width: 100px;
  height: 40px;
  border-radius: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  &.justSvg {
    width: 100%;
    svg {
      margin-right: 0px;
    }
  }
`;

export const HistoricosHolder = styled.div`
  width: 100%;
  height: auto;

  max-height: 300px;
  overflow-y: auto !important;
  overflow-x: visible !important;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Historico = styled.div`
  width: 100%;
  height: auto;

  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  border-left: 1px solid var(--quaternary-color);
  border-radius: 15px 0 0 15px;

  box-shadow: 0 0 10px var(--shadow-color);

  padding: 15px 30px;

  div.text {
    width: 70%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    color: var(--text-color-2);

    strong {
      font-size: 16px;
      font-weight: 600;

      span {
        font-weight: 500;
      }
    }

    p {
      margin: 10px 0 0 0;

      line-height: 1.2;
      text-align: justify;

      flex: 1 1;
    }
  }

  div.contact {
    width: 30%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    span.date {
      width: 100%;
      text-align: right;

      font-size: 18px;
      font-weight: 600;
    }

    span.contato {
      width: 100%;
      text-align: right;

      font-size: 16px;
      font-weight: 500;
    }

    span.delete {
      flex: 1 1;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
`;

export const SaldoHolder = styled.div<ContainerProps>`
  width: 100px;
  height: 38px;
  padding: 20px 16px;
  flex: 1 1;

  margin-right: 15px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: 2px solid #777;
  border-radius: 8px;

  box-shadow: 0 0 4px var(--shadow-color);
  background: var(--box-bg-color);

  span {
    color: var(--text-color-3);
    font-size: 1.3rem;
  }

  ${props =>
    props.error &&
    css`
      span {
        color: var(--error-color);
      }
      border-color: var(--error-color);
    `}
  ${props =>
    props.ok &&
    css`
      span {
        color: var(--success-color);
      }
      border-color: var(--success-color);
    `}
`;
