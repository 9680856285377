/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, {
  useState,
  useCallback,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';
import * as Yup from 'yup';

import Input from 'components/Input';
import { IPagamentosProjetoDTO, ISelectDTO, IServicosDTO } from 'utils/DTOS';
import Select from 'components/Select';
import Textarea from 'components/Input/textarea';
import Checkbox from 'components/Checkbox';
import Spinner from 'components/Spinner';
import getValidationErrors from 'utils/getValidationErrors';
import InputCurrency from 'components/Input/currency';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

// Styled components
import {
  Title,
  FormContainer,
  SaveButton,
  FormRow,
  InputHolder,
} from './styles';

interface IModalAttatchment {
  update: boolean;
  setUpdate: React.Dispatch<SetStateAction<boolean>>;
  servico: IServicosDTO;
  readonly: boolean;
}

const EdicaoHorasTecnicasModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const { children, setUpdate, update, servico, readonly } = props;

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      if (readonly) {
        return;
      }

      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          qt_hora_coordenador: Yup.number().typeError(
            'Formato do campo inválido'
          ),
          qt_hora_analista: Yup.number().typeError('Formato do campo inválido'),
          qt_hora_tecnico: Yup.number().typeError('Formato do campo inválido'),
          qt_hora_assistente_tecnico: Yup.number().typeError(
            'Formato do campo inválido'
          ),
          qt_hora_estagiario: Yup.number().typeError(
            'Formato do campo inválido'
          ),
          qt_hora_especialista: Yup.number().typeError(
            'Formato do campo inválido'
          ),
          qt_hora_assistente_admin: Yup.number().typeError(
            'Formato do campo inválido'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api
          .put(`/servicos/servico/${servico.id}`, data)
          .then(res => {
            addToast({
              type: 'success',
              title: `Horas técnicas do serviço #${servico.id} atualizadas com sucesso}`,
            });
            setUpdate(!update);
            handleCancel();
          })
          .catch(err => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description:
                'Ocorreu um erro ao editar o serviço, cheque as informações e tente novamente.',
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na edição do serviço',
          description:
            'Ocorreu um erro ao editar o serviço, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, servico, setUpdate, update, handleCancel]
  );

  return (
    <>
      <button
        type="button"
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        open={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal secundary"
        wrapClassName="wrapForm"
      >
        <Title>
          Edição de horas técnicas - #{servico && servico.id.toString()}
        </Title>
        <FormContainer>
          <Form initialData={servico} ref={formRef} onSubmit={handleSubmit}>
            <FormRow>
              <InputHolder>
                <label htmlFor="qt_hora_diretor_tecnico">
                  Qt Horas Diretor Técnico
                </label>
                <Input
                  readOnly={readonly}
                  type="number"
                  name="qt_hora_diretor_tecnico"
                  placeholder="Digite a quantidade de horas técnicas do cargo"
                />
              </InputHolder>
              <InputHolder>
                <label htmlFor="qt_hora_coordenador">
                  Qt Horas Coordenador
                </label>
                <Input
                  readOnly={readonly}
                  type="number"
                  name="qt_hora_coordenador"
                  placeholder="Digite a quantidade de horas técnicas do cargo"
                />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="qt_hora_analista">Qt Horas Analista</label>
                <Input
                  readOnly={readonly}
                  type="number"
                  name="qt_hora_analista"
                  placeholder="Digite a quantidade de horas técnicas do cargo"
                />
              </InputHolder>
              <InputHolder>
                <label htmlFor="qt_hora_tecnico">Qt Horas Técnico</label>
                <Input
                  readOnly={readonly}
                  type="number"
                  name="qt_hora_tecnico"
                  placeholder="Digite a quantidade de horas técnicas do cargo"
                />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="qt_hora_assistente_tecnico">
                  Qt Horas Assistente Técnico
                </label>
                <Input
                  readOnly={readonly}
                  type="number"
                  name="qt_hora_assistente_tecnico"
                  placeholder="Digite a quantidade de horas técnicas do cargo"
                />
              </InputHolder>
              <InputHolder>
                <label htmlFor="qt_hora_estagiario">Qt Horas Estagiário</label>
                <Input
                  readOnly={readonly}
                  type="number"
                  name="qt_hora_estagiario"
                  placeholder="Digite a quantidade de horas técnicas do cargo"
                />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="qt_hora_especialista">
                  Qt Horas Especialista
                </label>
                <Input
                  readOnly={readonly}
                  type="number"
                  name="qt_hora_especialista"
                  placeholder="Digite a quantidade de horas técnicas do cargo"
                />
              </InputHolder>
              <InputHolder>
                <label htmlFor="qt_hora_assistente_admin">
                  Qt Horas Assistente Administrativo
                </label>
                <Input
                  readOnly={readonly}
                  type="number"
                  name="qt_hora_assistente_admin"
                  placeholder="Digite a quantidade de horas técnicas do cargo"
                />
              </InputHolder>
            </FormRow>
            <SaveButton
              type="button"
              onClick={() => formRef.current?.submitForm()}
              disabled={readonly}
            >
              {loading ? <Spinner size={15} /> : 'Salvar'}
            </SaveButton>
          </Form>
        </FormContainer>
      </Modal>
    </>
  );
};

export default EdicaoHorasTecnicasModal;
