import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';
import { IoMdClose } from 'react-icons/io';

import { Line, List, LineItem } from 'styles/others';
import { formatBRDate } from 'utils/formatData';
import { IPagamentos } from '.';

interface IProps {
  pagamentos: IPagamentos[];
  removeFnc: (pagamento_id: number) => void;
}

const PagamentosList: React.FC<IProps> = props => {
  const { removeFnc, pagamentos } = props;

  return (
    <>
      {pagamentos.length !== 0 && (
        <List>
          <Line
            style={{
              gridTemplateColumns: '1fr 2.5fr 1fr 2fr 2.5fr 0.5fr 1fr',
            }}
            className="indice"
          >
            <LineItem className="indice">#</LineItem>
            <LineItem className="indice">Condição</LineItem>
            <LineItem className="indice">Gatilho</LineItem>
            <LineItem className="indice">Valor</LineItem>
            <LineItem className="indice">Vencimento</LineItem>
            <LineItem className="indice">V.F.</LineItem>
            <LineItem className="indice">Del.</LineItem>
          </Line>
          {pagamentos.map(pagamento => (
            <Line
              style={{
                gridTemplateColumns: '1fr 2.5fr 1fr 2fr 2.5fr 0.5fr 1fr',
              }}
              key={pagamento.id}
            >
              <LineItem id="index">{pagamento.id}</LineItem>
              <LineItem
                title={pagamento.des_gatilho && pagamento.des_gatilho}
                id="condicao"
              >
                {pagamento.des_condicao_pagamento}
              </LineItem>
              <LineItem id="gatilho">
                {pagamento.des_gatilho ? pagamento.des_gatilho : '-'}
              </LineItem>
              <LineItem id="valor">{pagamento.valor_string}</LineItem>
              <LineItem id="vencimento">
                {formatBRDate(pagamento.vencimento)}
              </LineItem>
              <LineItem id="vinc_fin">
                {pagamento.vinculo_financeiro === 'true' ? (
                  <FaCheck color="green" />
                ) : (
                  <FaTimes color="red" />
                )}
              </LineItem>
              <LineItem id="remover">
                <IoMdClose onClick={() => removeFnc(pagamento.id)} size={15} />
              </LineItem>
            </Line>
          ))}
        </List>
      )}
    </>
  );
};

export default PagamentosList;
