import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import BoxContainer from 'components/BoxContainer';
import Listagem, { IRow } from 'components/Listagem';

import { FilterHolder, FilterItemHolder } from 'styles/others';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Input from 'components/Input';
import Button from 'components/Button';
import { RiFilterOffFill } from 'react-icons/ri';
import { FaSearch } from 'react-icons/fa';
import MaskedInput from 'components/Input/mask';
import { Container, TitleHolder, ListHolder } from '../styles';

export interface IFilter {
  nome?: string;
  razao_social?: string;
  cpf?: string;
  cnpj?: string;
}

const Clientes: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [filter, setFilter] = useState<IFilter | undefined>(undefined);

  const onFilter = useCallback((data: IFilter) => {
    setFilter(data);
  }, []);

  const clearFilter = useCallback(() => {
    formRef.current?.clearField('nome');
    formRef.current?.clearField('razao_social');
    formRef.current?.clearField('cpf');
    formRef.current?.clearField('cnpj');
    setFilter(undefined);
  }, []);

  const rows: IRow[] = [
    {
      label: '#',
      data: 'id',
    },
    {
      label: 'Nome',
      data: 'nome',
    },
    {
      label: 'Razão Social',
      data: 'razao_social',
    },
    {
      label: 'CNPJ',
      data: 'cnpj',
    },
    {
      label: 'E-mail',
      data: 'email',
      style: {
        textTransform: 'none',
      },
    },
    {
      label: 'Endereço',
      data: 'endereco',
    },
    {
      label: 'Bairro',
      data: 'bairro',
    },
    {
      label: 'Cidade',
      data: 'cidade',
    },
    {
      label: 'CEP',
      data: 'cep',
    },
    {
      label: 'Nome Contato',
      data: 'nome_contato',
    },
    {
      label: 'Telefone',
      data: 'telefone',
    },
  ];

  return (
    <Container>
      <TitleHolder>
        Clientes:
        <button
          type="button"
          onClick={() => history.push('/cadastros/clientes/novo')}
        >
          Novo cliente
        </button>
      </TitleHolder>
      <FilterHolder>
        <Form initialData={filter} ref={formRef} onSubmit={onFilter}>
          <FilterItemHolder>
            <label htmlFor="nome">Nome</label>
            <Input name="nome" id="nome" placeholder="Digite aqui..." />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="razao_social">Razão Social</label>
            <Input
              name="razao_social"
              id="razao_social"
              placeholder="Digite aqui..."
            />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="cpf">CPF</label>
            <MaskedInput
              mask="999.999.999-99"
              name="cpf"
              id="cpf"
              placeholder="Digite aqui..."
            />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="cnpj">CNPJ</label>
            <MaskedInput
              mask="99.999.999/9999-99"
              name="cnpj"
              id="cnpj"
              placeholder="Digite aqui..."
            />
          </FilterItemHolder>
          <FilterItemHolder className="submit">
            <Button onClick={() => clearFilter()} type="button">
              <RiFilterOffFill size={18} />
            </Button>
            <Button type="submit">
              <FaSearch size={18} />
            </Button>
          </FilterItemHolder>
        </Form>
      </FilterHolder>
      <ListHolder>
        <BoxContainer borderColor="quaternary">
          <Listagem
            filter={filter}
            rows={rows}
            module="clientes"
            template="100px 2.5fr 2.5fr 2fr 3fr 2fr 1.5fr 1.5fr 1.2fr 1.5fr 1.8fr"
            editUrl="/cadastros/clientes/cliente"
            indexUrl="/cadastros/clientes/cliente"
            deleteUrl="/clientes/cliente"
          />
          {/* <PaginationHolder>
            div.
          </PaginationHolder> */}
        </BoxContainer>
      </ListHolder>
    </Container>
  );
};

export default Clientes;
