import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  overflow: visible;
`;

export const StatusColuna = styled.div`
  width: 300px;
  height: auto;

  padding: 10px;
  margin: 0 10px;

  overflow: visible;
  background: #efefef;
  min-height: 100px;

  border-radius: 15px;
`;

export const Title = styled.div`
  width: 100%;
  padding: 10px 0;
  margin-bottom: 15px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  strong {
    font-size: 18px;
    font-weight: 600;
    color: #111;
    margin-left: 10px;
  }
`;

export const Tarefa = styled.div`
  width: 100%;
  height: 100px;
  margin: 10px 0;

  overflow: visible;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  background: var(--box-bg-color);
  border-radius: 8px;

  border-bottom: 1px solid var(--secundary-color);
  box-shadow: 0 0 2px var(--secundary-shadow);

  padding: 15px;

  strong {
    font-size: 16px;
    font-weight: 500;
    color: #111;
  }
  span {
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  &:first-of-type {
    margin-top: 0px;
  }
  &:last-of-type {
    margin-bottom: 0px;
  }
`;
