import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 30px 0;

  form {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const Holder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

export const Main = styled.main`
  width: calc(75% - 20px);
  height: 100%;
  max-height: 569px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Title = styled.div`
  width: 100%;
  height: 50px;

  border-bottom: 1px solid #bbb;

  padding-left: 15px;

  color: #333;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  font-weight: 600;

  margin-bottom: 10px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: auto;

  padding: 0 0 0 15px;

  overflow-y: scroll !important;

  strong#title {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    margin: 10px 0 5px;

    color: #111;
    font-size: 16px;
    border-bottom: 1px solid #bbb;

    svg {
      color: #333;
    }
  }
  form.observacaoForm {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    textarea {
      width: calc(100% - 30px);
    }
    button {
      width: 30px;
    }
  }
`;

export const ResponsaveisHolder = styled.ul`
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  list-style: none;

  padding: 5px 15px;

  width: 100%;
  height: auto;
`;

export const Responsavel = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  font-size: 14px;
  color: #333;
  margin: 5px 0;
`;

export const Checklist = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 40px;
  padding: 10px 15px;

  border-bottom: 1px solid #333;

  span,
  strong,
  button {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #111;
    button {
      width: 100%;
    }
  }

  button {
    width: 22px;
    height: 22px;

    margin-right: 5px;
  }

  span#nome {
    width: 10%;
    button {
      font-size: 12px;
      font-weight: 300;
    }
  }

  strong#atividade {
    width: calc(50% - 32px);
    button {
      font-size: 14px;
      font-weight: 500;
    }
  }

  span#prazo {
    width: 35%;
    button {
      font-size: 12px;
      font-weight: 500;
      display: flex;
      justify-content: flex-end;
    }
  }

  span#cancelar {
    width: 22px;
    margin-left: 10px;

    button {
      margin-right: 0;
    }
  }
`;

export const ChecklistStatus = styled.span`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  height: 100%;
  width: 100%;

  border-radius: 50%;
  background-color: #fff;
  border: 1px solid var(--success-color);

  svg {
    color: var(--success-color);
  }

  &.concluido {
    background-color: var(--success-color);
    border: 1px solid var(--success-color);
    svg {
      color: #fff;
    }
  }
`;

export const Sidebar = styled.aside`
  width: 25%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FormRow = styled.div`
  width: 100%;

  margin-bottom: 20px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const InputHolder = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  background-color: rgba(0, 0, 0, 0.09);

  padding: 5px;
  border-radius: 5px;

  margin: 0 10px;

  &:first-of-type {
    margin-left: 0px;
  }
  &:last-of-type {
    margin-right: 0px;
  }

  .checkboxHolder {
    height: 44px;
    justify-content: center;
    align-items: flex-start;
    span {
      font-size: 1.4rem;
    }
  }

  label {
    font-size: 1.15rem;
    margin-bottom: 3px;
    padding: 0 0 3px 10px;
    border-bottom: 1px solid #333;
    width: 100%;
  }
`;

export const ModalRoot = styled.div`
  width: 100%;
  height: 100%;
`;
