/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Textarea from 'components/Input/textarea';
import Select from 'components/Select';
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FaSave } from 'react-icons/fa';
import api from 'services/api';
import { IProjetosDTO, ISelectDTO } from 'utils/DTOS';
import Checkbox from 'components/Checkbox';
import { useToast } from 'hooks/toast';
import Spinner from 'components/Spinner';
import Input from 'components/Input';

import { AxiosError, AxiosResponse } from 'axios';
import TerceirosModal from 'components/TerceirosModal';
import { IoIosAdd } from 'react-icons/io';
import {
  SetorHolder,
  RowSpacedBetween,
  RowFlex,
  RowCentered,
  Title,
  Gatilhos,
  LabelGatilho,
  GridGatilho,
  InputHolder,
  NovoBtn,
} from './styles';
import TerceirosList2 from '../TerceirosList2';
import PagamentosMedicaoList from '../PagamentosMedicaoList';

interface IProps {
  statusList: ISelectDTO[];
  update: boolean;
  setUpdate: React.Dispatch<SetStateAction<boolean>>;
  projeto_id?: string;
  projeto_data?: IProjetosDTO;
  usuarios: ISelectDTO[];
}

interface INumGatilhos {
  protocolo?: number;
  conclusao?: number;
  outros3?: number;
  entregaParcial?: number;
  outros1?: number;
  outros2?: number;
}

const AbaTecnico: React.FC<IProps> = props => {
  const { statusList, update, setUpdate, projeto_data, projeto_id, usuarios } =
    props;
  const [loading, setLoading] = useState<boolean>(false);
  const [qtdGatilhos, setQtdGatilhos] = useState<INumGatilhos>({
    protocolo: 0,
    conclusao: 0,
    outros3: 0,
    entregaParcial: 0,
    outros1: 0,
    outros2: 0,
  });

  const { addToast } = useToast();

  const statusRef = useRef<FormHandles>(null);
  const responsavelRef = useRef<FormHandles>(null);
  const dataInicioPrevistoRef = useRef<FormHandles>(null);
  const dataInicioRealRef = useRef<FormHandles>(null);
  const urgenciaRef = useRef<FormHandles>(null);
  const observacaoRef = useRef<FormHandles>(null);
  const protocoloRef = useRef<FormHandles>(null);
  const conclusaoRef = useRef<FormHandles>(null);
  const outros3Ref = useRef<FormHandles>(null);
  const entregaParcialRef = useRef<FormHandles>(null);
  const outros1Ref = useRef<FormHandles>(null);
  const outros2Ref = useRef<FormHandles>(null);

  const editObservacao = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Observação editada com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar a observação do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição da observação',
          description:
            'Ocorreu um erro ao editar a observação do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editStatus = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Status editado com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar o status do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição do status',
          description:
            'Ocorreu um erro ao editar o status do projeto, cheque as informações e tente novamente.',
        });
        console.error(`Erro: ${err}`);
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editResponsavel = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Responsável editado com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar o responsável do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição do responsável',
          description:
            'Ocorreu um erro ao editar o responsável do projeto, cheque as informações e tente novamente.',
        });
        console.error(`Erro: ${err}`);
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editDataInicioPrevisto = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Data de início previsto editada com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar a data de início previsto do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição da data de início previsto',
          description:
            'Ocorreu um erro ao editar a data de início previsto do projeto, cheque as informações e tente novamente.',
        });
        console.error(`Erro: ${err}`);
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editDataInicioReal = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Data de início real editada com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar a data de início real do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição da data de início real',
          description:
            'Ocorreu um erro ao editar a data de início real do projeto, cheque as informações e tente novamente.',
        });
        console.error(`Erro: ${err}`);
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editUrgencia = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        dat.urgente[0] === 'true'
          ? (dat.urgente = true)
          : (dat.urgente = false);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Urgência editada com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar a urgência do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição da urgência',
          description:
            'Ocorreu um erro ao editar a urgência do projeto, cheque as informações e tente novamente.',
        });
        console.error(`Erro: ${err}`);
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editProtocolo = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        for (const key in dat) {
          if (dat[key] === '') {
            dat[key] = null;
          }
        }

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Protocolo editado com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar o protocolo do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição do protocolo',
          description:
            'Ocorreu um erro ao editar o protocolo do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editConclusao = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        for (const key in dat) {
          if (dat[key] === '') {
            dat[key] = undefined;
          }
        }

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Conclusão editada com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar a conclusão do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição da conclusão',
          description:
            'Ocorreu um erro ao editar a conclusão do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editOutros3 = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        for (const key in dat) {
          if (dat[key] === '') {
            dat[key] = undefined;
          }
        }

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Outros 3 editado com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar o outros 3 do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição do outros 3',
          description:
            'Ocorreu um erro ao editar o outros 3 do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editEntregaParcial = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        for (const key in dat) {
          if (dat[key] === '') {
            dat[key] = undefined;
          }
        }

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Entrega parcial editada com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar a entrega parcial do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição da entrega parcial',
          description:
            'Ocorreu um erro ao editar a entrega parcial do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editOutros1 = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        for (const key in dat) {
          if (dat[key] === '') {
            dat[key] = undefined;
          }
        }

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Outros 1 editado com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar outros 1 do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição do outros 1',
          description:
            'Ocorreu um erro ao editar o outros 1 do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editOutros2 = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        for (const key in dat) {
          if (dat[key] === '') {
            dat[key] = undefined;
          }
        }

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Outros 2 editado com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar outros 2 do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição do outros 2',
          description:
            'Ocorreu um erro ao editar o outros 2 do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const getQtdGatilhos = useCallback(async () => {
    setLoading(true);
    if (!projeto_id) {
      return;
    }
    await api
      .get(`/pagamentos-projetos/qtd-gatilhos/${projeto_id}`)
      .then(async (res: AxiosResponse) => {
        setQtdGatilhos(res.data);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os gatilhos financeiros do projeto, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, projeto_id, update]);

  useEffect(() => {
    getQtdGatilhos();
  }, [getQtdGatilhos]);

  return (
    <SetorHolder>
      <RowSpacedBetween
        className="grid3"
        style={{ gridTemplateColumns: '1fr 1.5fr 1fr' }}
        id="status-inputs"
      >
        <Form
          ref={statusRef}
          onSubmit={editStatus}
          initialData={projeto_data && projeto_data}
        >
          <InputHolder style={{ width: '75%' }}>
            <label htmlFor="status">Status:</label>
            <Select name="status" options={statusList} />
          </InputHolder>
          <InputHolder style={{ width: '20%' }}>
            <NovoBtn
              style={{ marginTop: '26px' }}
              onClick={e => {
                e.preventDefault();
                statusRef.current?.submitForm();
              }}
              className="justSvg"
              type="button"
            >
              <FaSave size={25} />
            </NovoBtn>
          </InputHolder>
        </Form>
        <Form
          ref={responsavelRef}
          onSubmit={editResponsavel}
          initialData={projeto_data && projeto_data}
        >
          <InputHolder style={{ width: '75%' }}>
            <label htmlFor="responsavel_id">Responsável:</label>
            <Select
              name="responsavel_id"
              options={usuarios}
              placeholder="Selecione o responsável..."
            />
          </InputHolder>
          <InputHolder style={{ width: '20%' }}>
            <NovoBtn
              style={{ marginTop: '26px' }}
              onClick={e => {
                e.preventDefault();
                responsavelRef.current?.submitForm();
              }}
              className="justSvg"
              type="button"
            >
              <FaSave size={25} />
            </NovoBtn>
          </InputHolder>
        </Form>
        <Form
          ref={urgenciaRef}
          onSubmit={editUrgencia}
          initialData={projeto_data && projeto_data}
        >
          <InputHolder style={{ width: '75%' }}>
            <Checkbox
              style={{ margin: '26px 20px 0' }}
              options={[
                {
                  label: 'Urgente',
                  value: 'true',
                  name: 'urgente',
                  marked: projeto_data && projeto_data.urgente,
                },
              ]}
              name="urgente"
            />
          </InputHolder>
          <InputHolder style={{ width: '20%' }}>
            <NovoBtn
              style={{ marginTop: '26px' }}
              onClick={e => {
                e.preventDefault();
                urgenciaRef.current?.submitForm();
              }}
              className="justSvg"
              type="button"
            >
              <FaSave size={25} />
            </NovoBtn>
          </InputHolder>
        </Form>
      </RowSpacedBetween>
      <RowSpacedBetween
        className="grid3"
        style={{ gridTemplateColumns: '1fr 1fr' }}
        id="data_inicios"
      >
        <Form
          ref={dataInicioPrevistoRef}
          onSubmit={editDataInicioPrevisto}
          initialData={projeto_data && projeto_data}
        >
          <InputHolder style={{ width: '75%' }}>
            <label htmlFor="data_inicio_previsto">Data Início Previsto:</label>
            <Input type="date" name="data_inicio_previsto" />
          </InputHolder>
          <InputHolder style={{ width: '20%' }}>
            <NovoBtn
              onClick={e => {
                e.preventDefault();
                dataInicioPrevistoRef.current?.submitForm();
              }}
              className="justSvg"
              style={{ marginTop: '26px' }}
              type="button"
            >
              <FaSave size={25} />
            </NovoBtn>
          </InputHolder>
        </Form>
        <Form
          ref={dataInicioRealRef}
          onSubmit={editDataInicioReal}
          initialData={projeto_data && projeto_data}
        >
          <InputHolder style={{ width: '75%' }}>
            <label htmlFor="data_inicio_real">Data Início Real:</label>
            <Input type="date" name="data_inicio_real" />
          </InputHolder>
          <InputHolder style={{ width: '20%' }}>
            <NovoBtn
              onClick={e => {
                e.preventDefault();
                dataInicioRealRef.current?.submitForm();
              }}
              className="justSvg"
              style={{ marginTop: '26px' }}
              type="button"
            >
              <FaSave size={25} />
            </NovoBtn>
          </InputHolder>
        </Form>
      </RowSpacedBetween>
      <RowFlex id="protocolo" style={{ marginBottom: '10px' }}>
        <Form
          ref={protocoloRef}
          onSubmit={editProtocolo}
          initialData={projeto_data && projeto_data}
          className="gatilhos"
        >
          <LabelGatilho htmlFor="protocolo">
            <Title>Protocolo:</Title>
            <Gatilhos>{qtdGatilhos.protocolo || 0} gatilho(s)</Gatilhos>
            <NovoBtn
              style={{ marginTop: '26px' }}
              className="justSvg"
              onClick={e => {
                e.preventDefault();
                protocoloRef.current?.submitForm();
              }}
              type="button"
            >
              <FaSave size={25} />
            </NovoBtn>
          </LabelGatilho>
          {loading ? (
            <Spinner size={10} />
          ) : (
            <GridGatilho>
              <InputHolder>
                <label htmlFor="protocolo_prazo">Prazo:</label>
                <Input type="date" name="protocolo_prazo" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="protocolo_entrega">Entrega:</label>
                <Input type="date" name="protocolo_entrega" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="protocolo_encerramento">Encerramento:</label>
                <Input type="date" name="protocolo_encerramento" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="protocolo_observacao">Observação:</label>
                <Input name="protocolo_observacao" placeholder="Observação" />
              </InputHolder>
            </GridGatilho>
          )}
        </Form>
      </RowFlex>
      <RowFlex id="conclusao" style={{ marginBottom: '10px' }}>
        <Form
          ref={conclusaoRef}
          onSubmit={editConclusao}
          initialData={projeto_data && projeto_data}
          className="gatilhos"
        >
          <LabelGatilho htmlFor="conclusao">
            <Title>Conclusão:</Title>
            <Gatilhos>{qtdGatilhos.conclusao || 0} gatilho(s)</Gatilhos>
            <NovoBtn
              style={{ marginTop: '26px' }}
              className="justSvg"
              onClick={e => {
                e.preventDefault();
                conclusaoRef.current?.submitForm();
              }}
              type="button"
            >
              <FaSave size={25} />
            </NovoBtn>
          </LabelGatilho>
          {loading ? (
            <Spinner size={10} />
          ) : (
            <GridGatilho>
              <InputHolder>
                <label htmlFor="conclusao_prazo">Prazo:</label>
                <Input type="date" name="conclusao_prazo" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="conclusao_entrega">Entrega:</label>
                <Input type="date" name="conclusao_entrega" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="conclusao_encerramento">Encerramento:</label>
                <Input type="date" name="conclusao_encerramento" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="conclusao_observacao">Observação:</label>
                <Input name="conclusao_observacao" placeholder="Observação" />
              </InputHolder>
            </GridGatilho>
          )}
        </Form>
      </RowFlex>
      <RowFlex id="entrega_parcial" style={{ marginBottom: '10px' }}>
        <Form
          ref={entregaParcialRef}
          onSubmit={editEntregaParcial}
          initialData={projeto_data && projeto_data}
          className="gatilhos"
        >
          <LabelGatilho htmlFor="entrega_parcial">
            <Title>Entrega Parcial:</Title>
            <Gatilhos>{qtdGatilhos.entregaParcial || 0} gatilho(s)</Gatilhos>
            <NovoBtn
              style={{ marginTop: '26px' }}
              className="justSvg"
              onClick={e => {
                e.preventDefault();
                entregaParcialRef.current?.submitForm();
              }}
              type="button"
            >
              <FaSave size={25} />
            </NovoBtn>
          </LabelGatilho>
          {loading ? (
            <Spinner size={10} />
          ) : (
            <GridGatilho>
              <InputHolder>
                <label htmlFor="entrega_parcial_prazo">Prazo:</label>
                <Input type="date" name="entrega_parcial_prazo" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="entrega_parcial_entrega">Entrega:</label>
                <Input type="date" name="entrega_parcial_entrega" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="entrega_parcial_encerramento">
                  Encerramento:
                </label>
                <Input type="date" name="entrega_parcial_encerramento" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="entrega_parcial_observacao">Observação:</label>
                <Input
                  name="entrega_parcial_observacao"
                  placeholder="Observação"
                />
              </InputHolder>
            </GridGatilho>
          )}
        </Form>
      </RowFlex>
      <RowFlex id="outros_1" style={{ marginBottom: '10px' }}>
        <Form
          ref={outros1Ref}
          onSubmit={editOutros1}
          initialData={projeto_data && projeto_data}
          className="gatilhos"
        >
          <LabelGatilho htmlFor="outros_1">
            <Title>Outros 1:</Title>
            <Gatilhos>{qtdGatilhos.outros1 || 0} gatilho(s)</Gatilhos>
            <NovoBtn
              style={{ marginTop: '26px' }}
              className="justSvg"
              onClick={e => {
                e.preventDefault();
                outros1Ref.current?.submitForm();
              }}
              type="button"
            >
              <FaSave size={25} />
            </NovoBtn>
          </LabelGatilho>
          {loading ? (
            <Spinner size={10} />
          ) : (
            <GridGatilho>
              <InputHolder>
                <label htmlFor="outros1_prazo">Prazo:</label>
                <Input type="date" name="outros1_prazo" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="outros1_entrega">Entrega:</label>
                <Input type="date" name="outros1_entrega" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="outros1_encerramento">Encerramento:</label>
                <Input type="date" name="outros1_encerramento" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="outros1_observacao">Observação:</label>
                <Input name="outros1_observacao" placeholder="Observação" />
              </InputHolder>
            </GridGatilho>
          )}
        </Form>
      </RowFlex>
      <RowFlex id="outros_2" style={{ marginBottom: '10px' }}>
        <Form
          ref={outros2Ref}
          onSubmit={editOutros2}
          initialData={projeto_data && projeto_data}
          className="gatilhos"
        >
          <LabelGatilho htmlFor="outros_1">
            <Title>Outros 2:</Title>
            <Gatilhos>{qtdGatilhos.outros2 || 0} gatilho(s)</Gatilhos>
            <NovoBtn
              style={{ marginTop: '26px' }}
              className="justSvg"
              onClick={e => {
                e.preventDefault();
                outros2Ref.current?.submitForm();
              }}
              type="button"
            >
              <FaSave size={25} />
            </NovoBtn>
          </LabelGatilho>
          {loading ? (
            <Spinner size={10} />
          ) : (
            <GridGatilho>
              <InputHolder>
                <label htmlFor="outros2_prazo">Prazo:</label>
                <Input type="date" name="outros2_prazo" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="outros2_entrega">Entrega:</label>
                <Input type="date" name="outros2_entrega" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="outros2_encerramento">Encerramento:</label>
                <Input type="date" name="outros2_encerramento" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="outros2_observacao">Observação:</label>
                <Input name="outros2_observacao" placeholder="Observação" />
              </InputHolder>
            </GridGatilho>
          )}
        </Form>
      </RowFlex>
      <RowFlex id="outros3">
        <Form
          ref={outros3Ref}
          onSubmit={editOutros3}
          initialData={projeto_data && projeto_data}
          className="gatilhos"
        >
          <LabelGatilho htmlFor="outros3">
            <Title>Outros 3:</Title>
            <Gatilhos>{qtdGatilhos.outros3 || 0} gatilho(s)</Gatilhos>
            <NovoBtn
              style={{ marginTop: '26px' }}
              className="justSvg"
              onClick={e => {
                e.preventDefault();
                outros3Ref.current?.submitForm();
              }}
              type="button"
            >
              <FaSave size={25} />
            </NovoBtn>
          </LabelGatilho>
          {loading ? (
            <Spinner size={10} />
          ) : (
            <GridGatilho>
              <InputHolder>
                <label htmlFor="outros3_prazo">Prazo:</label>
                <Input type="date" name="outros3_prazo" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="outros3_entrega">Entrega:</label>
                <Input type="date" name="outros3_entrega" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="outros3_encerramento">Encerramento:</label>
                <Input type="date" name="outros3_encerramento" />
              </InputHolder>
              <InputHolder>
                <label htmlFor="outros3_observacao">Observação:</label>
                <Input name="outros3_observacao" placeholder="Observação" />
              </InputHolder>
            </GridGatilho>
          )}
        </Form>
      </RowFlex>
      <RowSpacedBetween
        style={{ marginBottom: 10 }}
        id="forma-pagamento-medicoes-titulo"
      >
        <Title>Pagamentos (medições):</Title>
      </RowSpacedBetween>
      <RowCentered id="forma-pagamento-medicoes-tabela">
        <PagamentosMedicaoList projeto_id={projeto_id} />
      </RowCentered>
      <RowSpacedBetween style={{ marginBottom: 10 }} id="terceiros-titulo">
        <Title>Terceiros:</Title>
        {projeto_id && (
          <NovoBtn
            className="justSvg"
            style={{ marginLeft: 20, width: '5%' }}
            type="button"
          >
            <TerceirosModal
              projeto_id={parseInt(projeto_id)}
              setUpdate={setUpdate}
              update={update}
            >
              <IoIosAdd size={25} />
            </TerceirosModal>
          </NovoBtn>
        )}
      </RowSpacedBetween>
      <RowCentered id="terceiros-tabela">
        <TerceirosList2 projeto_id={projeto_id} />
      </RowCentered>
      <RowSpacedBetween style={{ marginBottom: 10 }} id="status">
        <Title>Observação:</Title>
        <NovoBtn
          onClick={e => {
            e.preventDefault();
            observacaoRef.current?.submitForm();
          }}
          type="button"
        >
          <FaSave size={25} />
          Salvar
        </NovoBtn>
      </RowSpacedBetween>
      <RowCentered id="observacao">
        <Form
          ref={observacaoRef}
          onSubmit={editObservacao}
          initialData={projeto_data && projeto_data}
        >
          <Textarea
            name="observacoes_gerais"
            placeholder="Digite a(s) observações gerais aqui..."
          />
        </Form>
      </RowCentered>
    </SetorHolder>
  );
};

export default AbaTecnico;
