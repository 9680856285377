/* eslint-disable react/destructuring-assignment */
import React, {
  useState,
  useCallback,
  useRef,
  SetStateAction,
  useEffect,
} from 'react';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import Select from 'components/Select';
import { FormHandles } from '@unform/core';

// Styled components
import { AxiosError } from 'axios';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import getValidationErrors from 'utils/getValidationErrors';
import Spinner from 'components/Spinner';
import { ISelectDTO, IStatusUpdateDTO } from 'utils/DTOS';
import { formatDateWithHour } from 'utils/formatData';
import {
  Title,
  Body,
  FormRow,
  InputHolder,
  ConfirmButton,
  StatusUpdateHolder,
  ListaStatusTitle,
  ListaStatusUpdate,
  StatusUpdate,
} from './styles';

interface IModalAttatchment {
  statusList: ISelectDTO[];
  status: number;
  projeto_id: number;
  update: boolean;
  setUpdate: React.Dispatch<SetStateAction<boolean>>;
}

const StatusModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusProjetos, setStatusProjetos] = useState<IStatusUpdateDTO[]>([]);

  const { addToast } = useToast();

  const { statusList, status, projeto_id, children, update, setUpdate } = props;

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const editStatus = useCallback(async () => {
    if (!formRef.current) {
      return;
    }

    try {
      setLoading(true);

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        modal_status: Yup.number()
          .typeError('Formato do campo inválido')
          .required('Escopo obrigatório'),
      });

      await schema.validate(
        {
          modal_status: parseInt(formRef.current.getFieldValue('modal_status')),
        },
        {
          abortEarly: false,
        }
      );

      api
        .put(`/projetos/projeto/${projeto_id}`, {
          status: parseInt(formRef.current.getFieldValue('modal_status')),
        })
        .then(res => {
          addToast({
            type: 'success',
            title: 'Status atualizado com sucesso',
          });
          setUpdate(!update);
          handleCancel();
        })
        .catch((err: AxiosError) => {
          addToast({
            type: 'error',
            title:
              typeof err.response?.data.message === 'string'
                ? err.response?.data.message
                : 'Ocorreu um erro',
            description:
              'Ocorreu um erro ao atualizar o status, cheque as informações e tente novamente.',
          });
          console.error(`Erro: ${err}`);
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }

      addToast({
        type: 'error',
        title: 'Erro na atualização do escopo',
        description:
          'Ocorreu um erro ao atualizar o status, cheque as informações e tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, handleCancel, projeto_id, update, setUpdate]);

  const handleSubmit = useCallback(async data => {
    true;
  }, []);

  const getStatusProjeto = useCallback(async () => {
    api
      .get(`/status-projetos/projeto/${projeto_id}`)
      .then(res => {
        console.log(res.data);
        setStatusProjetos(res.data);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar as atualizações de status, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast, projeto_id]);

  useEffect(() => {
    getStatusProjeto();
  }, [getStatusProjeto]);

  return (
    <>
      <button
        type="button"
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal main"
        wrapClassName="wrapForm"
      >
        <Title>Editar Status</Title>
        <Body>
          <Form
            initialData={{ modal_status: status }}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <FormRow>
              <InputHolder style={{ width: '100%' }}>
                <label htmlFor="modal_status">Status:</label>
                <Select name="modal_status" options={statusList} />
              </InputHolder>
            </FormRow>
          </Form>
          <StatusUpdateHolder>
            <ListaStatusTitle>Atualizações de Status:</ListaStatusTitle>
            <ListaStatusUpdate>
              {statusProjetos.length === 0 ? (
                <li style={{ width: '100%', textAlign: 'center' }}>
                  Nenhuma atualização de status...
                </li>
              ) : (
                <>
                  <StatusUpdate id="header">
                    <span id="updated_at">Dt. Atualização</span>
                    <span id="des_status_antes">Status Antes</span>
                    <span id="des_status_depois">Status Depois</span>
                  </StatusUpdate>
                  {statusProjetos.map(statusProjeto => (
                    <StatusUpdate
                      key={statusProjeto.id}
                      id={statusProjeto.id.toString()}
                    >
                      <span id="updated_at">
                        {formatDateWithHour(statusProjeto.updated_at)}
                      </span>
                      <span id="des_status_antes">
                        {statusProjeto.des_status_antes || '-'}
                      </span>
                      <span id="des_status_depois">
                        {statusProjeto.des_status_depois}
                      </span>
                    </StatusUpdate>
                  ))}
                </>
              )}
            </ListaStatusUpdate>
          </StatusUpdateHolder>
        </Body>
        <ConfirmButton
          disabled={loading}
          type="button"
          onClick={() => editStatus()}
        >
          {loading ? <Spinner size={15} /> : 'Atualizar status'}
        </ConfirmButton>
      </Modal>
    </>
  );
};

export default StatusModal;
