import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { AxiosError, AxiosResponse } from 'axios';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from 'hooks/toast';
import api from 'services/api';
import FormConstructor, { IInput, ISaveBtn } from 'components/FormConstructor';
import getValidationErrors from 'utils/getValidationErrors';
import ManyToManyModal from 'components/ManyToManyModal';
import { ILPSDTO, ISelectDTO, ISetoroesClienteDTO } from 'utils/DTOS';

import Spinner from 'components/Spinner';
import { useAuth } from 'hooks/auth';
import {
  Container,
  HeaderHolder,
  HeaderItem,
  BodyHolder,
  ModalOpener,
} from '../interna-styles';
import { TitleHolder } from '../styles';

interface ParamsTypes {
  id: string | undefined;
}

const ClientesInterna: React.FC = () => {
  const module = 'cliente';
  const module_label = 'Cliente';

  const { id } = useParams<ParamsTypes>();

  const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const [update, setUpdate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any | undefined>(undefined);
  const [setoresCliente, setSetoresCliente] = useState<ISelectDTO[]>([]);
  const [lpsCliente, setLpsCliente] = useState<ISelectDTO[]>([]);
  const [lps, setLps] = useState<ISelectDTO[]>([]);
  const { addToast } = useToast();

  const { cod_perfil } = useAuth().usuario;

  const getSetoresCliente = useCallback(async () => {
    await api
      .get('/setores-cliente')
      .then(async (res: AxiosResponse) => {
        const tmp: ISetoroesClienteDTO[] = res.data;
        const final: ISelectDTO[] = [];

        for (let i = 0; i < tmp.length; i++) {
          const tmp1 = tmp[i];

          final.push({
            label: tmp1.nome,
            value: tmp1.id.toString(),
          });
        }

        setSetoresCliente(final);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: `Ocorreu um erro ao buscar os setores, tente novamente.`,
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast, update]);

  const getLps = useCallback(async () => {
    await api
      .get('/lps')
      .then(async (res: AxiosResponse) => {
        const tmp: ILPSDTO[] = res.data;
        const final: ISelectDTO[] = [];

        for (let i = 0; i < tmp.length; i++) {
          const tmp1 = tmp[i];

          final.push({
            label: tmp1.nome,
            value: tmp1.id.toString(),
          });
        }

        setLps(final);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: `Ocorreu um erro ao buscar os LPS, tente novamente.`,
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast, update]);

  useEffect(() => {
    getSetoresCliente();
    getLps();
  }, [getSetoresCliente, getLps]);

  const saveBtn: ISaveBtn = {
    label: 'Enviar',
    loading,
  };

  const inputs: IInput[] = [
    {
      name: 'nome',
      label: 'Nome',
      tipo: 'input',
      required: true,
    },
    {
      name: 'razao_social',
      label: 'Razão Social',
      tipo: 'input',
    },
    {
      name: 'cnpj',
      label: 'CNPJ',
      tipo: 'mask',
      mask: '99.999.999/9999-99',
    },
    {
      name: 'cpf',
      label: 'CPF',
      tipo: 'mask',
      mask: '999.999.999-99',
    },
    {
      name: 'email',
      label: 'E-mail',
      tipo: 'input',
      type: 'email',
      required: true,
    },
    {
      name: 'email_2',
      label: 'E-mail 2',
      tipo: 'input',
      type: 'email',
    },
    {
      name: 'endereco',
      label: 'Endereço',
      tipo: 'input',
      required: true,
    },
    {
      name: 'bairro',
      label: 'Bairro',
      tipo: 'input',
      required: true,
    },
    {
      name: 'cidade',
      label: 'Cidade',
      tipo: 'input',
      required: true,
    },
    {
      name: 'cep',
      label: 'CEP',
      tipo: 'mask',
      mask: '99999-999',
      required: true,
    },
    {
      name: 'contato_financeiro',
      label: 'Nome Contato Financeiro',
      tipo: 'input',
      required: false,
    },
    {
      name: 'contato_financeiro_telefone',
      label: 'Telefone Contato Financeiro',
      tipo: 'phone',
      required: false,
    },
    {
      name: 'contato_financeiro_email',
      label: 'E-mail Contato Financeiro',
      tipo: 'input',
      type: 'email',
      required: false,
    },
    {
      name: 'nome_contato',
      label: 'Nome Contato',
      tipo: 'input',
      required: true,
    },
    {
      name: 'telefone',
      label: 'Telefone',
      tipo: 'phone',
      required: true,
    },
    {
      name: 'nome_contato_2',
      label: 'Nome Contato 2',
      tipo: 'input',
      required: false,
    },
    {
      name: 'telefone_2',
      label: 'Telefone 2',
      tipo: 'phone',
      required: false,
    },
    {
      name: 'nome_contato_3',
      label: 'Nome Contato 3',
      tipo: 'input',
      required: false,
    },
    {
      name: 'telefone_3',
      label: 'Telefone 3',
      tipo: 'phone',
      required: false,
    },
    {
      name: 'id_vhsys',
      label: 'ID do VHSYS',
      tipo: 'input',
      required: false,
    },
    {
      name: 'setor_cliente_id',
      label: 'Setor do cliente',
      tipo: 'select',
      required: true,
      selectData: setoresCliente,
    },
  ];

  const get = useCallback(async () => {
    setLoading(true);
    await api
      .get(`/${module}s/${module}/${id}`)
      .then(async (res: AxiosResponse) => {
        res.data.setor_cliente_id = res.data.__setor_cliente__.id.toString();
        setData(res.data);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: `Ocorreu um erro ao buscar o ${module.toLocaleLowerCase()}, tente novamente.`,
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, id, module, update]);

  useEffect(() => {
    get();
  }, [get]);

  const getLpsFromClient = useCallback(() => {
    if (!data) {
      return;
    }
    const tmp: ILPSDTO[] = data.__lps__;
    const final: ISelectDTO[] = [];

    for (let i = 0; i < tmp.length; i++) {
      const tmp1 = tmp[i];

      final.push({
        label: tmp1.nome,
        value: tmp1.id.toString(),
      });
    }

    setLpsCliente(final);
  }, [data, update]);

  useEffect(() => {
    getLpsFromClient();
  }, [getLpsFromClient]);

  const handleSubmit = useCallback(
    async (dat: any) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          email: Yup.string().email().required('E-mail obrigatório'),
          endereco: Yup.string().required('Endereço obrigatório'),
          bairro: Yup.string().required('Bairro obrigatório'),
          cidade: Yup.string().required('Cidade obrigatória'),
          cep: Yup.string()
            .required('CEP obrigatório')
            .matches(/^\d{5}-\d{3}$/, 'Formato do cep inválido'),
          nome_contato: Yup.string().required('Nome do contato obrigatório'),
          telefone: Yup.string().required('Telefone obrigatório'),
          id_vhsys: Yup.string(),
          setor_cliente_id: Yup.string().required(
            'Setor do cliente obrigatório'
          ),
        });

        await schema.validate(dat, {
          abortEarly: false,
        });

        api
          .put(`/${module}s/${module}/${id}`, dat)
          .then((res: AxiosResponse) => {
            const { id: idd } = res.data;
            addToast({
              type: 'success',
              title: `${module_label}${
                idd && ` de id: ${idd}`
              } atualizado com sucesso`,
            });
            setUpdate(!update);
            history.push(`/cadastros/${module}s`);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description: `Ocorreu um erro ao atualizar o ${module}, cheque as informações e tente novamente.`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: err.message || `Erro na atuaização do ${module}`,
          description: `Ocorreu um erro ao atualizar o ${module}, cheque as informações e tente novamente.`,
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [addToast, id, update]
  );

  return (
    <Container>
      {data ? (
        <>
          <HeaderHolder style={{ gridTemplateColumns: '300px' }}>
            <HeaderItem className="full">
              <ManyToManyModal
                module="cliente"
                relation="lps"
                addUrl="/clientes/adicionar-lps"
                removeUrl="/clientes/remover-lps"
                actual_relations={lpsCliente}
                relations={lps}
                module_id={data.id.toString()}
                setUpdate={setUpdate}
                update={update}
              >
                <ModalOpener>Gerenciar LPS</ModalOpener>
              </ManyToManyModal>
            </HeaderItem>
          </HeaderHolder>
          <BodyHolder style={{ marginTop: 60 }}>
            <TitleHolder>
              {module_label} #{id}:
            </TitleHolder>
            <FormConstructor
              color="quaternary"
              formColumns="1fr 1fr"
              formRef={formRef}
              inputs={inputs}
              saveBtn={saveBtn}
              submitFnc={handleSubmit}
              initialData={data}
              readonly={cod_perfil === 4}
            />
          </BodyHolder>
        </>
      ) : loading ? (
        <Spinner size={15} />
      ) : (
        `${
          module.lastIndexOf('s') === module.length - 1
            ? module.slice(0, -1)
            : module
        } não encontrado...`
      )}
    </Container>
  );
};

export default ClientesInterna;
