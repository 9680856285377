/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useCallback, SetStateAction, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';
import * as Yup from 'yup';

import Input from 'components/Input';
import Spinner from 'components/Spinner';
import getValidationErrors from 'utils/getValidationErrors';
import Textarea from 'components/Input/textarea';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

// Styled components
import {
  Title,
  FormContainer,
  SaveButton,
  FormRow,
  InputHolder,
} from './styles';

interface IModalAttatchment {
  update?: boolean;
  setUpdate?: React.Dispatch<SetStateAction<boolean>>;
  projeto_id?: string;
}

const NovoHistoricoModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const { children, setUpdate, update, projeto_id } = props;

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      data.projeto_id = projeto_id;
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          assunto: Yup.string().required('Assunto obrigatório'),
          texto: Yup.string().required('Texto obrigatório'),
          data_contato: Yup.date()
            .typeError('Formato do campo inválido')
            .required('Data obrigatória'),
          pessoa_contato: Yup.string().required('Pessoa contatada obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        api
          .post(`/historicos`, data)
          .then(res => {
            addToast({
              type: 'success',
              title: `Histórico do projeto: #${projeto_id} criado com sucesso}`,
            });
            setUpdate && setUpdate(!update);
            handleCancel();
          })
          .catch(err => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description:
                'Ocorreu um erro ao criar o histórico, cheque as informações e tente novamente.',
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na criação do histórico',
          description:
            'Ocorreu um erro ao criar o histórico, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update, handleCancel]
  );

  return (
    <>
      <button
        type="button"
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal secundary"
        wrapClassName="wrapForm"
      >
        <Title>Novo Histórico - Projeto #{projeto_id}</Title>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormRow>
              <InputHolder>
                <label htmlFor="assunto">Assunto *</label>
                <Input name="assunto" placeholder="Assunto" />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="texto">Texto *</label>
                <Textarea name="texto" placeholder="Texto" />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <label htmlFor="data_contato">Data do Contato *</label>
                <Input
                  type="date"
                  name="data_contato"
                  placeholder="data_contato"
                />
              </InputHolder>
              <InputHolder>
                <label htmlFor="pessoa_contato">Pessoa contatada *</label>
                <Input name="pessoa_contato" placeholder="Pessoa contatada" />
              </InputHolder>
            </FormRow>
            <SaveButton
              type="button"
              onClick={() => formRef.current?.submitForm()}
            >
              {loading ? <Spinner size={15} /> : 'Registrar'}
            </SaveButton>
          </Form>
        </FormContainer>
      </Modal>
    </>
  );
};

export default NovoHistoricoModal;
