import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router';

import { useToast } from 'hooks/toast';
import api from 'services/api';
import { ISelectDTO, ISetoroesClienteDTO } from 'utils/DTOS';
import FormConstructor, { IInput, ISaveBtn } from 'components/FormConstructor';
import getValidationErrors from 'utils/getValidationErrors';

import { Container, Title, Desc } from '../novo-styles';

const NovoCliente: React.FC = () => {
  const module = 'cliente';
  const module_label = 'Cliente';
  const formRef = useRef<FormHandles>(null);
  const [setoresCliente, setSetoresCliente] = useState<ISelectDTO[]>([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { addToast } = useToast();

  const searchCEP = useCallback(
    event => {
      const cep = event.target.value.replace(/\D/g, '');
      api
        .get(`/extras/cep/${cep}`)
        .then((res: AxiosResponse) => {
          addToast({
            type: 'success',
            title: `CEP encontrado com sucesso!`,
            description: `Informações do endereço preenchidas!`,
          });

          formRef.current?.setFieldValue('bairro', res.data.bairro ?? '');
          formRef.current?.setFieldValue(
            'endereco',
            res.data.logradouro && res.data.complemento
              ? `${res.data.logradouro} - ${res.data.complemento}`
              : ''
          );
          formRef.current?.setFieldValue('cidade', res.data.localidade ?? '');
        })
        .catch((err: AxiosError) => {
          addToast({
            type: 'error',
            title:
              typeof err.response?.data.message === 'string'
                ? err.response?.data.message.charAt(0).toUpperCase() +
                  err.response?.data.message.slice(1)
                : 'Ocorreu um erro',
            description: `Ocorreu um erro ao buscar o cep, cheque as informações e tente novamente.`,
          });
          console.error(`Erro: ${err}`);
        });
    },
    [addToast]
  );

  const searchCNPJ = useCallback(
    event => {
      const cnpj = event.target.value.replace(/\D/g, '');
      api
        .get(`/extras/cnpj/${cnpj}`)
        .then((res: AxiosResponse) => {
          addToast({
            type: 'success',
            title: `CNPJ encontrado com sucesso!`,
            description: `Informações da empresa preenchidas!`,
          });

          formRef.current?.setFieldValue('razao_social', res.data.nome ?? '');
          formRef.current?.setFieldValue('cep', res.data.cep ?? '');
          formRef.current?.setFieldValue(
            'contato_financeiro_email',
            res.data.email ?? ''
          );
          formRef.current?.setFieldValue(
            'contato_financeiro_telefone',
            res.data.telefone ?? ''
          );
        })
        .catch((err: AxiosError) => {
          addToast({
            type: 'error',
            title:
              typeof err.response?.data.message === 'string'
                ? err.response?.data.message.charAt(0).toUpperCase() +
                  err.response?.data.message.slice(1)
                : 'Ocorreu um erro',
            description: `Ocorreu um erro ao buscar o cnpj, cheque as informações e tente novamente.`,
          });
          console.error(`Erro: ${err}`);
        });
    },
    [addToast]
  );

  const saveBtn: ISaveBtn = {
    label: 'Enviar',
    loading,
  };

  const inputs: IInput[] = [
    {
      name: 'nome',
      label: 'Nome',
      tipo: 'input',
      required: true,
    },
    {
      name: 'cnpj',
      label: 'CNPJ',
      tipo: 'mask',
      mask: '99.999.999/9999-99',
      onBlurFnc: searchCNPJ,
    },
    {
      name: 'razao_social',
      label: 'Razão Social',
      tipo: 'input',
    },
    {
      name: 'cpf',
      label: 'CPF',
      tipo: 'mask',
      mask: '999.999.999-99',
    },
    {
      name: 'email',
      label: 'E-mail',
      tipo: 'input',
      type: 'email',
      required: true,
    },
    {
      name: 'email_2',
      label: 'E-mail 2',
      tipo: 'input',
      type: 'email',
    },
    {
      name: 'cep',
      label: 'CEP',
      tipo: 'mask',
      mask: '99999-999',
      required: true,
      onBlurFnc: searchCEP,
    },
    {
      name: 'endereco',
      label: 'Endereço',
      tipo: 'input',
      required: true,
    },
    {
      name: 'bairro',
      label: 'Bairro',
      tipo: 'input',
      required: true,
    },
    {
      name: 'cidade',
      label: 'Cidade',
      tipo: 'input',
      required: true,
    },
    {
      name: 'setor_cliente_id',
      label: 'Setor do cliente',
      tipo: 'select',
      required: true,
      selectData: setoresCliente,
    },
    {
      name: 'id_vhsys',
      label: 'ID do VHSYS',
      tipo: 'input',
      required: false,
    },
    {
      name: 'contato_financeiro',
      label: 'Nome Contato Financeiro',
      tipo: 'input',
      required: false,
    },
    {
      name: 'contato_financeiro_telefone',
      label: 'Telefone Contato Financeiro',
      tipo: 'phone',
      required: false,
    },
    {
      name: 'contato_financeiro_email',
      label: 'E-mail Contato Financeiro',
      tipo: 'input',
      type: 'email',
      required: false,
    },
    {
      name: 'nome_contato',
      label: 'Nome Contato',
      tipo: 'input',
      required: true,
    },
    {
      name: 'telefone',
      label: 'Telefone Contato',
      tipo: 'phone',
      required: true,
    },
    {
      name: 'nome_contato_2',
      label: 'Nome Contato 2',
      tipo: 'input',
      required: false,
    },
    {
      name: 'telefone_2',
      label: 'Telefone Contato 2',
      tipo: 'phone',
      required: false,
    },
    {
      name: 'nome_contato_3',
      label: 'Nome Contato 3',
      tipo: 'input',
      required: false,
    },
    {
      name: 'telefone_3',
      label: 'Telefone Contato 3',
      tipo: 'phone',
      required: false,
    },
  ];

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          email: Yup.string().email().required('E-mail obrigatório'),
          endereco: Yup.string().required('Endereço obrigatório'),
          bairro: Yup.string().required('Bairro obrigatório'),
          cidade: Yup.string().required('Cidade obrigatória'),
          cep: Yup.string()
            .required('CEP obrigatório')
            .matches(/^\d{5}-\d{3}$/, 'Formato do cep inválido'),
          nome_contato: Yup.string().required('Nome do contato obrigatório'),
          telefone: Yup.string().required('Telefone obrigatório'),
          id_vhsys: Yup.string(),
          setor_cliente_id: Yup.string().required(
            'Setor do cliente obrigatório'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api
          .post(`/${module}s`, data)
          .then((res: AxiosResponse) => {
            const { id } = res.data;
            addToast({
              type: 'success',
              title: `${module_label}${
                id && ` de id: ${id}`
              } criado com sucesso`,
            });
            history.push(`/cadastros/${module}s`);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: err.message || `Erro na criação do ${module}`,
          description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [addToast, history]
  );

  const getSetoresCliente = useCallback(async () => {
    await api
      .get('/setores-cliente')
      .then(async (res: AxiosResponse) => {
        const tmp: ISetoroesClienteDTO[] = res.data;
        const final: ISelectDTO[] = [];

        for (let i = 0; i < tmp.length; i++) {
          const tmp1 = tmp[i];

          final.push({
            label: tmp1.nome,
            value: tmp1.id.toString(),
          });
        }

        setSetoresCliente(final);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: `Ocorreu um erro ao buscar os setores, tente novamente.`,
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getSetoresCliente();
  }, [getSetoresCliente]);

  return (
    <Container>
      <Title>Novo {module}:</Title>
      <Desc>
        Campos com <b>* (asterisco)</b> são obrigatórios
        <br />
        Para um preenchimento mais rápido, preencha o <b>cnpj/cep</b> e clique
        fora da <b>caixa de texto</b>, para preencher as informações encontradas
      </Desc>
      <FormConstructor
        color="quaternary"
        formColumns="1fr 1fr"
        formRef={formRef}
        inputs={inputs}
        saveBtn={saveBtn}
        submitFnc={handleSubmit}
      />
    </Container>
  );
};

export default NovoCliente;
