/* eslint-disable react/destructuring-assignment */
import React, { useState, useCallback, useRef, SetStateAction } from 'react';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';
import * as Yup from 'yup';
import { AxiosError } from 'axios';

import { Form } from '@unform/web';
import Select from 'components/Select';
import { FormHandles } from '@unform/core';
import Input from 'components/Input';

// Styled components
import Textarea from 'components/Input/textarea';
import { ISelectDTO } from 'utils/DTOS';
import { useToast } from 'hooks/toast';
import getValidationErrors from 'utils/getValidationErrors';
import api from 'services/api';
import InputCurrency from 'components/Input/currency';
import Checkbox from 'components/Checkbox';
import {
  Title,
  Body,
  FormRow,
  InputHolder,
  Divisor,
  WarnCondicao,
  ConfirmButton,
} from './styles';

interface IModalAttatchment {
  projeto_id: number;
  update: boolean;
  setUpdate: React.Dispatch<SetStateAction<boolean>>;
}

interface IFormData {
  pagamento_condicao_id: number;
  pagamento_vencimento?: string;
  pagamento_valor: string;
  pagamento_gatilho?: number;
  pagamento_obs_gatilho?: string;
  pagamento_vencimento_gatilho?: string;
  pagamento_vinculo_financeiro: string;
}

interface IData {
  valor: number;
  vencimento?: string;
  gatilho?: number;
  obs_gatilho?: string;
  condicao_pagamento: number;
  projeto_id: number;
  forma_pagamento_id?: number;
  vinculo_financeiro?: string;
}

const FormaPagamentoModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [condicao, setCondicao] = useState<ISelectDTO | undefined>(undefined); // 0 - Parcela / 1 - Medição / 2 - Gatilho

  const condicoes_pagamento: ISelectDTO[] = [
    {
      label: 'Parcela',
      value: 0,
    },
    {
      label: 'Medição',
      value: 1,
    },
    {
      label: 'Gatilho',
      value: 2,
    },
  ];

  const gatilhos = [
    {
      label: 'Protocolo',
      value: 0,
    },
    {
      label: 'Conclusão',
      value: 1,
    },
    {
      label: 'Entrega Parcial',
      value: 3,
    },
    {
      label: 'Outros 1',
      value: 4,
    },
    {
      label: 'Outros 2',
      value: 5,
    },
    {
      label: 'Outros 3',
      value: 2,
    },
  ];

  const { projeto_id, setUpdate, update, children } = props;

  const { addToast } = useToast();

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    formRef.current?.reset();
    setVisible(false);
  }, []);

  const condicaoChange = useCallback((data: any) => {
    setCondicao(data);
  }, []);

  const tratarValorTotal = useCallback((valor: string) => {
    const valorString = valor
      .replace('R$ ', '')
      .replace('.', '')
      .replace(',', '.');

    const split = valorString.split('.');

    if (
      split.length > 1 &&
      split[split.length - 1] &&
      split[split.length - 1].length === 3
    ) {
      split[split.length - 1] = split[split.length - 1].substring(
        0,
        split[split.length - 1].length - 1
      );
    }

    const valorFinal = parseFloat(`${split[0]}.${split[1]}`);

    return valorFinal || 0;
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        let schema;

        if (data.pagamento_condicao_id !== 2) {
          schema = Yup.object().shape({
            pagamento_condicao_id: Yup.number().required(
              'A condição de pagamento é obrigatória'
            ),
            pagamento_vencimento: Yup.date().required(
              'O vencimento é obrigatório'
            ),
            pagamento_valor: Yup.string().required('O valor é obrigatório'),
          });
        } else {
          schema = Yup.object().shape({
            pagamento_condicao_id: Yup.number().required(
              'A condição de pagamento é obrigatória'
            ),
            pagamento_valor: Yup.string().required('O valor é obrigatório'),
            pagamento_gatilho: Yup.number().required('O gatilho é obrigatório'),
            pagamento_obs_gatilho: Yup.string(),
            pagamento_vencimento_gatilho: Yup.date().required(
              'A previsão é obrigatória'
            ),
          });
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        console.log(data);

        const final_data: IData = {
          valor: tratarValorTotal(data.pagamento_valor),
          vencimento:
            data.pagamento_condicao_id !== 2
              ? data.pagamento_vencimento
              : data.pagamento_vencimento_gatilho,
          gatilho: data.pagamento_gatilho,
          obs_gatilho: data.pagamento_obs_gatilho,
          condicao_pagamento: data.pagamento_condicao_id,
          projeto_id,
          vinculo_financeiro: data.pagamento_vinculo_financeiro[0] || 'false',
        };

        console.log(final_data);

        api
          .post('/pagamentos-projetos', final_data)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Forma de pagamento criada com sucesso',
            });
            setUpdate(!update);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description:
                'Ocorreu um erro ao criar a forma de pagamento, cheque as informações e tente novamente.',
            });
            console.error(`Erro: ${err}`);

            handleCancel();
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          console.error(errors);
        }

        console.error(`Erro: ${err}`);

        addToast({
          type: 'error',
          title: 'Erro na criação da forma de pagamento',
          description:
            'Ocorreu um erro ao criar a forma de pagamento, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, handleCancel, projeto_id, setUpdate, update, tratarValorTotal]
  );

  return (
    <>
      <button
        type="button"
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal main"
        wrapClassName="wrapForm"
      >
        <Title>Novo pagamento</Title>
        <Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormRow>
              <InputHolder style={{ width: '45%' }}>
                <label htmlFor="pagamento_valor">Valor*</label>
                <InputCurrency
                  placeholder="Digite o valor"
                  name="pagamento_valor"
                />
              </InputHolder>
              <InputHolder style={{ width: '45%' }}>
                <label htmlFor="pagamento_condicao_id">
                  Condição de pagamento*
                </label>
                <Select
                  name="pagamento_condicao_id"
                  options={condicoes_pagamento}
                  onChange={data => condicaoChange(data)}
                />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder>
                <Checkbox
                  name="pagamento_vinculo_financeiro"
                  options={[
                    {
                      name: 'pagamento_vinculo_financeiro',
                      value: 'true',
                      label: 'Possuí vinculo financeiro?',
                    },
                  ]}
                />
              </InputHolder>
            </FormRow>
            <Divisor />
            {condicao && condicao.value !== 2 ? (
              <FormRow>
                <InputHolder style={{ width: '100%' }}>
                  <label htmlFor="pagamento_vencimento">Vencimento*</label>
                  <Input name="pagamento_vencimento" type="date" />
                </InputHolder>
              </FormRow>
            ) : condicao && condicao.value === 2 ? (
              <>
                <FormRow>
                  <InputHolder style={{ width: '90%' }}>
                    <label htmlFor="pagamento_gatilho">Gatilho*</label>
                    <Select name="pagamento_gatilho" options={gatilhos} />
                  </InputHolder>
                </FormRow>
                <FormRow>
                  <InputHolder style={{ width: '45%' }}>
                    <label htmlFor="pagamento_vencimento_gatilho">
                      Previsão*
                    </label>
                    <Input name="pagamento_vencimento_gatilho" type="date" />
                  </InputHolder>
                </FormRow>
                <FormRow>
                  <InputHolder style={{ width: '100%' }}>
                    <label htmlFor="pagamento_obs_gatilho">
                      Observação do Gatilho
                    </label>
                    <Textarea
                      name="pagamento_obs_gatilho"
                      placeholder="Observação do Gatilho"
                    />
                  </InputHolder>
                </FormRow>
              </>
            ) : (
              <></>
            )}
          </Form>
        </Body>
        {condicao ? (
          <ConfirmButton
            type="button"
            onClick={() => formRef.current?.submitForm()}
            disabled={loading}
          >
            {loading ? 'Carregando...' : 'Confirmar'}
          </ConfirmButton>
        ) : (
          <WarnCondicao>Selecione a condição de pagamento</WarnCondicao>
        )}
      </Modal>
    </>
  );
};

export default FormaPagamentoModal;
