/* eslint-disable react/destructuring-assignment */
import React, {
  useState,
  useCallback,
  useRef,
  SetStateAction,
  useEffect,
} from 'react';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';

import { Form } from '@unform/web';
import Select from 'components/Select';
import { FormHandles } from '@unform/core';
import Input from 'components/Input';

// Styled components
import Textarea from 'components/Input/textarea';
import { ISelectDTO, ITerceirosDTO } from 'utils/DTOS';
import { useToast } from 'hooks/toast';
import getValidationErrors from 'utils/getValidationErrors';
import api from 'services/api';
import InputCurrency from 'components/Input/currency';
import {
  Title,
  Body,
  FormRow,
  InputHolder,
  Divisor,
  WarnCondicao,
  ConfirmButton,
} from './styles';

interface IModalAttatchment {
  projeto_id: number;
  update: boolean;
  setUpdate: React.Dispatch<SetStateAction<boolean>>;
}

interface IFormData {
  terceiro_id: number;
  terceiro_descricao: string;
  terceiro_valor: string;
}

interface IData {
  terceiro_id: number;
  descricao: string;
  valor: number;
  projeto_id: number;
}

const TerceirosModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [terceiros, setTerceiros] = useState<ISelectDTO[]>([]);

  const { projeto_id, setUpdate, update, children } = props;

  const { addToast } = useToast();

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    formRef.current?.reset();
    setVisible(false);
  }, []);

  const getTerceiros = useCallback(async () => {
    await api
      .get('/terceiros')
      .then(async (res: AxiosResponse) => {
        const tmp_terceiros: ITerceirosDTO[] = res.data;
        const final_terceiros: ISelectDTO[] = [];

        for (let i = 0; i < tmp_terceiros.length; i++) {
          const tmp_terceiro = tmp_terceiros[i];

          final_terceiros.push({
            label: tmp_terceiro.nome,
            value: tmp_terceiro.id.toString(),
          });
        }

        setTerceiros(final_terceiros);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os terceiros, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getTerceiros();
  }, [getTerceiros]);

  const tratarValorTotal = useCallback((valor: string) => {
    const valorString = valor
      .replace('R$ ', '')
      .replace('.', '')
      .replace(',', '.');

    const split = valorString.split('.');

    if (
      split.length > 1 &&
      split[split.length - 1] &&
      split[split.length - 1].length === 3
    ) {
      split[split.length - 1] = split[split.length - 1].substring(
        0,
        split[split.length - 1].length - 1
      );
    }

    const valorFinal = parseFloat(`${split[0]}.${split[1]}`);

    return valorFinal || 0;
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          terceiro_id: Yup.number().required(
            'O terceiro do serviço é obrigatório'
          ),
          terceiro_descricao: Yup.string().required(
            'A descrição do serviço é obrigatória'
          ),
          terceiro_valor: Yup.string().required(
            'O valor do serviço é obrigatório'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const final_data: IData = {
          valor: tratarValorTotal(data.terceiro_valor),
          descricao: data.terceiro_descricao,
          terceiro_id: data.terceiro_id,
          projeto_id,
        };

        api
          .post('/terceiros-projetos', final_data)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Serviço de terceiro criado com sucesso',
            });
            setUpdate(!update);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description:
                'Ocorreu um erro ao criar o serviço de terceiro, cheque as informações e tente novamente.',
            });
            console.error(`Erro: ${err}`);

            handleCancel();
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          console.error(errors);
        }

        console.error(`Erro: ${err}`);

        addToast({
          type: 'error',
          title: 'Erro na criação do serviço de terceiro',
          description:
            'Ocorreu um erro ao criar o serviço de terceiro, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, handleCancel, projeto_id, setUpdate, update, tratarValorTotal]
  );

  return (
    <>
      <button
        type="button"
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal main"
        wrapClassName="wrapForm"
      >
        <Title>Novo serviço de terceiro</Title>
        <Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormRow>
              <InputHolder style={{ width: '45%' }}>
                <label htmlFor="terceiro_id">Terceiro*</label>
                <Select name="terceiro_id" options={terceiros} />
              </InputHolder>
              <InputHolder style={{ width: '45%' }}>
                <label htmlFor="terceiro_valor">Valor*</label>
                <InputCurrency
                  placeholder="Digite o valor"
                  name="terceiro_valor"
                />
              </InputHolder>
            </FormRow>
            <FormRow>
              <InputHolder style={{ width: '100%' }}>
                <label htmlFor="terceiro_descricao">Descrição*</label>
                <Textarea
                  name="terceiro_descricao"
                  placeholder="Digite a descrição e a forma de pagamento do serviço do terceiro"
                />
              </InputHolder>
            </FormRow>
          </Form>
        </Body>
        <ConfirmButton
          type="button"
          onClick={() => formRef.current?.submitForm()}
          disabled={loading}
        >
          {loading ? 'Carregando...' : 'Confirmar'}
        </ConfirmButton>
      </Modal>
    </>
  );
};

export default TerceirosModal;
