/* eslint-disable react/jsx-indent */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, {
  useState,
  useCallback,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';
import * as Yup from 'yup';

import Input from 'components/Input';
import {
  IClientesDTO,
  ILPSDTO,
  IProjetosDTO,
  ISelectDTO,
  IServicosDTO,
  ITarefasDTO,
} from 'utils/DTOS';
import Select from 'components/Select';
import Textarea from 'components/Input/textarea';
import Checkbox from 'components/Checkbox';
import Spinner from 'components/Spinner';
import { AxiosError, AxiosResponse } from 'axios';
import getValidationErrors from 'utils/getValidationErrors';
import { format } from 'date-fns';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

// Styled components
import {
  Title,
  FormContainer,
  SaveButton,
  FormRow,
  InputHolder,
  ChecklistContainer,
  Checklist,
} from './styles';

interface IModalAttatchment {
  update?: boolean;
  setUpdate?: React.Dispatch<SetStateAction<boolean>>;
}

const NovaTarefaModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [clientes, setClientes] = useState<ISelectDTO[]>([]);
  const [lps, setLps] = useState<ISelectDTO[]>([]);
  const [projetos, setProjetos] = useState<ISelectDTO[]>([]);
  const [servicos, setServicos] = useState<ISelectDTO[]>([]);

  const { children, setUpdate, update } = props;

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const getClientes = useCallback(async () => {
    await api
      .get('/clientes/wlps')
      .then(async (res: AxiosResponse) => {
        const tmp_clientes: IClientesDTO[] = res.data;
        const final_clientes: ISelectDTO[] = [];

        for (let i = 0; i < tmp_clientes.length; i++) {
          const tmp_cliente = tmp_clientes[i];

          final_clientes.push({
            label: tmp_cliente.nome,
            value: tmp_cliente.id.toString(),
          });
        }

        setClientes(final_clientes);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os clientes, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  const getServicos = useCallback(async () => {
    await api
      .get('/servicos/ativos')
      .then(async (res: AxiosResponse) => {
        setServicos(res.data);
        const tmp_servicos: IServicosDTO[] = res.data;
        const final_servicos: ISelectDTO[] = [];

        for (let i = 0; i < tmp_servicos.length; i++) {
          const tmp_Servico = tmp_servicos[i];

          if (!tmp_Servico.nome) {
            return;
          }

          final_servicos.push({
            label: tmp_Servico.nome,
            value: tmp_Servico.id.toString(),
          });
        }

        setServicos(final_servicos);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os serviços, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  const getLps = useCallback(async () => {
    await api
      .get('/lps')
      .then(async (res: AxiosResponse) => {
        const tmp_lps: ILPSDTO[] = res.data;
        const final_lps: ISelectDTO[] = [];

        for (let i = 0; i < tmp_lps.length; i++) {
          const tmp_lpss = tmp_lps[i];

          final_lps.push({
            label: tmp_lpss.nome,
            value: tmp_lpss.id.toString(),
          });
        }

        setLps(final_lps);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: 'Ocorreu um erro ao buscar os lps, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  const getProjetos = useCallback(async () => {
    await api
      .get('/projetos')
      .then(async (res: AxiosResponse) => {
        const tmp_projetos: IProjetosDTO[] = res.data;
        const final_projetos: ISelectDTO[] = [];

        for (let i = 0; i < tmp_projetos.length; i++) {
          const tmp_projeto = tmp_projetos[i];

          final_projetos.push({
            label: `${tmp_projeto.__cliente__.nome} > ${
              tmp_projeto.__lps__.nome
            } > ${tmp_projeto.id.toString()}`,
            value: tmp_projeto.id.toString(),
          });
        }

        setProjetos(final_projetos);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os projetos, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getClientes();
    getServicos();
    getLps();
    getProjetos();
  }, [getClientes, getServicos, getLps, getProjetos]);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string()
            .required('Nome obrigatório')
            .typeError('Formato do campo inválido'),
          projeto_id: Yup.string()
            .required('Projeto obrigatório')
            .typeError('Formato do campo inválido'),
          data_prazo: Yup.date()
            .required('Data de entrega obrigatória')
            .typeError('Formato do campo inválido'),
          cliente_id: Yup.string()
            .required('Cliente obrigatório')
            .typeError('Formato do campo inválido'),
          lps_id: Yup.string()
            .required('Lps obrigatória')
            .typeError('Formato do campo inválido'),
          servico_id: Yup.string()
            .required('Serviço obrigatório')
            .typeError('Formato do campo inválido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        api
          .post(`/tarefas`, data)
          .then(res => {
            addToast({
              type: 'success',
              title: `Tarefa criada com sucesso}`,
            });
            setUpdate && setUpdate(!update);
            handleCancel();
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description:
                'Ocorreu um erro ao criar a tarefa, cheque as informações e tente novamente.',
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na criação da tarefa',
          description:
            'Ocorreu um erro ao criar a tarefa, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, setUpdate, update, handleCancel]
  );

  return (
    <>
      <button
        type="button"
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal secundary"
        wrapClassName="wrapForm"
      >
        {loading ? (
          <Spinner size={50} />
        ) : (
          <>
            <Title>Nova tarefa</Title>
            <FormContainer>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <FormRow>
                  <InputHolder>
                    <label htmlFor="nome">Nome:</label>
                    <Input
                      name="nome"
                      placeholder="Digite o nome da tarefa aqui"
                    />
                  </InputHolder>
                  <InputHolder>
                    <label htmlFor="projeto_id">Projeto*:</label>
                    <Select name="projeto_id" options={projetos} />
                  </InputHolder>
                </FormRow>
                <FormRow>
                  <InputHolder>
                    <label htmlFor="data_prazo">Data Prazo*:</label>
                    <Input type="date" name="data_prazo" />
                  </InputHolder>
                  <InputHolder>
                    <label htmlFor="cliente_id">Cliente*:</label>
                    <Select name="cliente_id" options={clientes} />
                  </InputHolder>
                </FormRow>
                <FormRow>
                  <InputHolder>
                    <label htmlFor="lps_id">LPS*:</label>
                    <Select name="lps_id" options={lps} />
                  </InputHolder>
                  <InputHolder>
                    <label htmlFor="servico_id">Serviços*:</label>
                    <Select name="servico_id" options={servicos} />
                  </InputHolder>
                </FormRow>
                <SaveButton
                  type="button"
                  onClick={() => formRef.current?.submitForm()}
                >
                  {loading ? <Spinner size={15} /> : 'Criar'}
                </SaveButton>
              </Form>
            </FormContainer>
          </>
        )}
      </Modal>
    </>
  );
};

export default NovaTarefaModal;
