/* eslint-disable no-param-reassign */
import { AxiosError, AxiosResponse } from 'axios';
import EditInTable from 'components/EditInTable';
import Spinner from 'components/Spinner';
import { format } from 'date-fns';
import { useToast } from 'hooks/toast';
import React, { useCallback, useEffect, useState } from 'react';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import api from 'services/api';

import { InputProps } from 'components/EditInTable';
import { Line, List, LineItem } from 'styles/others';
import { IPagamentosProjetoDTO, ISelectDTO } from 'utils/DTOS';
import { v4 } from 'uuid';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import { formatBRDate, formatDateInput } from 'utils/formatData';

interface IProps {
  projeto_id?: string;
}

interface IPagamentoProjeto extends IPagamentosProjetoDTO {
  editing: boolean;
  vencimentoString: string;
}

const PagamentosMedicaoList: React.FC<IProps> = props => {
  const { projeto_id } = props;

  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [pagamentos, setPagamentos] = useState<IPagamentoProjeto[]>([]);
  const [update, setUpdate] = useState<boolean>(false);

  const toggleEdit = useCallback(
    (id: number, state: number, index: number) => {
      const tmp_pagamentos = pagamentos.filter(
        pagamento => pagamento.id !== id
      );

      const editing = pagamentos.find(pagamento => pagamento.id === id);

      if (!editing) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao editar o pagamento, tente novamente.',
        });
        return;
      }

      editing.editing = !editing.editing;

      state === 1 && setUpdate(!update);

      setPagamentos([editing, ...tmp_pagamentos]);
    },
    [addToast, pagamentos, update]
  );

  const getPagamentos = useCallback(async () => {
    setLoading(true);
    if (!projeto_id) {
      return;
    }
    await api
      .get(`/pagamentos-projetos/projeto-medicao/${projeto_id}`)
      .then(async (res: AxiosResponse) => {
        let num_parcela = 1;
        res.data.forEach((dat: any) => {
          dat.num_parcela = num_parcela;
          dat.editing = false;
          dat.vencimentoString = formatBRDate(dat.vencimento);
          dat.vencimento = dat.vencimento
            ? formatDateInput(dat.vencimento)
            : undefined;
        });
        setPagamentos(res.data);
        num_parcela += 1;
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os pagamentos do projeto, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, projeto_id, update]);

  useEffect(() => {
    getPagamentos();
  }, [getPagamentos]);

  const inputData: InputProps[] = [
    {
      data: 'id',
      name: 'id',
      label: '#',
      tipo: 'input',
      noInput: true,
    },
    {
      data: 'des_status',
      label: 'Status',
      name: 'status',
      tipo: 'select',
      noInput: true,
    },
    {
      data: 'vencimento',
      label: 'Vencimento',
      name: 'vencimento',
      tipo: 'input',
      type: 'date',
      noInput: true,
    },
    {
      data: 'porcentagem',
      label: '%',
      name: 'porcentagem',
      tipo: 'input',
      type: 'number',
    },
  ];

  return (
    <>
      {loading ? (
        <Spinner size={15} />
      ) : pagamentos.length !== 0 ? (
        <List>
          <Line
            style={{
              gridTemplateColumns: '150px 1.5fr 1fr 150px 100px',
            }}
            className="indice"
          >
            <LineItem className="indice">N parcela</LineItem>
            <LineItem className="indice">Status</LineItem>
            <LineItem className="indice">Vencimento</LineItem>
            <LineItem className="indice">%</LineItem>
            <LineItem className="indice">Ações</LineItem>
          </Line>
          {pagamentos.map((pagamento, i) =>
            pagamento.editing ? (
              <EditInTable
                key={v4()}
                update={update}
                setUpdate={setUpdate}
                data={pagamento}
                inputData={inputData}
                template="150px 1.5fr 1fr 150px 100px"
                edit_url="/pagamentos-projetos/pagamento-projeto"
                module="pagamentos"
              />
            ) : (
              <Line
                style={{
                  gridTemplateColumns: '150px 1.5fr 1fr 150px 100px',
                }}
                key={pagamento.id}
              >
                <LineItem id="index">{pagamento.id}</LineItem>
                <LineItem id="status">{pagamento.des_status}</LineItem>
                <LineItem id="vencimento">
                  {pagamento.vencimentoString}
                </LineItem>
                <LineItem id="nf">
                  {pagamento.porcentagem ? pagamento.porcentagem : '...'}
                </LineItem>
                <LineItem id="acoes">
                  <FaPencilAlt onClick={() => toggleEdit(pagamento.id, 0, i)} />
                  {/* <ConfirmDeleteModal
                    delete_url="/pagamentos-projetos/pagamento-projeto"
                    id={pagamento.id.toString()}
                    module="pagamentos"
                    setUpdate={setUpdate}
                    update={update}
                  >
                    <FaTrash />
                  </ConfirmDeleteModal> */}
                </LineItem>
              </Line>
            )
          )}
        </List>
      ) : (
        'Nenhum pagamento encontrado para esse projeto'
      )}
    </>
  );
};

export default PagamentosMedicaoList;
