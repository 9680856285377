import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router';

import FormConstructor, { IInput, ISaveBtn } from 'components/FormConstructor';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import getValidationErrors from 'utils/getValidationErrors';

import { Container, FormContainer, Title, Desc } from '../novo-styles';

const NovoTerceiro: React.FC = () => {
  const module = 'terceiro';
  const module_label = 'Terceiro';
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { addToast } = useToast();

  const searchCNPJ = useCallback(
    event => {
      const cnpj = event.target.value.replace(/\D/g, '');
      api
        .get(`/extras/cnpj/${cnpj}`)
        .then((res: AxiosResponse) => {
          addToast({
            type: 'success',
            title: `CNPJ encontrado com sucesso!`,
            description: `Informações da empresa preenchidas!`,
          });

          formRef.current?.setFieldValue('nome', res.data.nome);
          formRef.current?.setFieldValue('email', res.data.email);
          formRef.current?.setFieldValue('telefone', res.data.telefone);
        })
        .catch((err: AxiosError) => {
          addToast({
            type: 'error',
            title:
              typeof err.response?.data.message === 'string'
                ? err.response?.data.message.charAt(0).toUpperCase() +
                  err.response?.data.message.slice(1)
                : 'Ocorreu um erro',
            description: `Ocorreu um erro ao buscar o cnpj, cheque as informações e tente novamente.`,
          });
          console.error(`Erro: ${err}`);
        });
    },
    [addToast]
  );

  const saveBtn: ISaveBtn = {
    label: 'Enviar',
    loading,
  };

  const inputs: IInput[] = [
    {
      name: 'cpf_cnpj',
      label: 'CPF / CNPJ',
      tipo: 'input',
      required: true,
      onBlurFnc: searchCNPJ,
    },
    {
      name: 'nome',
      label: 'Nome',
      tipo: 'input',
      required: true,
    },
    {
      name: 'banco',
      label: 'Código do Banco',
      tipo: 'input',
      type: 'number',
      required: true,
    },
    {
      name: 'agencia',
      label: 'Agencia',
      tipo: 'input',
      type: 'number',
      required: true,
    },
    {
      name: 'conta_corrente',
      label: 'Conta Corrente',
      tipo: 'input',
      type: 'number',
      required: true,
    },
    {
      name: 'chave_pix',
      label: 'Chave Pix',
      tipo: 'input',
    },
    {
      name: 'telefone',
      label: 'Telefone',
      tipo: 'phone',
      required: true,
    },
    {
      name: 'email',
      label: 'E-mail',
      tipo: 'input',
      type: 'email',
      required: true,
    },
  ];

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          cpf_cnpj: Yup.string()
            .required('CPF ou CNPJ obrigatório')
            .matches(
              /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/,
              'O CPF/CNPJ não está no formato correto (999.999.999-99(CPF) ou 99.999.999/0001-99(CNPJ))'
            ),
          banco: Yup.string().required('Banco obrigatório'),
          agencia: Yup.string().required('Agência obrigatória'),
          conta_corrente: Yup.string().required('Conta corrente obrigatória'),
          chave_pix: Yup.string(),
          telefone: Yup.string().required('Telefone obrigatório'),
          email: Yup.string()
            .email('E-mail inválido')
            .required('E-mail obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api
          .post(`/${module}s`, data)
          .then((res: AxiosResponse) => {
            const { id } = res.data;
            addToast({
              type: 'success',
              title: `${module_label}${
                id && ` de id: ${id}`
              } criado com sucesso`,
            });
            history.push(`/cadastros/${module}s`);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: err.message || `Erro na criação do ${module}`,
          description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [addToast, history]
  );

  return (
    <Container>
      <Title>Novo {module_label}:</Title>
      <Desc>
        Campos com <b>* (asterisco)</b> são obrigatórios
        <br />
        Para um preenchimento mais rápido, preencha o <b>cnpj</b> e clique fora
        da <b>caixa de texto</b>, para preencher as informações encontradas
      </Desc>
      <FormContainer>
        <FormConstructor
          color="quaternary"
          formColumns="1fr"
          formRef={formRef}
          inputs={inputs}
          saveBtn={saveBtn}
          submitFnc={handleSubmit}
        />
      </FormContainer>
    </Container>
  );
};

export default NovoTerceiro;
