import { AxiosError, AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FaSearch } from 'react-icons/fa';
import { RiFilterOffFill } from 'react-icons/ri';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import { useToast } from 'hooks/toast';
import api from 'services/api';
import BoxContainer from 'components/BoxContainer';
import Select from 'components/Select';
import Button from 'components/Button';
import {
  IClientesDTO,
  IDepartamentosDTO,
  ILPSDTO,
  IPaginateDTO,
  IProjetosDTO,
  ISelectDTO,
  IServicosDTO,
} from 'utils/DTOS';
import ListagemNoGetPaginated, {
  IRow,
} from 'components/Listagem/no-get-paginated';

import { createFilteredUrl, IOrder } from 'utils/etc';
import { FilterHolder, FilterItemHolder } from 'styles/others';
import Input from 'components/Input';
import { Container, TitleHolder, ListHolder } from './styles';

export interface IFilter {
  [key: string]: any;
  id?: string;
  status?: string;
  faturar_por?: string;
  criacao_inicio?: string;
  criacao_fim?: string;
  cliente?: string;
  lps?: string;
  servico?: string;
  departamento?: string;
}

interface IProjetosPaginated extends Omit<IPaginateDTO, 'data'> {
  data: IProjetosDTO;
}

const Projeto: React.FC | any = ({ location }: any) => {
  const { addToast } = useToast();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [oldUrl, setOldUrl] = useState<string | undefined>(undefined);
  const [filter, setFilter] = useState<IFilter | undefined>(undefined);
  const [projetos, setProjetos] = useState<IProjetosPaginated | undefined>();
  const [actualPage, setActualPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(25);
  const [clientes, setClientes] = useState<ISelectDTO[]>([]);
  const [departamentos, setDepartamentos] = useState<ISelectDTO[]>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAll, setLoadingAll] = useState<boolean>(false);
  const [servicos, setServicos] = useState<ISelectDTO[]>([]);
  const [orderBySel, setOrderBy] = useState<string>('id');
  const [orderByDir, setOrderByDir] = useState<'ASC' | 'DESC'>('DESC');
  const [lps, setLps] = useState<ISelectDTO[]>([]);

  // 0 - Iniciar / 1 - Andamento / 2 - Protocolado / 3 - Concluido / 4 - Stand-by / 5 - Cancelado / 6 - Contínuo / 7 - Entregue
  const status: ISelectDTO[] = [
    { value: '0', label: 'Iniciar' },
    { value: '1', label: 'Andamento' },
    { value: '2', label: 'Protocolado' },
    { value: '3', label: 'Concluido' },
    { value: '4', label: 'Stand-by' },
    { value: '5', label: 'Cancelado' },
    { value: '6', label: 'Contínuo' },
    { value: '7', label: 'Entregue' },
  ];

  // 0 - Tecnologia / 1 - Gestão
  const faturar_por: ISelectDTO[] = [
    { value: '0', label: 'Tecnologia' },
    { value: '1', label: 'Gestão' },
  ];

  const getQueryParams = useCallback(() => {
    setFilter(qs.parse(location.search, { ignoreQueryPrefix: true }));
  }, [location]);

  // useEffect(() => {
  //   getQueryParams();
  // }, [getQueryParams]);

  const onFilter = useCallback(
    (data: IFilter) => {
      if (
        (data.criacao_inicio && !data.criacao_fim) ||
        (!data.criacao_inicio && data.criacao_fim)
      ) {
        addToast({
          type: 'error',
          title: 'Selecione o início e fim da data de criação',
        });
      }

      setFilter(data);

      let query = '';
      Object.keys(data).map(key => {
        if (data[key]) {
          query += `${query === '' ? '?' : '&'}${key}=${data[key]}`;
        }
      });

      history.push({
        pathname: location.pathname,
        search: query,
      });
    },
    [addToast, location, history]
  );

  const getClientes = useCallback(async () => {
    await api
      .get('/clientes')
      .then(async (res: AxiosResponse) => {
        const tmp_clientes: IClientesDTO[] = res.data;
        const final_clientes: ISelectDTO[] = [];

        for (let i = 0; i < tmp_clientes.length; i++) {
          const tmp_cliente = tmp_clientes[i];

          final_clientes.push({
            label: tmp_cliente.nome,
            value: tmp_cliente.id.toString(),
          });
        }

        setClientes(final_clientes);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os clientes, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getClientes();
  }, [getClientes]);

  const getServicos = useCallback(async () => {
    await api
      .get('/servicos')
      .then(async (res: AxiosResponse) => {
        const tmp_servicos: IServicosDTO[] = res.data;
        const final_servicos: ISelectDTO[] = [];

        for (let i = 0; i < tmp_servicos.length; i++) {
          const tmp_servico = tmp_servicos[i];

          final_servicos.push({
            label: tmp_servico.nome,
            value: tmp_servico.id.toString(),
          });
        }

        setServicos(final_servicos);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os serviços, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getServicos();
  }, [getServicos]);

  const getLps = useCallback(async () => {
    await api
      .get('/lps')
      .then(async (res: AxiosResponse) => {
        const tmp_lpss: ILPSDTO[] = res.data;
        const final_lps: ISelectDTO[] = [];

        for (let i = 0; i < tmp_lpss.length; i++) {
          const tmp_lps = tmp_lpss[i];

          final_lps.push({
            label: tmp_lps.nome,
            value: tmp_lps.id.toString(),
          });
        }

        setLps(final_lps);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: 'Ocorreu um erro ao buscar as lps, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getLps();
  }, [getLps]);

  const getDepartamentos = useCallback(async () => {
    await api
      .get('/departamentos')
      .then(async (res: AxiosResponse) => {
        const tmp_departamentos: IDepartamentosDTO[] = res.data;
        const final_departamentos: ISelectDTO[] = [];

        for (let i = 0; i < tmp_departamentos.length; i++) {
          const tmp_departamento = tmp_departamentos[i];

          final_departamentos.push({
            label: tmp_departamento.nome,
            value: tmp_departamento.id.toString(),
          });
        }

        setDepartamentos(final_departamentos);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os departamentos, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getDepartamentos();
  }, [getDepartamentos]);

  const rows: IRow[] = [
    {
      label: '#',
      data: 'id',
    },
    {
      label: 'Status',
      data: 'status',
    },
    {
      label: 'Cliente',
      data: '__cliente__.nome',
    },
    {
      label: 'LPS',
      data: '__lps__.nome',
    },
    {
      label: 'Serviço',
      data: '__servico__.nome',
    },
    {
      label: '%',
      data: 'progresso',
    },
    {
      label: 'Proj. Vinc.',
      data: '__vinculo__.id',
    },
    {
      label: 'Depart.',
      data: '__servico__.depart',
    },
    {
      label: 'Tec/Ges',
      data: 'des_faturar_por_2',
    },
  ];

  const getProjetos = useCallback(async () => {
    setLoading(true);

    const order: IOrder = {
      orderBy: orderBySel,
      orderByDir,
    };

    const url = createFilteredUrl(
      `/projetos/list-paginated?page=${actualPage}&per_page=${perPage}`,
      filter,
      order
    );

    if (oldUrl === url) {
      return;
    }

    setOldUrl(url);

    await api
      .get(url)
      .then(async (res: AxiosResponse) => {
        const tmp_data = [];
        for (let i = 0; i < res.data.data.length; i++) {
          const projeto = res.data.data[i];
          projeto.progresso = `${projeto.progresso.toFixed(0)}%`;
          // eslint-disable-next-line prefer-destructuring
          projeto.des_faturar_por_2 = projeto.des_faturar_por[0];
          const depp = projeto.__servico__.__departamento__.nome.split(' ');

          let depart = '';

          depp.forEach((dep: string) => {
            depart += dep[0];
          });

          projeto.__servico__.depart = depart;

          tmp_data.push(projeto);
        }

        res.data.data = tmp_data;

        setProjetos(res.data);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os projetos, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, actualPage, perPage, filter, orderByDir, orderBySel, update]);

  useEffect(() => {
    getProjetos();
  }, [getProjetos]);

  const getAllProjetos = useCallback(async () => {
    setLoadingAll(true);

    const response: any[] = await api
      .get(
        createFilteredUrl(
          `/projetos/list-paginated?page=1&per_page=1000000000000000000`,
          filter
        )
      )
      .then(async (res: AxiosResponse) => {
        const tmp_data = [];
        for (let i = 0; i < res.data.data.length; i++) {
          const projeto = res.data.data[i];
          projeto.progresso = `${projeto.progresso.toFixed(0)}%`;
          // eslint-disable-next-line prefer-destructuring
          projeto.des_faturar_por_2 = projeto.des_faturar_por[0];
          const depp = projeto.__servico__.__departamento__.nome.split(' ');

          let depart = '';

          depp.forEach((dep: string) => {
            depart += dep[0];
          });

          projeto.__servico__.depart = depart;

          tmp_data.push(projeto);
        }

        res.data.data = tmp_data;

        return res.data.data;
      })
      .catch((err: AxiosError) => {
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoadingAll(false);
      });

    return response;
  }, [addToast, actualPage, perPage, filter, update]);

  const clearFilter = useCallback(() => {
    setFilter(undefined);

    formRef.current?.clearField('id');
    formRef.current?.clearField('status');
    formRef.current?.clearField('faturar_por');
    formRef.current?.clearField('criacao_inicio');
    formRef.current?.clearField('criacao_fim');
    formRef.current?.clearField('faturar_por');
    formRef.current?.clearField('cliente');
    formRef.current?.clearField('servico');
    formRef.current?.clearField('lps');
    formRef.current?.clearField('departamento');
  }, []);

  // 'id' | 'status' | 'faturar_por' | 'cliente' | 'lps' | 'servico' | 'departamento' | 'created_at' | 'updated_at' | 'vinculo'
  const orderBy: ISelectDTO[] = [
    {
      label: '# ID',
      value: 'id',
    },
    {
      label: 'Status',
      value: 'status',
    },
    {
      label: 'Faturar Por',
      value: 'faturar_por',
    },
    {
      label: 'Cliente',
      value: 'cliente',
    },
    {
      label: 'LPS',
      value: 'lps',
    },
    {
      label: 'Serviço',
      value: 'servico',
    },
    {
      label: 'Departamento',
      value: 'departamento',
    },
    {
      label: 'Criação',
      value: 'created_at',
    },
    {
      label: 'Atualização',
      value: 'updated_at',
    },
    {
      label: 'Proj. Vinc.',
      value: 'vinculo',
    },
  ];

  return (
    <Container>
      <TitleHolder>
        Projetos:
        <button type="button" onClick={() => history.push('/projetos/novo')}>
          Novo projeto
        </button>
      </TitleHolder>
      <FilterHolder>
        <Form initialData={filter && filter} ref={formRef} onSubmit={onFilter}>
          <FilterItemHolder className="select">
            <label htmlFor="id">Id</label>
            <Input name="id" id="id" placeholder="Digite aqui..." />
          </FilterItemHolder>
          <FilterItemHolder className="select">
            <label htmlFor="status">Status</label>
            <Select options={status} name="status" id="status" />
          </FilterItemHolder>
          <FilterItemHolder className="select">
            <label htmlFor="faturar_por">Faturar por</label>
            <Select options={faturar_por} name="faturar_por" id="faturar_por" />
          </FilterItemHolder>
          <FilterItemHolder className="select">
            <label htmlFor="criacao_inicio">Data de Criação - Início</label>
            <Input name="criacao_inicio" id="criacao_inicio" type="date" />
          </FilterItemHolder>
          <FilterItemHolder className="select">
            <label htmlFor="criacao_fim">Data de Criação - Fim</label>
            <Input name="criacao_fim" id="criacao_fim" type="date" />
          </FilterItemHolder>
          <FilterItemHolder className="select">
            <label htmlFor="cliente">Cliente</label>
            <Select options={clientes} name="cliente" id="cliente" />
          </FilterItemHolder>
          <FilterItemHolder className="select">
            <label htmlFor="servico">Serviço</label>
            <Select options={servicos} name="servico" id="servico" />
          </FilterItemHolder>
          <FilterItemHolder className="select">
            <label htmlFor="lps">Lps</label>
            <Select options={lps} name="lps" id="lps" />
          </FilterItemHolder>
          <FilterItemHolder className="select">
            <label htmlFor="departamento">Departamentos</label>
            <Select
              options={departamentos}
              name="departamento"
              id="departamento"
            />
          </FilterItemHolder>
          <FilterItemHolder className="submit">
            <Button onClick={() => clearFilter()} type="button">
              <RiFilterOffFill size={18} />
            </Button>
            <Button type="submit">
              <FaSearch size={18} />
            </Button>
          </FilterItemHolder>
        </Form>
      </FilterHolder>
      <ListHolder>
        <BoxContainer borderColor="quaternary">
          <ListagemNoGetPaginated
            data={projetos}
            editUrl="/projetos/projeto"
            indexUrl="/projetos/projeto"
            module="projetos"
            template="80px 180px 3.5fr 3.5fr 4fr 100px 100px 100px 100px"
            deleteUrl="/projetos/projeto"
            rows={rows}
            loading={loading}
            setUpdate={setUpdate}
            update={update}
            actualPage={actualPage}
            setActualPage={setActualPage}
            perPage={perPage}
            setPerPage={setPerPage}
            utilsBar
            csvExport
            csvLoading={loadingAll}
            getAllData={getAllProjetos}
            filter={filter}
            orderby={orderBy}
            setOrderBy={setOrderBy}
            orderByDir={orderByDir}
            setOrderByDir={setOrderByDir}
          />
        </BoxContainer>
      </ListHolder>
    </Container>
  );
};

export default withRouter(Projeto);
