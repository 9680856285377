/* eslint-disable react/no-array-index-key */
import { AxiosError, AxiosResponse } from 'axios';
import { useToast } from 'hooks/toast';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, Redirect, useHistory } from 'react-router';
import { MdBusinessCenter, MdLocationOn } from 'react-icons/md';
import { TiBusinessCard } from 'react-icons/ti';
import { SiGooglemessages } from 'react-icons/si';

import api from 'services/api';

import {
  IClientesDTO,
  ILPSDTO,
  IProjetosDTO,
  ISelectDTO,
  IServicosDTO,
  IUsuariosDTO,
} from 'utils/DTOS';
import EscopoModal from 'components/EscopoModal';
import StatusModal from 'components/StatusModal';
import Spinner from 'components/Spinner';
import { AiOutlineFile } from 'react-icons/ai';
import { FaArrowRight } from 'react-icons/fa';
import { format } from 'date-fns';
import ModalAnexos from 'components/Anexos';
import ModalRegistrarChat from 'components/RegistrarChatModal';
import { useAuth } from 'hooks/auth';
import ProgressoModal from 'components/ProgressoModal';
import { formatDateInput, formatDateWithHour } from 'utils/formatData';
import ChatModal from 'components/ChatModal';
import {
  Container,
  HeaderHolder,
  HeaderItem,
  ProgressBarContainer,
  ProgressBarComplete,
  ProgressBarLiquid,
  BodyHolder,
  ContentHolder,
  AbasHolder,
  Aba,
  Content,
  LateralHolder,
  ChatHolder,
  ChatList,
  Chat,
  RegistrarHolder,
  Registrar,
  AnexoHolder,
  Anexo,
} from './styles';
import AbaFinanceiro from './abas/financeiro';
import AbaTecnico from './abas/tecnico';
import AbaComercial from './abas/comercial';

interface ParamsTypes {
  id: string | undefined;
}

const Projeto: React.FC = () => {
  const { id: projeto_id } = useParams<ParamsTypes>();

  const [loading, setLoading] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [projetoData, setProjetoData] = useState<IProjetosDTO | undefined>(
    undefined
  );
  const [statusColor, setStatusColor] = useState<string>('white');
  const [abaAtiva, setAbaAtiva] = useState<1 | 2 | 3>(2); // 1 - financeiro / 2 - técnico / 3 - comercial
  const { addToast } = useToast();
  const { cod_perfil: perfilUsuario } = useAuth().usuario;

  const statusList: ISelectDTO[] = useMemo(
    () =>
      projetoData && !projetoData.recorrente
        ? [
            { value: 0, label: 'Iniciar' },
            { value: 1, label: 'Andamento' },
            { value: 2, label: 'Protocolado' },
            { value: 3, label: 'Concluido' },
            { value: 4, label: 'Stand-by' },
            { value: 5, label: 'Cancelado' },
            { value: 6, label: 'Contínuo' },
            { value: 7, label: 'Entregue' },
          ]
        : [
            { value: 0, label: 'Iniciar' },
            { value: 6, label: 'Contínuo' },
            { value: 4, label: 'Stand-by' },
            { value: 5, label: 'Cancelado' },
            { value: 7, label: 'Entregue' },
          ],
    [projetoData]
  );

  const getStatusColor = useCallback(() => {
    // 0 - Iniciar / 1 - Andamento / 2 - Protocolado / 3 - Concluido / 4 - Stand-by / 5 - Cancelado / 6 - Contínuo / 7 - Entregue
    if (!projetoData) {
      return;
    }

    switch (parseInt(projetoData.status.toString())) {
      case 0:
        setStatusColor('#EBAD5F');
        break;
      case 1:
        setStatusColor('#3f51b4');
        break;
      case 2:
        setStatusColor('#03a8f3');
        break;
      case 3:
        setStatusColor('#8ac24a');
        break;
      case 4:
        setStatusColor('#ddc812');
        break;
      case 5:
        setStatusColor('#f34336');
        break;
      case 6:
        setStatusColor('#785548');
        break;
      case 7:
        setStatusColor('#23FF00');
        break;
      default:
        break;
    }
  }, [projetoData]);

  const [usuarios, setUsuarios] = useState<ISelectDTO[]>([]);

  const getUsuarios = useCallback(async () => {
    await api
      .get('/usuarios')
      .then(async (res: AxiosResponse) => {
        const tmp_usuarios: IUsuariosDTO[] = res.data;
        const final_usuarios: ISelectDTO[] = [];

        for (let i = 0; i < tmp_usuarios.length; i++) {
          const tmp_usuario = tmp_usuarios[i];

          final_usuarios.push({
            label: tmp_usuario.nome,
            value: tmp_usuario.id.toString(),
          });
        }

        setUsuarios(final_usuarios);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os usuários, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getUsuarios();
  }, [getUsuarios]);

  const getProjectData = useCallback(async () => {
    setLoading(true);

    await api
      .get(`/projetos/projeto-pag/${projeto_id}`)
      .then(async (res: AxiosResponse) => {
        res.data.status = res.data.status.toString();
        res.data.responsavel_id =
          res.data.__responsavel__ && res.data.__responsavel__.id.toString();
        res.data.data_inicio_previsto = res.data.data_inicio_previsto
          ? formatDateInput(res.data.data_inicio_previsto)
          : undefined;
        res.data.data_inicio_real = res.data.data_inicio_real
          ? formatDateInput(res.data.data_inicio_real)
          : undefined;
        res.data.protocolo_prazo = res.data.protocolo_prazo
          ? formatDateInput(res.data.protocolo_prazo)
          : undefined;
        res.data.protocolo_entrega = res.data.protocolo_entrega
          ? formatDateInput(res.data.protocolo_entrega)
          : undefined;
        res.data.protocolo_encerramento = res.data.protocolo_encerramento
          ? formatDateInput(res.data.protocolo_encerramento)
          : undefined;
        res.data.conclusao_prazo = res.data.conclusao_prazo
          ? formatDateInput(res.data.conclusao_prazo)
          : undefined;
        res.data.conclusao_entrega = res.data.conclusao_entrega
          ? formatDateInput(res.data.conclusao_entrega)
          : undefined;
        res.data.conclusao_encerramento = res.data.conclusao_encerramento
          ? formatDateInput(res.data.conclusao_encerramento)
          : undefined;
        res.data.outros3_prazo = res.data.outros3_prazo
          ? formatDateInput(res.data.outros3_prazo)
          : undefined;
        res.data.outros3_entrega = res.data.outros3_entrega
          ? formatDateInput(res.data.outros3_entrega)
          : undefined;
        res.data.outros3_encerramento = res.data.outros3_encerramento
          ? formatDateInput(res.data.outros3_encerramento)
          : undefined;
        res.data.entrega_parcial_prazo = res.data.entrega_parcial_prazo
          ? formatDateInput(res.data.entrega_parcial_prazo)
          : undefined;
        res.data.entrega_parcial_entrega = res.data.entrega_parcial_entrega
          ? formatDateInput(res.data.entrega_parcial_entrega)
          : undefined;
        res.data.entrega_parcial_encerramento = res.data
          .entrega_parcial_encerramento
          ? formatDateInput(res.data.entrega_parcial_encerramento)
          : undefined;
        res.data.outros1_prazo = res.data.outros1_prazo
          ? formatDateInput(res.data.outros1_prazo)
          : undefined;
        res.data.outros1_entrega = res.data.outros1_entrega
          ? formatDateInput(res.data.outros1_entrega)
          : undefined;
        res.data.outros1_encerramento = res.data.outros1_encerramento
          ? formatDateInput(res.data.outros1_encerramento)
          : undefined;
        res.data.outros2_prazo = res.data.outros2_prazo
          ? formatDateInput(res.data.outros2_prazo)
          : undefined;
        res.data.outros2_entrega = res.data.outros2_entrega
          ? formatDateInput(res.data.outros2_entrega)
          : undefined;
        res.data.outros2_encerramento = res.data.outros2_encerramento
          ? formatDateInput(res.data.outros2_encerramento)
          : undefined;
        res.data.data_encerramento = res.data.data_encerramento
          ? formatDateInput(res.data.data_encerramento)
          : undefined;
        res.data.faturar_por = res.data.faturar_por.toString();
        res.data.recebimento = res.data.recebimento.toString();

        setProjetoData(res.data);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: 'Ocorreu um erro ao buscar o projeto, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, projeto_id, update]);

  const getAbas = useCallback(() => {
    // perfil = 0 = padrão / 1 = admin / 2 = comercial / 3 = coord / 4 = técnico / 5 = coord financeiro
    switch (perfilUsuario) {
      case 1:
        return (
          <>
            <Aba
              className={abaAtiva === 1 ? 'selected' : ''}
              onClick={() => setAbaAtiva(1)}
              type="button"
            >
              Financeiro
            </Aba>
            <Aba
              className={abaAtiva === 2 ? 'selected' : ''}
              onClick={() => setAbaAtiva(2)}
              type="button"
            >
              Técnico
            </Aba>
            <Aba
              className={abaAtiva === 3 ? 'selected' : ''}
              onClick={() => setAbaAtiva(3)}
              type="button"
            >
              Comercial
            </Aba>
          </>
        );
      case 2 || projetoData?.__usuario_criador__?.cod_perfil === 2:
        return (
          <>
            <Aba
              className={abaAtiva === 1 ? 'selected' : ''}
              onClick={() => setAbaAtiva(1)}
              type="button"
            >
              Financeiro
            </Aba>
            <Aba
              className={abaAtiva === 2 ? 'selected' : ''}
              onClick={() => setAbaAtiva(2)}
              type="button"
            >
              Técnico
            </Aba>
            <Aba
              className={abaAtiva === 3 ? 'selected' : ''}
              onClick={() => setAbaAtiva(3)}
              type="button"
            >
              Comercial
            </Aba>
          </>
        );
      case 3:
        return (
          <>
            <Aba
              className={abaAtiva === 2 ? 'selected' : ''}
              onClick={() => setAbaAtiva(2)}
              type="button"
            >
              Técnico
            </Aba>
            <Aba
              className={abaAtiva === 3 ? 'selected' : ''}
              onClick={() => setAbaAtiva(3)}
              type="button"
            >
              Comercial
            </Aba>
          </>
        );
      case 4:
        return (
          <>
            <Aba
              className={abaAtiva === 2 ? 'selected' : ''}
              onClick={() => setAbaAtiva(2)}
              type="button"
            >
              Técnico
            </Aba>
            <Aba
              className={abaAtiva === 3 ? 'selected' : ''}
              onClick={() => setAbaAtiva(3)}
              type="button"
            >
              Comercial
            </Aba>
          </>
        );
      case 5:
        return (
          <>
            <Aba
              className={abaAtiva === 1 ? 'selected' : ''}
              onClick={() => setAbaAtiva(1)}
              type="button"
            >
              Financeiro
            </Aba>
            <Aba
              className={abaAtiva === 2 ? 'selected' : ''}
              onClick={() => setAbaAtiva(2)}
              type="button"
            >
              Técnico
            </Aba>
            <Aba
              className={abaAtiva === 3 ? 'selected' : ''}
              onClick={() => setAbaAtiva(3)}
              type="button"
            >
              Comercial
            </Aba>
          </>
        );
      default:
        return <></>;
    }
  }, [perfilUsuario, abaAtiva]);

  useEffect(() => {
    getProjectData();
  }, [getProjectData]);

  useEffect(() => {
    getStatusColor();
  }, [getStatusColor]);

  const history = useHistory();

  return (
    <Container>
      {loading ? (
        <Spinner size={50} />
      ) : projetoData ? (
        <>
          <HeaderHolder>
            <HeaderItem className="mid1">
              <span>#{projetoData.id}</span>
            </HeaderItem>
            <HeaderItem className="mid2">
              <span
                className={
                  perfilUsuario !== 0 && perfilUsuario <= 5 ? 'active' : ''
                }
                onClick={() => {
                  perfilUsuario !== 0 &&
                    perfilUsuario <= 5 &&
                    history.push(
                      `/cadastros/clientes/cliente/${projetoData.__cliente__.id}`
                    );
                }}
              >
                <TiBusinessCard size={20} />{' '}
                <p>{projetoData.__cliente__.nome}</p>
              </span>
            </HeaderItem>
            <HeaderItem className="mid3">
              <span
                className={
                  perfilUsuario !== 0 && perfilUsuario <= 5 ? 'active' : ''
                }
                onClick={() => {
                  perfilUsuario !== 0 &&
                    perfilUsuario <= 5 &&
                    history.push(
                      `/cadastros/lps/lps-id/${projetoData.__lps__.id}`
                    );
                }}
              >
                <MdLocationOn size={20} /> <p>{projetoData.__lps__.nome}</p>
              </span>
            </HeaderItem>
            <HeaderItem className="mid4">
              <span
                className={
                  perfilUsuario !== 0 && perfilUsuario <= 5 ? 'active' : ''
                }
                onClick={() => {
                  perfilUsuario !== 0 &&
                    perfilUsuario <= 5 &&
                    history.push(
                      `/cadastros/servicos/servico/${projetoData.__servico__.id}`
                    );
                }}
              >
                <MdBusinessCenter size={20} />{' '}
                <p>{projetoData.__servico__.nome}</p>
              </span>
            </HeaderItem>
            <HeaderItem className="mid5">
              <EscopoModal
                usuario_criador={projetoData.__usuario_criador__}
                escopo={projetoData.escopo_padrao}
                projeto_id={projetoData.id}
                update={update}
                setUpdate={setUpdate}
              >
                <span>
                  <SiGooglemessages size={20} />
                  Escopo
                </span>
              </EscopoModal>
            </HeaderItem>
            <HeaderItem className="mid6 status">
              <span style={{ backgroundColor: statusColor }} className="status">
                <StatusModal
                  statusList={statusList}
                  projeto_id={projetoData.id}
                  setUpdate={setUpdate}
                  status={projetoData.status}
                  update={update}
                >
                  {projetoData.des_status}
                </StatusModal>
              </span>
            </HeaderItem>
            <HeaderItem className="full">
              <ProgressoModal
                progresso={projetoData.progresso}
                projeto_id={projetoData.id}
                update={update}
                setUpdate={setUpdate}
              >
                <ProgressBarContainer>
                  <ProgressBarComplete
                    style={{ width: `${projetoData.progresso}%` }}
                  >
                    <ProgressBarLiquid />
                  </ProgressBarComplete>
                  <span className="progress">{projetoData.progresso}%</span>
                </ProgressBarContainer>
              </ProgressoModal>
            </HeaderItem>
          </HeaderHolder>
          <BodyHolder>
            <ContentHolder>
              <AbasHolder>{getAbas()}</AbasHolder>
              <Content>
                {abaAtiva === 1 ? (
                  <AbaFinanceiro
                    update={update}
                    setUpdate={setUpdate}
                    projeto_id={projeto_id}
                    projeto_data={projetoData}
                  />
                ) : abaAtiva === 2 ? (
                  <AbaTecnico
                    statusList={statusList}
                    usuarios={usuarios}
                    update={update}
                    setUpdate={setUpdate}
                    projeto_id={projeto_id}
                    projeto_data={projetoData}
                  />
                ) : abaAtiva === 3 ? (
                  <AbaComercial
                    update={update}
                    setUpdate={setUpdate}
                    projeto_data={projetoData}
                    perfilUsuario={perfilUsuario}
                  />
                ) : (
                  'Erro no sistema de abas...'
                )}
              </Content>
            </ContentHolder>
            <LateralHolder>
              <RegistrarHolder>
                <ModalRegistrarChat
                  setUpdate={setUpdate}
                  update={update}
                  projeto_id={projeto_id}
                  usuarios={usuarios}
                >
                  <Registrar type="button">Registrar</Registrar>
                </ModalRegistrarChat>
              </RegistrarHolder>
              <ChatHolder>
                {projetoData.chats.length === 0 ? (
                  'Nenhum Registro...'
                ) : (
                  <ChatList>
                    {projetoData.chats.map((chat, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <ChatModal
                        key={`key-${i}`}
                        id={chat.id}
                        username={chat.__usuario__.nome}
                        titulo={chat.titulo}
                        mensagem={chat.mensagem}
                        usuario_marcado_nome={chat.__usuario_marcado__?.nome}
                        updated_at={chat.updated_at}
                      >
                        <Chat>
                          <div className="column">
                            <div className="top">
                              <p>{formatDateWithHour(chat.updated_at)}</p>
                            </div>
                            <div className="info">
                              <strong>
                                {chat.__usuario__.nome}{' '}
                                {chat.__usuario_marcado__ &&
                                  `> ${chat.__usuario_marcado__.nome}`}
                              </strong>
                              <span>
                                <b>
                                  {chat.titulo}
                                  {chat.mensagem && ': '}
                                </b>
                                {chat.mensagem && chat.mensagem}
                              </span>
                            </div>
                          </div>
                        </Chat>
                      </ChatModal>
                    ))}
                  </ChatList>
                )}
              </ChatHolder>
              <AnexoHolder>
                <strong>Anexos:</strong>
                <ModalAnexos
                  update={update}
                  setUpdate={setUpdate}
                  anexos={projetoData.anexos}
                  projeto_id={projeto_id}
                >
                  <Anexo>
                    <AiOutlineFile size={50} />
                    <div>
                      <strong>Todos os anexos</strong>
                    </div>

                    <FaArrowRight size={20} />
                  </Anexo>
                </ModalAnexos>
              </AnexoHolder>
            </LateralHolder>
          </BodyHolder>
        </>
      ) : (
        'Projeto não encontrado...'
      )}
    </Container>
  );
};

export default Projeto;
