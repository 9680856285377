/* eslint-disable react/jsx-indent */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, {
  useState,
  useCallback,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';
import * as Yup from 'yup';

import Input from 'components/Input';
import { ISelectDTO, IUsuariosDTO } from 'utils/DTOS';
import Select from 'components/Select';
import Spinner from 'components/Spinner';
import { AxiosError, AxiosResponse } from 'axios';
import getValidationErrors from 'utils/getValidationErrors';
import { IoMdCheckmark } from 'react-icons/io';
import InputShow from 'components/InputShow';
import OnChangeSubmit from 'components/OnChangeSubmit';
import { FaPlus, FaSave, FaTrash, FaUserPlus } from 'react-icons/fa';
import ManyToManyModal from 'components/ManyToManyModal';
import ChecklistModal from 'components/ChecklistModal';
import { formatBRDate, formatDateInput } from 'utils/formatData';
import Textarea from 'components/Input/textarea';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

// Styled components
import {
  Holder,
  Main,
  Sidebar,
  Title,
  FormContainer,
  FormRow,
  InputHolder,
  InnerContainer,
  ResponsaveisHolder,
  Responsavel,
  Checklist,
  ChecklistStatus,
} from './styles';

interface IModalAttatchment {
  id: number;
  style?: React.CSSProperties;
  clientes?: ISelectDTO[];
  lps?: ISelectDTO[];
  servicos?: ISelectDTO[];
  usuarios?: ISelectDTO[];
}

const TarefaModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [updatee, setUpdatee] = useState(false);
  const [tarefa, setTarefa] = useState<any | undefined>(undefined);
  const [tarefaUsuarios, setTarefaUsuarios] = useState<ISelectDTO[]>([]);

  const initChanged = 0;
  const [changedStatus, setChangedStatus] = useState<number>(initChanged);
  const [changedDataEntrega, setChangedDataEntrega] =
    useState<number>(initChanged);
  const [changedDataPrazo, setChangedDataPrazo] = useState<number>(initChanged);
  const [changedCliente, setChangedCliente] = useState<number>(initChanged);
  const [changedLPS, setChangedLPS] = useState<number>(initChanged);
  const [changedServicos, setChangedServicos] = useState<number>(initChanged);

  const { children, id, style, clientes, lps, servicos, usuarios } = props;

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const getTarefa = useCallback(async () => {
    setLoading(true);

    await api
      .get(`/tarefas/full/${id}`)
      .then(async (res: AxiosResponse) => {
        res.data.cliente_id = res.data.__cliente__.id;
        res.data.lps_id = res.data.__lps__.id;
        res.data.servico_id = res.data.__servico__.id;
        res.data.data_entrega =
          res.data.data_entrega && formatDateInput(res.data.data_entrega);
        res.data.data_prazo =
          res.data.data_prazo && formatDateInput(res.data.data_prazo);
        res.data.status = res.data.status.toString();

        if (res.data.checklists) {
          for (let i = 0; i < res.data.checklists.length; i++) {
            const checklist = res.data.checklists[i];

            checklist.prazo_conclusao_original = checklist.prazo_conclusao;
            checklist.prazo_conclusao = formatDateInput(
              checklist.prazo_conclusao
            );
            checklist.prazo_inicio_original = checklist.prazo_inicio;
            checklist.prazo_inicio = formatDateInput(checklist.prazo_inicio);
            checklist.responsavel_id = checklist.__responsavel__.id;
          }
        }

        setTarefa(res.data);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description: 'Ocorreu um erro ao buscar o tarefa, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, id, updatee]);

  useEffect(() => {
    if (!visible) {
      return;
    }

    getTarefa();
  }, [getTarefa, visible]);

  const getUsuariosFromTarefa = useCallback(() => {
    if (!tarefa) {
      return;
    }
    const tmp: IUsuariosDTO[] = tarefa.__usuarios__;
    const final: ISelectDTO[] = [];

    for (let i = 0; i < tmp.length; i++) {
      const tmp1 = tmp[i];

      final.push({
        label: tmp1.nome,
        value: tmp1.id.toString(),
      });
    }

    setTarefaUsuarios(final);
  }, [tarefa, updatee]);

  useEffect(() => {
    getUsuariosFromTarefa();
  }, [getUsuariosFromTarefa]);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        api
          .put(`/tarefas/tarefa/${id}`, data)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Observação editada com sucesso',
            });
            setUpdatee(!updatee);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar a observação da tarefa`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição na observação da tarefa',
          description:
            'Ocorreu um erro ao editar a observação da tarefa, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, setUpdatee, updatee, id]
  );

  const updateChecklist = useCallback(
    async (checklist_id: number, already: boolean) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const data = {
          data_conclusao: already ? new Date(123, 123, 123) : new Date(),
        };

        api
          .put(`/checklists/checklist/${checklist_id}`, data)
          .then(res => {
            addToast({
              type: 'success',
              title: `Checklist #${checklist_id} atualizada com sucesso`,
            });
            setUpdatee(!updatee);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description:
                'Ocorreu um erro ao editar a checklist, cheque as informações e tente novamente.',
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na edição da checklist',
          description:
            'Ocorreu um erro ao editar a checklist, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, setUpdatee, updatee]
  );

  return (
    <>
      <button
        type="button"
        style={style}
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal secundary"
        wrapClassName="wrapForm"
      >
        {loading ? (
          <Spinner size={50} />
        ) : tarefa ? (
          <Holder>
            <Main>
              <InputShow
                data={{ nome: tarefa.nome }}
                name="nome"
                style={{ marginRight: '20px' }}
                editUrl="/tarefas/tarefa"
                id={tarefa.id}
                module="tarefa"
                module_label="Tarefa"
                setUpdate={setUpdatee}
                update={updatee}
              >
                <Title>{tarefa.nome}</Title>
              </InputShow>
              <InnerContainer>
                <strong id="title">
                  Responsáveis:
                  <ManyToManyModal
                    module="tarefa"
                    relation="usuario"
                    addUrl="/tarefas/adicionar-usuario"
                    removeUrl="/tarefas/remover-usuario"
                    actual_relations={tarefaUsuarios}
                    relations={usuarios || []}
                    module_id={tarefa.id.toString()}
                    setUpdate={setUpdatee}
                    update={updatee}
                  >
                    <FaUserPlus size={20} />
                  </ManyToManyModal>
                </strong>
                <ResponsaveisHolder>
                  {tarefa.__usuarios__.map((responsavel: IUsuariosDTO) => (
                    <Responsavel key={responsavel.id}>
                      {responsavel.nome}
                    </Responsavel>
                  ))}
                </ResponsaveisHolder>
                <strong id="title">Observação:</strong>
                <Form
                  initialData={tarefa}
                  onSubmit={handleSubmit}
                  ref={formRef}
                  className="observacaoForm"
                >
                  <Textarea
                    name="observacao"
                    clean
                    placeholder="Digite aqui a observação"
                  />
                  <button type="submit">
                    <FaSave size={15} />
                  </button>
                </Form>
                <strong id="title">
                  Checklists:
                  <ChecklistModal
                    tarefa_id={tarefa.id}
                    usuarios_tarefa={tarefaUsuarios}
                    setUpdate={setUpdatee}
                    update={updatee}
                  >
                    <FaPlus size={20} />
                  </ChecklistModal>
                </strong>
                {tarefa.checklists.map((checklist: any) => (
                  <Checklist key={checklist.id}>
                    <button
                      onClick={() => {
                        updateChecklist(
                          checklist.id,
                          checklist.data_conclusao?.toString() !==
                            '0133-08-01T03:06:28.000Z' &&
                            checklist.data_conclusao
                        );
                      }}
                      type="button"
                    >
                      {!checklist.data_conclusao ||
                      checklist.data_conclusao.toString() ===
                        '0133-08-01T03:06:28.000Z' ? (
                        <ChecklistStatus>
                          <IoMdCheckmark size={15} />
                        </ChecklistStatus>
                      ) : (
                        <ChecklistStatus className="concluido">
                          <IoMdCheckmark size={15} />
                        </ChecklistStatus>
                      )}
                    </button>
                    <span id="nome">
                      <ChecklistModal
                        tarefa_id={tarefa.id}
                        usuarios_tarefa={tarefaUsuarios}
                        editing
                        initialData={checklist}
                        setUpdate={setUpdatee}
                        update={updatee}
                      >
                        {checklist.__responsavel__.nome.split(' ')[0]}
                      </ChecklistModal>
                    </span>
                    <strong id="atividade">
                      <ChecklistModal
                        tarefa_id={tarefa.id}
                        usuarios_tarefa={tarefaUsuarios}
                        editing
                        initialData={checklist}
                        setUpdate={setUpdatee}
                        update={updatee}
                      >
                        {checklist.atividade}
                      </ChecklistModal>
                    </strong>
                    <span id="prazo">
                      <ChecklistModal
                        tarefa_id={tarefa.id}
                        usuarios_tarefa={tarefaUsuarios}
                        editing
                        initialData={checklist}
                        setUpdate={setUpdatee}
                        update={updatee}
                      >
                        {`${
                          checklist.prazo_inicio_original
                            ? `${formatBRDate(
                                checklist.prazo_inicio_original
                              )} `
                            : '- '
                        }
                    até ${formatBRDate(checklist.prazo_conclusao_original)}`}
                      </ChecklistModal>
                    </span>
                    <span id="cancelar">
                      <ConfirmDeleteModal
                        delete_url="/checklists/checklist"
                        id={checklist.id.toString()}
                        module="checklist"
                        setUpdate={setUpdatee}
                        update={updatee}
                      >
                        <FaTrash />
                      </ConfirmDeleteModal>
                    </span>
                  </Checklist>
                ))}
              </InnerContainer>
            </Main>
            <Sidebar>
              <FormContainer>
                <FormRow>
                  <InputHolder>
                    <label htmlFor="status">Status:</label>
                    <OnChangeSubmit
                      data={tarefa}
                      editUrl="/tarefas/tarefa"
                      id={tarefa.id}
                      module="tarefa"
                      module_label="Tarefa"
                      initChanged={initChanged}
                      changed={changedStatus}
                      setChanged={setChangedStatus}
                      update={updatee}
                      setUpdate={setUpdatee}
                    >
                      <Select
                        clean
                        name="status"
                        options={[
                          { value: 0, label: 'Iniciar' },
                          { value: 1, label: 'Andamento' },
                          { value: 2, label: 'Protocolado' },
                          { value: 3, label: 'Concluido' },
                          { value: 4, label: 'Stand-by' },
                          { value: 5, label: 'Cancelado' },
                        ]}
                        onChange={() => {
                          setChangedStatus(changedStatus + 1);
                        }}
                      />
                    </OnChangeSubmit>
                  </InputHolder>
                </FormRow>
                <FormRow>
                  <InputHolder>
                    <label htmlFor="data_entrega">Data da Entrega:</label>
                    <OnChangeSubmit
                      data={tarefa}
                      editUrl="/tarefas/tarefa"
                      id={tarefa.id}
                      module="tarefa"
                      module_label="Tarefa"
                      initChanged={initChanged}
                      changed={changedDataEntrega}
                      setChanged={setChangedDataEntrega}
                      update={updatee}
                      setUpdate={setUpdatee}
                    >
                      <Input
                        clean
                        type="date"
                        name="data_entrega"
                        onChange={() => {
                          setChangedDataEntrega(changedDataEntrega + 1);
                        }}
                      />
                    </OnChangeSubmit>
                  </InputHolder>
                </FormRow>
                <FormRow>
                  <InputHolder>
                    <label htmlFor="data_entrega">Data de Prazo:</label>
                    <OnChangeSubmit
                      data={tarefa}
                      editUrl="/tarefas/tarefa"
                      id={tarefa.id}
                      module="tarefa"
                      module_label="Tarefa"
                      initChanged={initChanged}
                      changed={changedDataPrazo}
                      setChanged={setChangedDataPrazo}
                      update={updatee}
                      setUpdate={setUpdatee}
                    >
                      <Input
                        clean
                        type="date"
                        name="data_prazo"
                        onChange={() => {
                          setChangedDataPrazo(changedDataPrazo + 1);
                        }}
                      />
                    </OnChangeSubmit>
                  </InputHolder>
                </FormRow>
                <FormRow>
                  <InputHolder>
                    <label htmlFor="cliente_id">Cliente:</label>
                    <OnChangeSubmit
                      data={tarefa}
                      editUrl="/tarefas/tarefa"
                      id={tarefa.id}
                      module="tarefa"
                      module_label="Tarefa"
                      initChanged={initChanged}
                      changed={changedCliente}
                      setChanged={setChangedCliente}
                      update={updatee}
                      setUpdate={setUpdatee}
                    >
                      <Select
                        clean
                        name="cliente_id"
                        options={clientes || []}
                        onChange={() => {
                          setChangedCliente(changedCliente + 1);
                        }}
                      />
                    </OnChangeSubmit>
                  </InputHolder>
                </FormRow>
                <FormRow>
                  <InputHolder>
                    <label htmlFor="lps_id">LPS:</label>
                    <OnChangeSubmit
                      data={tarefa}
                      editUrl="/tarefas/tarefa"
                      id={tarefa.id}
                      module="tarefa"
                      module_label="Tarefa"
                      initChanged={initChanged}
                      changed={changedLPS}
                      setChanged={setChangedLPS}
                      update={updatee}
                      setUpdate={setUpdatee}
                    >
                      <Select
                        clean
                        name="lps_id"
                        options={lps || []}
                        onChange={() => {
                          setChangedLPS(changedLPS + 1);
                        }}
                      />
                    </OnChangeSubmit>
                  </InputHolder>
                </FormRow>
                <FormRow>
                  <InputHolder>
                    <label htmlFor="servico_id">Serviços:</label>
                    <OnChangeSubmit
                      data={tarefa}
                      editUrl="/tarefas/tarefa"
                      id={tarefa.id}
                      module="tarefa"
                      module_label="Tarefa"
                      initChanged={initChanged}
                      changed={changedServicos}
                      setChanged={setChangedServicos}
                      update={updatee}
                      setUpdate={setUpdatee}
                    >
                      <Select
                        clean
                        name="servico_id"
                        options={servicos || []}
                        onChange={() => {
                          setChangedServicos(changedServicos + 1);
                        }}
                      />
                    </OnChangeSubmit>
                  </InputHolder>
                </FormRow>
              </FormContainer>
            </Sidebar>
          </Holder>
        ) : (
          `Tarefa #${id} não encontrada...`
        )}
      </Modal>
    </>
  );
};

export default TarefaModal;
