import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router';

import { useToast } from 'hooks/toast';
import api from 'services/api';
import FormConstructor, { IInput, ISaveBtn } from 'components/FormConstructor';
import getValidationErrors from 'utils/getValidationErrors';

import { ISelectDTO } from 'utils/DTOS';
import { Container, Title, Desc } from '../novo-styles';

const NovoDepartamento: React.FC = () => {
  const module = 'departamento';
  const module_label = 'Departamento';
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const saveBtn: ISaveBtn = {
    label: 'Enviar',
    loading,
  };

  const cores: ISelectDTO[] = [
    {
      label: 'Preto',
      value: 'preto',
    },
    {
      label: 'Branco',
      value: 'branco',
    },
    {
      label: 'Amarelo',
      value: 'amarelo',
    },
    {
      label: 'Laranja',
      value: 'laranja',
    },
    {
      label: 'Vermelho',
      value: 'vermelho',
    },
    {
      label: 'Roxo',
      value: 'roxo',
    },
    {
      label: 'Azul',
      value: 'azul',
    },
    {
      label: 'Verde',
      value: 'verde',
    },
  ];

  const inputs: IInput[] = [
    {
      name: 'nome',
      label: 'Nome',
      tipo: 'input',
      required: true,
    },
    {
      name: 'cor',
      label: 'Cor',
      tipo: 'select',
      selectData: cores,
    },
  ];

  const history = useHistory();

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          cor: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api
          .post(`/${module}s`, data)
          .then((res: AxiosResponse) => {
            const { id } = res.data;
            addToast({
              type: 'success',
              title: `${module_label}${
                id && ` de id: ${id}`
              } criado com sucesso`,
            });
            history.push(`/cadastros/${module}s`);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: err.message || `Erro na criação do ${module}`,
          description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [addToast, history]
  );

  return (
    <Container>
      <Title>Novo {module_label}:</Title>
      <Desc>
        Campos com <b>* (asterisco)</b> são obrigatórios
      </Desc>
      <FormConstructor
        color="quaternary"
        formColumns="1fr 1fr"
        formRef={formRef}
        inputs={inputs}
        saveBtn={saveBtn}
        submitFnc={handleSubmit}
      />
    </Container>
  );
};

export default NovoDepartamento;
