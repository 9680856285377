/* eslint-disable no-param-reassign */
import { AxiosError, AxiosResponse } from 'axios';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import EditInTable, { InputProps } from 'components/EditInTable';
import Spinner from 'components/Spinner';
import TerceiroModal from 'components/TerceiroModal';
import { format } from 'date-fns';
import { useToast } from 'hooks/toast';
import React, { useCallback, useEffect, useState } from 'react';
import { FaEye, FaPencilAlt, FaTrash } from 'react-icons/fa';
import api from 'services/api';

import { Line, List, LineItem } from 'styles/others';
import { ISelectDTO, ITerceirosProjetoDTO } from 'utils/DTOS';
import { v4 } from 'uuid';

interface IProps {
  projeto_id?: string;
}

interface ITerceiroProjeto extends ITerceirosProjetoDTO {
  editing: boolean;
  terceiro_id: number;
}

const TerceirosList2: React.FC<IProps> = props => {
  const { projeto_id } = props;

  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [terceiros, setTerceiros] = useState<ITerceiroProjeto[]>([]);
  const [terceirosData, setTerceirosData] = useState<ISelectDTO[]>([]);
  const [update, setUpdate] = useState<boolean>(false);

  const toggleEdit = useCallback(
    (id: number, state: number, index: number) => {
      const tmp_terceiro = terceiros.filter(terceiro => terceiro.id !== id);

      const editing = terceiros.find(terceiro => terceiro.id === id);

      if (!editing) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao editar o terceiro, tente novamente.',
        });
        return;
      }

      editing.editing = !editing.editing;

      state === 1 && setUpdate(!update);

      setTerceiros([editing, ...tmp_terceiro]);
    },
    [addToast, terceiros, update]
  );

  const getTerceiros = useCallback(async () => {
    setLoading(true);
    if (!projeto_id) {
      return;
    }
    await api
      .get(`/terceiros-projetos/projeto/${projeto_id}`)
      .then(async (res: AxiosResponse) => {
        res.data.forEach((dat: any) => {
          dat.editing = false;
          dat.terceiro_id = dat.__terceiro__.id;
        });

        setTerceiros(res.data);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os pagamentos do projeto, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, projeto_id, update]);

  useEffect(() => {
    getTerceiros();
  }, [getTerceiros]);

  const getTerceirosData = useCallback(async () => {
    setLoading(true);
    await api
      .get(`/terceiros`)
      .then(async (res: AxiosResponse) => {
        const final_terceiros: ISelectDTO[] = [];

        for (let i = 0; i < res.data.length; i++) {
          const tmp_terceiro = res.data[i];

          final_terceiros.push({
            label: tmp_terceiro.nome,
            value: tmp_terceiro.id.toString(),
          });
        }

        setTerceirosData(final_terceiros);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os terceiros, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast]);

  useEffect(() => {
    getTerceirosData();
  }, [getTerceirosData]);

  const inputData: InputProps[] = [
    {
      data: 'id',
      name: 'id',
      label: '#',
      tipo: 'input',
      noInput: true,
    },
    {
      data: '__terceiro__.id',
      label: 'Terceiro',
      name: 'terceiro_id',
      tipo: 'select',
      selectData: terceirosData,
    },
    {
      data: 'descricao',
      label: 'Descricao',
      name: 'descricao',
      tipo: 'input',
    },
    {
      data: '__terceiro__.telefone',
      label: 'Telefone',
      name: 'telefone',
      tipo: 'input',
      noInput: true,
    },
    {
      data: '__terceiro__.email',
      label: 'E-mail',
      name: 'email',
      tipo: 'input',
      noInput: true,
    },
  ];

  return (
    <>
      {loading ? (
        <Spinner size={15} />
      ) : terceiros.length !== 0 ? (
        <List>
          <Line
            style={{
              gridTemplateColumns: '100px 2fr 3.5fr 1.8fr 2.5fr 100px',
            }}
            className="indice"
          >
            <LineItem className="indice">#</LineItem>
            <LineItem className="indice">Terceiro</LineItem>
            <LineItem className="indice">Descrição</LineItem>
            <LineItem className="indice">Telefone</LineItem>
            <LineItem className="indice">E-mail</LineItem>
            <LineItem className="indice">Ações</LineItem>
          </Line>
          {terceiros.map((terceiro, i) =>
            terceiro.editing ? (
              <EditInTable
                key={v4()}
                update={update}
                setUpdate={setUpdate}
                data={terceiro}
                inputData={inputData}
                template="100px 2fr 3.5fr 1.8fr 2.5fr 100px"
                edit_url="/terceiros-projetos/tercerio-projeto"
                module="terceiros"
              />
            ) : (
              <Line
                style={{
                  gridTemplateColumns: '100px 2fr 3.5fr 1.8fr 2.5fr 100px',
                }}
                key={terceiro.id}
              >
                <LineItem id="index">
                  <TerceiroModal novalue terceiro={terceiro}>
                    {terceiro.id}
                  </TerceiroModal>
                </LineItem>
                <LineItem id="terceiro">
                  <TerceiroModal novalue terceiro={terceiro}>
                    {terceiro.__terceiro__.nome}
                  </TerceiroModal>
                </LineItem>
                <LineItem id="descricao">
                  <TerceiroModal novalue terceiro={terceiro}>
                    {terceiro.descricao}
                  </TerceiroModal>
                </LineItem>
                <LineItem id="telefone">
                  <TerceiroModal novalue terceiro={terceiro}>
                    {terceiro.__terceiro__.telefone}
                  </TerceiroModal>
                </LineItem>
                <LineItem style={{ textTransform: 'none' }} id="email">
                  <TerceiroModal novalue terceiro={terceiro}>
                    {terceiro.__terceiro__.email}
                  </TerceiroModal>
                </LineItem>
                <LineItem id="acoes">
                  <TerceiroModal novalue terceiro={terceiro}>
                    <FaEye />
                  </TerceiroModal>
                  <FaPencilAlt onClick={() => toggleEdit(terceiro.id, 0, i)} />
                  <ConfirmDeleteModal
                    delete_url="/terceiros-projetos/tercerio-projeto"
                    id={terceiro.id.toString()}
                    module="serviço de terceiros"
                    setUpdate={setUpdate}
                    update={update}
                  >
                    <FaTrash />
                  </ConfirmDeleteModal>
                </LineItem>
              </Line>
            )
          )}
        </List>
      ) : (
        'Nenhum terceiro encontrado para esse projeto'
      )}
    </>
  );
};

export default TerceirosList2;
