import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';
import { FaPlus } from 'react-icons/fa';

import { useToast } from 'hooks/toast';
import Input from 'components/Input';
import Select from 'components/Select';
import Textarea from 'components/Input/textarea';
import Checkbox from 'components/Checkbox';
import InputCurrency from 'components/Input/currency';
import PagamentoModal from 'components/PagamentoModal';
import api from 'services/api';
import {
  ISelectDTO,
  ITerceirosDTO,
  IClientesDTO,
  ILPSDTO,
  IServicosDTO,
  IProjetosDTO,
  IUsuariosDTO,
} from 'utils/DTOS';
import { useHistory } from 'react-router';
import Radio from 'components/Radio';
import Spinner from 'components/Spinner';
import getValidationErrors from 'utils/getValidationErrors';
import { add, format } from 'date-fns';
import TerceirosList from './TerceirosList';
import PagamentosList from './PagamentosList';

import {
  Container,
  FormContainer,
  Title,
  Desc,
  SectionTitle,
  FormRow,
  InputHolder,
  ValorHolder,
  SaldoHolder,
  NovoPagamento,
  AddButton,
  SaveButton,
} from './styles';

export interface ModalPagamentos {
  parcelas?: number;
  vencimento: Date;
  gatilho?: number; // 0 - Protocolo / 1 - Conclusão / 2 - Outros 3 / 3 - Entrega Parcial
}

export interface IPagamentos {
  id: number;
  valor_string: string;
  valor: number;
  gatilho?: number; // 0 - Protocolo / 1 - Conclusão / 2 - Outros 3 / 3 - Entrega Parcial / 4 - Outros 1 / 5 - Outros 2
  des_gatilho?: string;
  vencimento: Date;
  condicao_pagamento: number; // 0 - Parcela / 1 - Medição / 2 - Gatilho
  des_condicao_pagamento: string;
  vinculo_financeiro?: string;
}

export interface ITerceiros {
  id: number;
  nome?: string;
  valor_string: string;
  valor: number;
  descricao: string;
  terceiro_id: number;
}

interface ICadastro {
  cliente_id: number;
  lps_id: number;
  data_inicio_previsto: Date;
  servico_id: number;
  custo_estimado?: string;
  recorrente: boolean;
  tempo_contrato_mes: number;
  vinculado_id?: number;

  prazo_entrega: Date;
  escopo_padrao: string;
  observacoes_gerais?: string;

  valor_total: string;
  observacoes_pagamento?: string;
  observacao_nf?: string;
  faturar_por: string;
  recebimento: string;

  usuario_id: number;
}

const NovoProjeto: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [outrosProjetos, setOutrosProjetos] = useState<boolean>(false);
  const [haTercerios, setHaTercerios] = useState<boolean>(false);
  const [recorrente, setRecorrente] = useState<boolean>(false);
  const [contratoTerceiro, setContratoTerceiro] = useState<boolean>(false);
  const [terceiros, setTerceiros] = useState<ITerceiros[]>([]);
  const [terceirosData, setTerceirosData] = useState<ISelectDTO[]>([]);
  const [activeCondicao, setActiveCondicao] = useState<ISelectDTO>(
    {} as ISelectDTO
  );
  const [pagamentos, setPagamentos] = useState<IPagamentos[]>([]);
  const [clientesData, setClientesData] = useState<ISelectDTO[]>([]);
  const [clientes, setClientes] = useState<IClientesDTO[]>([]);
  const [selectedCliente, setSelectedCliente] = useState<
    IClientesDTO | undefined
  >();
  const [lpsData, setLpsData] = useState<ISelectDTO[]>([]);
  const [servicos, setServicos] = useState<IServicosDTO[]>([]);
  const [servicosData, setServicosData] = useState<ISelectDTO[]>([]);
  const [selectedServico, setSelectedServico] = useState<
    IServicosDTO | undefined
  >();
  const [vinculadosData, setVinculadosData] = useState<ISelectDTO[]>([]);
  const [tempValor, setTempValor] = useState<number | undefined>(undefined);
  const [tempPorcentagem, setTempPorcentagem] = useState<number | undefined>(
    undefined
  );
  const [saldo, setSaldo] = useState<number>(0);
  const [tempEncerramento, setTempEncerramento] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const condicoesData: ISelectDTO[] = [
    {
      label: 'Parcela',
      value: '0',
    },
    {
      label: 'Medição',
      value: '1',
    },
    {
      label: 'Gatilho',
      value: '2',
    },
  ];

  const { addToast } = useToast();

  const tratarValorTotal = useCallback((valor: string) => {
    const valorString = valor
      .replace('R$ ', '')
      .replace('.', '')
      .replace(',', '.');

    const split = valorString.split('.');

    if (
      split.length > 1 &&
      split[split.length - 1] &&
      split[split.length - 1].length === 3
    ) {
      split[split.length - 1] = split[split.length - 1].substring(
        0,
        split[split.length - 1].length - 1
      );
    }

    const valorFinal = parseFloat(`${split[0]}.${split[1]}`);

    return valorFinal || 0;
  }, []);

  const handleSubmit = useCallback(
    async (data: ICadastro) => {
      const final_data: any = data;

      if (saldo !== 0) {
        addToast({
          type: 'error',
          title: 'Saldo diferente de 0',
          description: 'Corrija o valor ou lance mais formas de pagamento.',
        });

        return;
      }

      if (haTercerios && terceiros.length === 0) {
        addToast({
          type: 'error',
          title: 'Sem nenhum terceiro',
          description:
            'Adicione um terceiro ou desmarque a caixa "Possui terceiros?"',
        });

        return;
      }

      final_data.custo_estimado =
        data.custo_estimado !== '' && data.custo_estimado
          ? tratarValorTotal(data.custo_estimado)
          : undefined;
      final_data.terceiros = terceiros;
      final_data.pagamentos = pagamentos;
      final_data.valor_total = tratarValorTotal(data.valor_total);
      final_data.recorrente = final_data.recorrente[0] === 'true';

      final_data.cliente_id = parseInt(final_data.cliente_id);
      final_data.lps_id = parseInt(final_data.lps_id);
      final_data.servico_id = parseInt(final_data.servico_id);
      final_data.vinculado_id =
        (final_data.vinculado_id && parseInt(final_data.vinculado_id)) ||
        undefined;
      final_data.faturar_por = parseInt(final_data.faturar_por);
      final_data.recebimento = parseInt(final_data.recebimento);

      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cliente_id: Yup.number().required('Cliente obrigatório'),
          lps_id: Yup.number().required('LPS obrigatória'),
          data_inicio_previsto: Yup.date()
            .required('Data de início previsto obrigatório')
            .typeError('Formato do campo inválido'),
          servico_id: Yup.number().required('Serviço obrigatório'),
          custo_estimado: Yup.string(),
          tempo_contrato_mes: Yup.number().typeError(
            'Formato do campo inválido'
          ),
          data_encerramento: Yup.date()
            .required('Data de encerramento do contrato obrigatória')
            .typeError('Formato do campo inválido'),
          vinculado_id: Yup.number(),
          prazo_entrega: Yup.date()
            .required('Prazo de entrega obrigatório')
            .typeError('Formato do campo inválido'),
          escopo_padrao: Yup.string().required('Escopo padrão obrigatório'),
          observacoes_gerais: Yup.string(),

          valor_total: Yup.string().required('Valor total obrigatório'),
          observacoes_pagamento: Yup.string(),
          observacao_nf: Yup.string(),
          faturar_por: Yup.number().required('Faturar por obrigatório'),
          recebimento: Yup.number().required('Tipo do recebimento obrigatório'),
        });

        await schema.validate(final_data, {
          abortEarly: false,
        });

        api
          .post('/projetos', final_data)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Projeto criado com sucesso',
            });
            history.push(`/projetos/projeto/${res.data.id}`);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description:
                'Ocorreu um erro ao criar o projeto, cheque as informações e tente novamente.',
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          console.error(errors);
        }

        console.error(`Erro: ${err}`);

        addToast({
          type: 'error',
          title: 'Erro na criação do projeto',
          description:
            'Ocorreu um erro ao criar o projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [terceiros, pagamentos, addToast, saldo, tratarValorTotal, history]
  );

  const onCheckboxChange = useCallback(
    target => {
      switch (target.name) {
        case 'outros_projetos_vinculados':
          setOutrosProjetos(!outrosProjetos);
          break;
        case 'recorrente':
          setRecorrente(!recorrente);
          break;
        case 'contrato_terceiro':
          setContratoTerceiro(!contratoTerceiro);
          break;
        case 'ha_terceiros':
          setHaTercerios(!haTercerios);
          break;

        default:
          break;
      }
    },
    [outrosProjetos, recorrente, contratoTerceiro, haTercerios]
  );

  const addTerceiro = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!formRef.current) {
        return;
      }

      const data: any = formRef.current.getData();

      const { terceiro_id, terceiro_valor, terceiro_descricao } = data;

      const findTerceiro = terceirosData.find(
        terceiro => terceiro.value === terceiro_id
      );

      if (!findTerceiro) {
        console.error('Terceiro not found');
        return;
      }

      const { label } = findTerceiro;

      const terceiro: ITerceiros = {
        id: terceiros.length + 1,
        terceiro_id: parseInt(terceiro_id),
        nome: label,
        valor_string: terceiro_valor,
        valor: tratarValorTotal(terceiro_valor),
        descricao: terceiro_descricao,
      };

      setTerceiros([...terceiros, terceiro]);

      formRef.current.clearField('terceiro_id');
      formRef.current.clearField('terceiro_valor');
      formRef.current.clearField('terceiro_descricao');
    },
    [terceiros, setTerceiros, terceirosData]
  );

  const removeTerceiro = useCallback(
    (id: number) => {
      setTerceiros(terceiros.filter(terceiro => terceiro.id !== id));
    },
    [terceiros, setTerceiros]
  );

  const condicaoChange = useCallback((data: any) => {
    setActiveCondicao(data);
  }, []);

  const servicoChange = useCallback(
    (e: any) => {
      setSelectedServico(
        servicos.find(servico => servico.id === parseInt(e.value))
      );
    },
    [setSelectedServico, servicos]
  );

  const ajustarSaldo = useCallback(
    (vtotal?: number) => {
      if (!formRef.current) {
        return;
      }

      let valor_total = 0;

      if (!vtotal) {
        valor_total = tratarValorTotal(
          formRef.current.getFieldValue('valor_total')
        );
      } else {
        valor_total = vtotal;
      }

      let tmp_saldo = valor_total;

      for (let i = 0; i < pagamentos.length; i++) {
        const pagamento = pagamentos[i];

        tmp_saldo -= pagamento.valor;
      }

      if (tmp_saldo > -0.05 && tmp_saldo < 0.05) {
        tmp_saldo = 0;
      }

      setSaldo(tmp_saldo);
    },
    [tratarValorTotal, pagamentos, setSaldo]
  );

  const valorTotalChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      ajustarSaldo(tratarValorTotal(e.target.value));
    },
    [ajustarSaldo, tratarValorTotal]
  );

  const valorChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!formRef.current) {
        return;
      }

      const valor = tratarValorTotal(e.target.value);

      const valor_total = parseFloat(
        parseFloat(
          formRef.current
            .getFieldValue('valor_total')
            .replace('R$ ', '')
            .replace('.', '')
            .replace(',', '.')
        ).toFixed(2)
      );

      if (valor > valor_total) {
        e.target.value = `R$ ${tempValor?.toString().replace('.', ',')}`;
        return;
      }

      if (valor) {
        setTempValor(valor);
      } else {
        setTempValor(0);
      }

      if (valor_total && valor) {
        setTempPorcentagem(
          parseFloat(((100 * valor) / valor_total).toFixed(2))
        );
      } else {
        setTempPorcentagem(0);
      }
    },
    [tratarValorTotal, tempValor]
  );

  const percentualChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!formRef.current) {
        return;
      }

      const valor = parseFloat(e.target.value.replace(',', '.'));

      if (valor > 100) {
        e.target.value = `R$ ${tempPorcentagem?.toString().replace('.', ',')}`;
        return;
      }

      setTempPorcentagem(valor);

      const valor_total = tratarValorTotal(
        formRef.current.getFieldValue('valor_total')
      );

      if (valor_total && valor) {
        setTempValor(parseFloat(((valor_total * valor) / 100).toFixed(2)));
      } else {
        setTempValor(0);
      }
    },
    [tratarValorTotal, tempPorcentagem]
  );

  const removePagamento = useCallback(
    (id: number) => {
      setPagamentos(pagamentos.filter(pagamento => pagamento.id !== id));
    },
    [pagamentos, setPagamentos]
  );

  const getClientes = useCallback(async () => {
    await api
      .get('/clientes/wlps')
      .then(async (res: AxiosResponse) => {
        const tmp_clientes: IClientesDTO[] = res.data;
        const final_clientes: ISelectDTO[] = [];

        for (let i = 0; i < tmp_clientes.length; i++) {
          const tmp_cliente = tmp_clientes[i];

          final_clientes.push({
            label: tmp_cliente.nome,
            value: tmp_cliente.id.toString(),
          });
        }

        setClientes(res.data);
        setClientesData(final_clientes);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os clientes, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  const getLPSFromSelectedClient = useCallback(
    async (e: any) => {
      const cliente: IClientesDTO | undefined = clientes.find(
        client => client.id.toString() === e.value
      );

      if (!cliente) {
        setLpsData([]);
        return;
      }

      setSelectedCliente(cliente);

      if (!cliente.__lps__) {
        setLpsData([]);
        return;
      }

      const tmp_Lps: ILPSDTO[] = cliente.__lps__;
      const final_Lps: ISelectDTO[] = [];

      for (let i = 0; i < tmp_Lps.length; i++) {
        const tmp_lpss = tmp_Lps[i];

        final_Lps.push({
          label: tmp_lpss.nome,
          value: tmp_lpss.id.toString(),
        });
      }

      setLpsData(final_Lps);
    },
    [clientes]
  );

  const getServicos = useCallback(async () => {
    await api
      .get('/servicos/ativos')
      .then(async (res: AxiosResponse) => {
        setServicos(res.data);
        const tmp_servicos: IServicosDTO[] = res.data;
        const final_servicos: ISelectDTO[] = [];

        for (let i = 0; i < tmp_servicos.length; i++) {
          const tmp_Servico = tmp_servicos[i];

          if (!tmp_Servico.nome) {
            return;
          }

          final_servicos.push({
            label: tmp_Servico.nome,
            value: tmp_Servico.id.toString(),
          });
        }

        setServicosData(final_servicos);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os serviços, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  const getProjetos = useCallback(async () => {
    if (!selectedCliente) {
      return;
    }

    await api
      .get(`/projetos/vincular/${selectedCliente?.id}`)
      .then(async (res: AxiosResponse) => {
        const tmp_projetos: IProjetosDTO[] = res.data;
        const final_projetos: ISelectDTO[] = [];

        for (let i = 0; i < tmp_projetos.length; i++) {
          const tmp_projeto = tmp_projetos[i];

          final_projetos.push({
            label: `${tmp_projeto.__cliente__.nome} > ${
              tmp_projeto.__lps__.nome
            } > ${tmp_projeto.id.toString()}`,
            value: tmp_projeto.id.toString(),
          });
        }

        setVinculadosData(final_projetos);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os projetos para vincular, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [selectedCliente, addToast]);

  const getTerceiros = useCallback(async () => {
    await api
      .get('/terceiros')
      .then(async (res: AxiosResponse) => {
        const tmp_terceiros: ITerceirosDTO[] = res.data;
        const final_terceiros: ISelectDTO[] = [];

        for (let i = 0; i < tmp_terceiros.length; i++) {
          const tmp_terceiro = tmp_terceiros[i];

          final_terceiros.push({
            label: tmp_terceiro.nome,
            value: tmp_terceiro.id.toString(),
          });
        }

        setTerceirosData(final_terceiros);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os terceiros, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getClientes();
    getServicos();
    getProjetos();
    getTerceiros();
  }, [getClientes, getServicos, getProjetos, getTerceiros]);

  useEffect(() => {
    ajustarSaldo();
  }, [pagamentos, setPagamentos]);

  return (
    <Container>
      <Title>Novo projeto:</Title>
      <Desc>
        Campos com <b>* (asterisco)</b> são obrigatórios
      </Desc>
      <FormContainer>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <SectionTitle>Dados gerais:</SectionTitle>
          <FormRow>
            <InputHolder style={{ width: '45%' }}>
              <label htmlFor="cliente_id">Cliente*</label>
              <Select
                name="cliente_id"
                options={clientesData}
                onChange={e => getLPSFromSelectedClient(e)}
              />
            </InputHolder>
            <InputHolder style={{ width: '30%' }}>
              <label htmlFor="lps_id">LPS*</label>
              <Select name="lps_id" options={lpsData} />
            </InputHolder>
            <InputHolder style={{ width: '25%' }}>
              <label htmlFor="data_inicio_previsto">
                Data Início Previsto*
              </label>
              <Input type="date" name="data_inicio_previsto" />
            </InputHolder>
          </FormRow>
          <FormRow>
            <InputHolder style={{ width: '60%' }}>
              <label htmlFor="servico_id">Serviço*</label>
              <Select
                name="servico_id"
                options={servicosData}
                onChange={e => servicoChange(e)}
              />
            </InputHolder>
            <InputHolder style={{ width: '40%' }}>
              <label htmlFor="custo_estimado">Custo Estimado</label>
              <InputCurrency
                placeholder="Custo estimado"
                name="custo_estimado"
              />
            </InputHolder>
          </FormRow>
          <FormRow>
            <InputHolder style={{ width: 'auto' }}>
              <Checkbox
                name="flag_outros_projetos_vinculados"
                options={[
                  {
                    name: 'outros_projetos_vinculados',
                    value: 'true',
                    label: 'Outros projetos vinculados',
                  },
                ]}
                onChange={e => {
                  onCheckboxChange(e.target);
                }}
              />
            </InputHolder>
            {outrosProjetos && (
              <InputHolder style={{ width: '60%' }}>
                <Select name="vinculado_id" options={vinculadosData} />
              </InputHolder>
            )}
          </FormRow>
          <FormRow>
            <InputHolder style={{ width: 'auto' }}>
              <Checkbox
                name="recorrente"
                options={[
                  {
                    name: 'recorrente',
                    value: 'true',
                    label: 'Recorrente',
                  },
                ]}
                onChange={e => {
                  onCheckboxChange(e.target);
                }}
              />
            </InputHolder>
            {recorrente && (
              <InputHolder style={{ width: '60%' }}>
                <Input
                  type="number"
                  name="tempo_contrato_mes"
                  placeholder="Tempo de contrato (meses)"
                  onChange={e => {
                    e.preventDefault();
                    setTempEncerramento(
                      format(
                        add(new Date(), {
                          months: parseInt(e.target.value),
                        }),
                        'yyyy-MM-dd'
                      )
                    );
                  }}
                />
              </InputHolder>
            )}
          </FormRow>
          <FormRow>
            <InputHolder style={{ width: '100%' }}>
              <label htmlFor="data_encerramento">
                Data de Encerramento de Contrato:
              </label>
              <Input
                type="date"
                name="data_encerramento"
                placeholder="Data de Encerramento de Contrato"
                value={tempEncerramento}
              />
            </InputHolder>
          </FormRow>
          <SectionTitle>Técnico:</SectionTitle>
          <FormRow>
            <InputHolder style={{ width: 'auto' }}>
              <label htmlFor="prazo_entrega">Prazo Conclusão*</label>
              <Input type="date" name="prazo_entrega" />
            </InputHolder>
          </FormRow>
          <FormRow>
            <InputHolder style={{ width: '100%' }}>
              <label htmlFor="escopo_padrao">Escopo*</label>
              <Textarea
                name="escopo_padrao"
                placeholder="Digite o escopo aqui"
                defaultValue={selectedServico && selectedServico.escopo}
              />
            </InputHolder>
          </FormRow>
          <FormRow>
            <InputHolder style={{ width: '100%' }}>
              <label htmlFor="observacoes_gerais">Observações Gerais</label>
              <Textarea
                name="observacoes_gerais"
                placeholder="Digite as observações gerais aqui"
              />
            </InputHolder>
          </FormRow>
          <SectionTitle>Terceiros:</SectionTitle>
          <FormRow>
            <InputHolder style={{ width: 'auto' }}>
              <Checkbox
                name="flag_outros_projetos_vinculados"
                options={[
                  {
                    name: 'ha_terceiros',
                    value: 'true',
                    label: 'Possui terceiros?',
                  },
                ]}
                onChange={e => {
                  onCheckboxChange(e.target);
                }}
              />
            </InputHolder>
          </FormRow>
          {haTercerios && (
            <>
              <FormRow>
                <InputHolder style={{ width: '45%' }}>
                  <label htmlFor="terceiro_id">Terceiro</label>
                  <Select name="terceiro_id" options={terceirosData} />
                </InputHolder>
                <InputHolder style={{ width: '20%' }} />
                <InputHolder style={{ width: '35%' }}>
                  <label htmlFor="terceiro_valor">Valor Contratado</label>
                  <InputCurrency
                    placeholder="Valor contratado"
                    name="terceiro_valor"
                  />
                </InputHolder>
              </FormRow>
              <FormRow>
                <InputHolder style={{ width: '100%' }}>
                  <label htmlFor="terceiro_descricao">Descrição</label>
                  <Textarea
                    name="terceiro_descricao"
                    placeholder="Digite a descrição e a forma de pagamento do serviço do terceiro"
                  />
                </InputHolder>
              </FormRow>
              <FormRow style={{ justifyContent: 'center' }}>
                <AddButton type="button" onClick={e => addTerceiro(e)}>
                  Adicionar
                </AddButton>
              </FormRow>
              <FormRow style={{ justifyContent: 'center' }}>
                <TerceirosList
                  removeFnc={removeTerceiro}
                  terceiros={terceiros}
                />
              </FormRow>
            </>
          )}
          <SectionTitle>Pagamentos:</SectionTitle>
          <FormRow style={{ alignItems: 'center' }}>
            <InputHolder style={{ width: '35%' }}>
              <ValorHolder>
                <label htmlFor="valor_total">Valor Total*:</label>
                <InputCurrency
                  placeholder="Digite o valor total"
                  name="valor_total"
                  onChange={e => valorTotalChange(e)}
                />
              </ValorHolder>
              <ValorHolder>
                <label htmlFor="saldo">Saldo:</label>
                <SaldoHolder
                  ok={!saldo || saldo === 0}
                  error={!!saldo && saldo !== 0}
                >
                  <span>
                    {saldo
                      ? `R$ ${saldo.toFixed(2).replace('.', ',')}`
                      : `R$ 0,00`}
                  </span>
                </SaldoHolder>
              </ValorHolder>
            </InputHolder>
            <NovoPagamento style={{ width: '65%' }}>
              <InputHolder style={{ width: '25%' }}>
                <label htmlFor="percentual">Percentual</label>
                <Input
                  placeholder="Percentual"
                  name="pagamento_percentual"
                  value={tempPorcentagem}
                  type="number"
                  onChange={e => percentualChange(e)}
                />
              </InputHolder>
              <InputHolder style={{ width: '25%' }}>
                <label htmlFor="valor">Valor</label>
                <InputCurrency
                  placeholder="Valor"
                  name="pagamento_valor"
                  value={tempValor}
                  onChange={e => valorChange(e)}
                />
              </InputHolder>
              <InputHolder style={{ width: '45%' }}>
                <label htmlFor="pagamento_condicao_id">Condição</label>
                <Select
                  name="pagamento_condicao_id"
                  options={condicoesData}
                  onChange={data => condicaoChange(data)}
                />
              </InputHolder>
              <InputHolder
                style={{
                  width: '5%',
                }}
              >
                <PagamentoModal
                  condicao={parseInt(activeCondicao && activeCondicao.value)}
                  des_condicao={activeCondicao && activeCondicao.label}
                  tmp_valor={tempValor}
                  pagamentos={pagamentos}
                  setPagamentos={setPagamentos}
                  form={formRef}
                  setValores={[setTempPorcentagem, setTempValor]}
                  vinculo={outrosProjetos}
                >
                  <FaPlus size={20} />
                </PagamentoModal>
              </InputHolder>
            </NovoPagamento>
          </FormRow>
          <FormRow style={{ justifyContent: 'center' }}>
            <PagamentosList
              removeFnc={removePagamento}
              pagamentos={pagamentos}
            />
          </FormRow>
          <FormRow>
            <InputHolder style={{ width: '100%' }}>
              <label htmlFor="observacoes_pagamento">
                Observação Pagamento
              </label>
              <Textarea
                name="observacoes_pagamento"
                placeholder="Digite a observação do pagamento aqui"
              />
            </InputHolder>
          </FormRow>
          <FormRow>
            <InputHolder style={{ width: '100%' }}>
              <label htmlFor="observacao_nf">Observação NF</label>
              <Textarea
                name="observacao_nf"
                placeholder="Digite a observação de nf aqui"
              />
            </InputHolder>
          </FormRow>
          <FormRow>
            <InputHolder
              style={{
                width: '40%',
                borderBottom: '1px solid var(--quaternary-color)',
                padding: '8px',
                boxShadow: '0 0 4px var(--shadow-color)',
                background: 'var(--box-color)',
                borderRadius: '8px',
              }}
            >
              <label htmlFor="faturar_por">Faturar por:</label>
              <Radio
                row
                name="faturar_por"
                options={[
                  {
                    value: '0',
                    label: 'Tecnologia',
                  },
                  {
                    value: '1',
                    label: 'Gestão',
                  },
                ]}
              />
            </InputHolder>
            <InputHolder style={{ width: '20%' }} />
            <InputHolder
              style={{
                width: '40%',
                borderBottom: '1px solid var(--quaternary-color)',
                padding: '8px',
                boxShadow: '0 0 4px var(--shadow-color)',
                background: 'var(--box-bg-color)',
                borderRadius: '8px',
              }}
            >
              <label htmlFor="recebimento">Recebimento:</label>
              <Radio
                row
                name="recebimento"
                options={[
                  {
                    value: '0',
                    label: 'Boleto',
                  },
                  {
                    value: '1',
                    label: 'Depósito',
                  },
                  {
                    value: '2',
                    label: 'Outros',
                  },
                ]}
              />
            </InputHolder>
          </FormRow>
          <FormRow style={{ justifyContent: 'center', alignItems: 'center' }}>
            <SaveButton disabled={loading} type="submit">
              {loading ? <Spinner size={15} /> : 'Enviar'}
            </SaveButton>
          </FormRow>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default NovoProjeto;
