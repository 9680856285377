import styled from 'styled-components';

export const Title = styled.div`
  width: 100%;
  height: 50px;

  border-bottom: 1px solid #bbb;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 30px 0;

  form {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const FormRow = styled.div`
  width: 100%;

  margin-bottom: 20px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const InputHolder = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  margin: 0 10px;

  &:first-of-type {
    margin-left: 0px;
  }
  &:last-of-type {
    margin-right: 0px;
  }

  .checkboxHolder {
    height: 44px;
    justify-content: center;
    align-items: flex-start;
    span {
      font-size: 1.4rem;
    }
  }

  label {
    font-size: 1.15rem;
    margin-bottom: 3px;
  }
`;

export const ChecklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: auto;
`;

export const Checklist = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 40px;
  padding: 10px 15px;

  border-bottom: 1px solid #333;

  span,
  strong,
  button {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #111;
  }

  button {
    width: 5%;
  }

  span#nome {
    width: 10%;
    font-size: 12px;
    font-weight: 300;
  }

  strong#atividade {
    width: 50%;
    font-size: 14px;
    font-weight: 500;
  }

  span#prazo {
    width: 35%;
    font-size: 12px;
    font-weight: 500;
    justify-content: flex-end;
  }
`;

export const SaveButton = styled.button`
  margin-top: 50px;

  width: 200px;
  height: 50px;

  background-color: var(--secundary-color);
  color: var(--text-color-1);
  border: none;
  border-radius: 30px;
`;

export const ModalRoot = styled.div`
  width: 100%;
  height: 100%;
`;
