import styled, { keyframes } from 'styled-components';

const g = keyframes`
  0% { background-color: var(--secundary-color); }
  50% { background-color: #ff994c; }
  100% { background-color: var(--secundary-color); }
`;

const r = keyframes`
  from { transform: rotate(0deg); }
  from { transform: rotate(360deg); }
`;

export const Container = styled.div`
  width: 100%;

  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const HeaderHolder = styled.div`
  height: 60px;
  width: calc(100% - var(--layout-items-size));

  position: absolute;
  top: var(--layout-items-size);
  left: var(--layout-items-size);

  color: var(--text-color-1);
  background: rgb(51, 51, 51);
  background: linear-gradient(
    180deg,
    rgba(51, 51, 51, 1) 90%,
    rgba(0, 0, 0, 0) 100%
  );

  display: grid;
  grid-template-columns: 1.3fr 3.5fr 3.5fr 3fr 2fr 2fr 3fr;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    top: var(--layout-mobile-items-size);
    left: 0px;
    width: 100%;
    height: auto;
    padding: 5px 0 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'mid1 mid2'
      'mid3 mid4'
      'mid5 mid6'
      'full full';
  }
`;

export const HeaderItem = styled.div`
  width: 100%;
  height: 30px;

  padding: 5px 0;

  border-right: 1px solid #ccc;

  &:last-of-type {
    border-right: 0px;
  }

  background: transparent;

  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    width: 90%;
    height: auto;
  }

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 25px;
    max-width: 250px;

    &.active {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    p {
      width: calc(100% - 25px);

      margin: 0;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }

    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-transform: capitalize;

    svg {
      width: 25px;
      margin-right: 5px;
    }

    &.status {
      line-height: 0.8;
      text-align: center;
      width: 60%;
      height: 100%;
      border-radius: 15px;
      box-shadow: 0 0 4px var(--shadow-color);
      text-shadow: 0 0 4px rgba(0, 0, 0.3);
    }
  }

  &.status {
    padding: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 5px 0;
    &:nth-child(2n) {
      border-right: 0px;
    }

    &.mid1 {
      grid-area: mid1;
    }

    &.mid2 {
      grid-area: mid2;
    }

    &.mid3 {
      grid-area: mid3;
    }

    &.mid4 {
      grid-area: mid4;
    }

    &.mid5 {
      grid-area: mid5;
    }

    &.mid6 {
      grid-area: mid6;
    }

    &.full {
      grid-area: full;
    }
  }
`;

export const ProgressBarContainer = styled.div`
  position: relative;
  width: 90%;
  border: 1px solid #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
  .progress {
    z-index: 2;
  }
`;

export const ProgressBarComplete = styled.div`
  position: absolute;
  left: 0;
  top: 0px;
  height: 100%;
  background-color: var(--secundary-color);
  border-radius: 10px;
  animation: g 2500ms infinite ease-in-out;
  z-index: 2;
`;

export const ProgressBarLiquid = styled.div`
  z-index: 1;
  width: 70px;
  height: 70px;
  animation: ${g} 2500ms infinite ease-in-out,
    ${r} 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
  position: absolute;
  right: -5px;
  top: -10px;
  background-color: var(--secundary-color);
  border-radius: 40%;
`;

export const BodyHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  height: auto;

  overflow: visible !important;

  margin-top: 60px;

  @media (max-width: 768px) {
    margin-top: 200px;
    flex-direction: column;
  }
`;

export const ContentHolder = styled.div`
  width: 75%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AbasHolder = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  height: 35px;
`;

export const Aba = styled.button`
  width: 150px;
  height: 100%;

  margin: 0 5px;

  border-radius: 10px 10px 0 0;

  background: var(--box-bg-color);
  border-right: 1px solid var(--main-color);
  border-left: 1px solid var(--main-color);

  &.selected {
    background-color: var(--main-color);
    color: var(--text-color-1);
    font-weight: 500;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: auto;

  padding: 30px 15px;

  background: var(--box-bg-color);
  box-shadow: 0 0 15px var(--shadow-color);

  border-left: 1px solid var(--main-color);

  border-radius: 5px;
`;

export const LateralHolder = styled.div`
  width: 24%;

  @media (max-width: 768px) {
    width: 100%;

    margin-top: 50px;
  }
`;

export const RegistrarHolder = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  height: 35px;
`;

export const Registrar = styled.button`
  width: 150px;
  height: 100%;

  margin: 0 5px;

  border-radius: 10px 10px 0 0;

  background: var(--main-color);
  border-bottom: 0px;

  color: var(--text-color-1);
  font-weight: 500;
`;

export const ChatHolder = styled.div`
  width: 100%;
  height: 400px;

  background: var(--box-bg-color);
  box-shadow: 0 0 15px var(--shadow-color);
  border-left: 1px solid var(--main-color);

  border-radius: 5px;

  padding: 20px 15px;

  overflow-y: scroll !important;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const ChatList = styled.ul`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  list-style: none;

  button {
    width: 100%;
    height: 80px;

    margin: 5px 0;

    border: 1px solid #777;
    box-shadow: 0 0 10px #7777774d;
    border-radius: 30px;

    padding: 8px 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    text-align: left;
  }
`;

export const Chat = styled.li`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  overflow-x: scroll;

  div.column {
    flex: 1 1;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    div.top {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      p {
        margin: 0;
        font-size: 10px;
        line-height: 1;
      }
    }
    div.info {
      height: 100%;
      width: 100%;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      overflow: hidden !important;
      strong {
        width: 100%;
        color: var(--text-color-2);
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        text-transform: capitalize;
      }
      span {
        width: 100%;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        text-transform: capitalize;

        b {
          font-weight: 600;
        }
      }
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const AnexoHolder = styled.div`
  width: 100%;
  height: auto;

  background: var(--box-bg-color);
  box-shadow: 0 0 15px var(--shadow-color);
  border-left: 1px solid var(--main-color);

  margin-top: 20px;
  border-radius: 5px;
  padding: 20px 15px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  strong {
    color: var(--text-color-2);
    font-weight: 700;
    font-size: 18px;
  }
`;

export const Anexo = styled.div`
  width: 100%;
  height: 80px;

  border: 1px solid #777;
  box-shadow: 0 0 10px #7777774d;
  border-radius: 30px;

  margin: 10px 0;
  padding: 0 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 20px;
    strong {
      font-size: 15px;
    }
    span {
      font-size: 14px;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:first-of-type {
    margin-top: 20px;
  }
`;
