/* eslint-disable no-param-reassign */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Textarea from 'components/Input/textarea';
import Select from 'components/Select';
import React, {
  SetStateAction,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import { FaSave } from 'react-icons/fa';
import { IoIosAdd } from 'react-icons/io';
import api from 'services/api';
import { IPagamentosProjetoDTO, IProjetosDTO } from 'utils/DTOS';
import { useToast } from 'hooks/toast';
import Spinner from 'components/Spinner';
import InputCurrency from 'components/Input/currency';
import { formatBRDate, formatDateInput } from 'utils/formatData';
import { AxiosResponse, AxiosError } from 'axios';
import { format } from 'date-fns';
import FormaPagamentoModal from 'components/FormaPagamentoModal';
import TerceirosModal from 'components/TerceirosModal';
import TerceirosList from '../TerceirosList';

import {
  SetorHolder,
  RowSpacedBetween,
  RowFlex,
  RowCentered,
  Title,
  NovoBtn,
  SaldoHolder,
} from './styles';
import PagamentosProjetoList from '../PagamentosList';

interface IProps {
  update: boolean;
  setUpdate: React.Dispatch<SetStateAction<boolean>>;
  projeto_id?: string;
  projeto_data?: IProjetosDTO;
}

interface IPagamentoProjeto extends IPagamentosProjetoDTO {
  editing: boolean;
  vencimentoString: string;
}

const AbaFinanceiro: React.FC<IProps> = props => {
  const { update, setUpdate, projeto_data, projeto_id } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [saldo, setSaldo] = useState<number>(0);
  const [pagamentos, setPagamentos] = useState<IPagamentoProjeto[]>([]);

  const { addToast } = useToast();

  const tratarValorTotal = useCallback((valor: string) => {
    const valorString = valor
      .replace('R$ ', '')
      .replace('.', '')
      .replace(',', '.');

    const split = valorString.split('.');

    if (
      split.length > 1 &&
      split[split.length - 1] &&
      split[split.length - 1].length === 3
    ) {
      split[split.length - 1] = split[split.length - 1].substring(
        0,
        split[split.length - 1].length - 1
      );
    }

    const valorFinal = parseFloat(`${split[0]}.${split[1]}`);

    return valorFinal || 0;
  }, []);

  const valorTotalRef = useRef<FormHandles>(null);
  const faturarPorRef = useRef<FormHandles>(null);
  const observacaoRef = useRef<FormHandles>(null);
  const observacaoNfRef = useRef<FormHandles>(null);
  const custoEstimadoRef = useRef<FormHandles>(null);
  const recebimentoRef = useRef<FormHandles>(null);

  const editValorTotal = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        dat.valor_total = tratarValorTotal(dat.valor_total);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Valor total editado com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar o valor total do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição do valor total',
          description:
            'Ocorreu um erro ao editar o valor total do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [tratarValorTotal, addToast, projeto_id, setUpdate, update]
  );

  const editFaturarPor = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Faturar por editado com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar o faturar por do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição do faturar por',
          description:
            'Ocorreu um erro ao editar o faturar por do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editObservacao = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Observação de pagamento editada com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar a observação de pagamento do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição da observação',
          description:
            'Ocorreu um erro ao editar a observação de pagamento do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editObservacaoNF = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Observação de nf editada com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar a observação de nf do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição da observação',
          description:
            'Ocorreu um erro ao editar a observação de nf do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, projeto_id, setUpdate, update]
  );

  const editCustoEstimado = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        dat.custo_estimado = tratarValorTotal(dat.custo_estimado);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Custo estimado editado com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar o custo estimado do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição do custo estimado',
          description:
            'Ocorreu um erro ao editar o custo estimado do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [tratarValorTotal, addToast, projeto_id, setUpdate, update]
  );

  const editRecebimento = useCallback(
    (dat: any) => {
      try {
        setLoading(true);

        api
          .put(`/projetos/projeto/${projeto_id}`, dat)
          .then(res => {
            addToast({
              type: 'success',
              title: 'Recebimento editado com sucesso',
            });
            setUpdate(!update);
          })
          .catch(err => {
            addToast({
              type: 'error',
              title: `Erro ao editar o recebimento do projeto`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na edição do recebimento do projeto',
          description:
            'Ocorreu um erro ao editar o recebimento do projeto, cheque as informações e tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    },
    [tratarValorTotal, addToast, projeto_id, setUpdate, update]
  );

  const getPagamentos = useCallback(async () => {
    setLoading(true);
    if (!projeto_id) {
      return;
    }
    await api
      .get(`/pagamentos-projetos/projeto/${projeto_id}`)
      .then(async (res: AxiosResponse) => {
        res.data.forEach((dat: any) => {
          dat.editing = false;
          dat.vencimentoString = formatBRDate(dat.vencimento);
          dat.vencimento = dat.vencimento
            ? formatDateInput(dat.vencimento)
            : undefined;
          if (dat.condicao_pagamento === 2 && dat.des_gatilho !== undefined) {
            dat.des_condicao_pagamento = dat.des_gatilho;
          }
        });
        setPagamentos(res.data);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os pagamentos do projeto, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, projeto_id, update]);

  useEffect(() => {
    getPagamentos();
  }, [getPagamentos]);

  const ajustarSaldo = useCallback(
    (vtotal?: number) => {
      if (!valorTotalRef.current) {
        return;
      }

      let valor_total = 0;

      if (!vtotal) {
        valor_total = tratarValorTotal(
          valorTotalRef.current.getFieldValue('valor_total')
        );
      } else {
        valor_total = vtotal;
      }

      let tmp_saldo = valor_total;

      for (let i = 0; i < pagamentos.length; i++) {
        const pagamento = pagamentos[i];

        tmp_saldo -= pagamento.valor;
      }

      if (
        (tmp_saldo < 0 && tmp_saldo > -0.01) ||
        (tmp_saldo > 0 && tmp_saldo < 0.01)
      ) {
        tmp_saldo = 0;
      }

      setSaldo(tmp_saldo);
    },
    [tratarValorTotal, pagamentos, setSaldo]
  );

  useEffect(() => {
    ajustarSaldo();
  }, [ajustarSaldo]);

  const valorTotalChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      ajustarSaldo(tratarValorTotal(e.target.value));
    },
    [ajustarSaldo, tratarValorTotal]
  );

  return (
    <SetorHolder>
      <RowFlex id="valor-total">
        <Form
          ref={valorTotalRef}
          onSubmit={editValorTotal}
          initialData={projeto_data && projeto_data}
        >
          <label htmlFor="valor_total">
            <Title>Valor total:</Title>
          </label>

          <SaldoHolder
            ok={!saldo || saldo === 0}
            error={!!saldo && saldo !== 0}
          >
            <span>
              Saldo:{' '}
              {saldo ? `R$ ${saldo.toFixed(2).replace('.', ',')}` : `R$ 0,00`}
            </span>
          </SaldoHolder>

          <InputCurrency
            style={{ width: '30%' }}
            name="valor_total"
            onChange={e => valorTotalChange(e)}
            placeholder="Digite o valor total"
          />
          <NovoBtn
            onClick={e => {
              e.preventDefault();
              valorTotalRef.current?.submitForm();
            }}
            className="justSvg"
            style={{ marginLeft: 20, width: '5%' }}
            type="button"
          >
            <FaSave size={25} />
          </NovoBtn>
        </Form>
      </RowFlex>
      <RowSpacedBetween
        style={{ marginBottom: 10 }}
        id="forma-pagamento-titulo"
      >
        <Title>Formas de Pagamento:</Title>
        {projeto_id && (
          <NovoBtn
            className="justSvg"
            style={{ marginLeft: 20, width: '5%' }}
            type="button"
          >
            <FormaPagamentoModal
              projeto_id={parseInt(projeto_id)}
              setUpdate={setUpdate}
              update={update}
            >
              <IoIosAdd size={25} />
            </FormaPagamentoModal>
          </NovoBtn>
        )}
      </RowSpacedBetween>
      <RowCentered id="forma-pagamento-tabela">
        <PagamentosProjetoList
          update={update}
          setUpdate={setUpdate}
          pagamentos={pagamentos}
          setPagamentos={setPagamentos}
          loading={loading}
        />
      </RowCentered>
      <RowFlex id="faturar-por">
        <Form
          ref={faturarPorRef}
          onSubmit={editFaturarPor}
          initialData={projeto_data && projeto_data}
        >
          <label htmlFor="faturar_por">
            <Title>Faturar por:</Title>
          </label>

          <Select
            name="faturar_por"
            options={[
              {
                value: 0,
                label: 'Tecnologia',
              },
              {
                value: 1,
                label: 'Gestão',
              },
            ]}
          />
          <NovoBtn
            onClick={e => {
              e.preventDefault();
              faturarPorRef.current?.submitForm();
            }}
            className="justSvg"
            style={{ marginLeft: 20, width: '5%' }}
            type="button"
          >
            <FaSave size={25} />
          </NovoBtn>
        </Form>
      </RowFlex>
      <RowFlex id="recebimento-por">
        <Form
          ref={recebimentoRef}
          onSubmit={editRecebimento}
          initialData={projeto_data && projeto_data}
        >
          <label htmlFor="recebimento">
            <Title>Recebimento:</Title>
          </label>

          <Select
            name="recebimento"
            options={[
              {
                value: 0,
                label: 'Boleto',
              },
              {
                value: 1,
                label: 'Depósito',
              },
              {
                value: 2,
                label: 'Outros',
              },
            ]}
          />
          <NovoBtn
            onClick={e => {
              e.preventDefault();
              recebimentoRef.current?.submitForm();
            }}
            className="justSvg"
            style={{ marginLeft: 20, width: '5%' }}
            type="button"
          >
            <FaSave size={25} />
          </NovoBtn>
        </Form>
      </RowFlex>
      <RowSpacedBetween style={{ marginBottom: 10 }} id="observacao-titulo">
        <Title>Observação Pagamento:</Title>
        <NovoBtn
          onClick={e => {
            e.preventDefault();
            observacaoRef.current?.submitForm();
          }}
          type="button"
        >
          <FaSave size={25} />
          Salvar
        </NovoBtn>
      </RowSpacedBetween>
      <RowCentered id="observacao">
        <Form
          ref={observacaoRef}
          onSubmit={editObservacao}
          initialData={projeto_data && projeto_data}
        >
          <Textarea
            name="observacoes_pagamento"
            placeholder="Digite a(s) observações de pagamento aqui..."
          />
        </Form>
      </RowCentered>
      <RowSpacedBetween style={{ marginBottom: 10 }} id="observacao_nf-titulo">
        <Title>Observação de NF:</Title>
        <NovoBtn
          onClick={e => {
            e.preventDefault();
            observacaoNfRef.current?.submitForm();
          }}
          type="button"
        >
          <FaSave size={25} />
          Salvar
        </NovoBtn>
      </RowSpacedBetween>
      <RowCentered id="observacao_nf">
        <Form
          ref={observacaoNfRef}
          onSubmit={editObservacaoNF}
          initialData={projeto_data && projeto_data}
        >
          <Textarea
            name="observacao_nf"
            placeholder="Digite a(s) observações de nf aqui..."
          />
        </Form>
      </RowCentered>
      <RowFlex id="valor-total">
        <Form
          ref={custoEstimadoRef}
          onSubmit={editCustoEstimado}
          initialData={projeto_data && projeto_data}
        >
          <label htmlFor="custo_estimado" style={{ width: '60%' }}>
            <Title>Custo estimado:</Title>
          </label>

          <InputCurrency
            style={{ width: '40%' }}
            name="custo_estimado"
            placeholder="Digite o custo estimado"
          />
          <NovoBtn
            onClick={e => {
              e.preventDefault();
              custoEstimadoRef.current?.submitForm();
            }}
            className="justSvg"
            style={{ marginLeft: 20, width: '5%' }}
            type="button"
          >
            <FaSave size={25} />
          </NovoBtn>
        </Form>
      </RowFlex>
      <RowSpacedBetween style={{ marginBottom: 10 }} id="terceiros-titulo">
        <Title>Terceiros:</Title>
        {projeto_id && (
          <NovoBtn
            className="justSvg"
            style={{ marginLeft: 20, width: '5%' }}
            type="button"
          >
            <TerceirosModal
              projeto_id={parseInt(projeto_id)}
              setUpdate={setUpdate}
              update={update}
            >
              <IoIosAdd size={25} />
            </TerceirosModal>
          </NovoBtn>
        )}
      </RowSpacedBetween>
      <RowCentered id="terceiros-tabela">
        <TerceirosList projeto_id={projeto_id} />
      </RowCentered>
    </SetorHolder>
  );
};

export default AbaFinanceiro;
