/* eslint-disable react/destructuring-assignment */
import React, { useState, useCallback, useRef, SetStateAction } from 'react';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';

import { Form } from '@unform/web';
import Select from 'components/Select';
import { FormHandles } from '@unform/core';
import Input from 'components/Input';

// Styled components
import { IPagamentos } from 'pages/NovoProjeto';
import { addMonths, parseISO } from 'date-fns';
import Textarea from 'components/Input/textarea';
import { formatDate } from 'utils/formatData';
import Checkbox from 'components/Checkbox';
import { Title, Body, FormRow, InputHolder, ConfirmButton } from './styles';

interface IModalAttatchment {
  condicao: number;
  des_condicao: string;
  tmp_valor: number | undefined;
  pagamentos: IPagamentos[];
  setPagamentos: React.Dispatch<SetStateAction<IPagamentos[]>>;
  form: React.RefObject<FormHandles>;
  setValores: React.Dispatch<SetStateAction<number | undefined>>[];
  vinculo?: boolean;
}

const PagamentoModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);

  const gatilhos = [
    {
      label: 'Protocolo',
      value: 0,
    },
    {
      label: 'Conclusão',
      value: 1,
    },
    {
      label: 'Entrega Parcial',
      value: 3,
    },
    {
      label: 'Outros 1',
      value: 4,
    },
    {
      label: 'Outros 2',
      value: 5,
    },
    {
      label: 'Outros 3',
      value: 2,
    },
  ];

  const {
    condicao,
    des_condicao,
    tmp_valor,
    pagamentos,
    setPagamentos,
    children,
    form,
    setValores,
    vinculo,
  } = props;

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    formRef.current?.reset();
    setVisible(false);
  }, []);

  const addPagamento = useCallback(() => {
    if (!formRef.current) {
      return;
    }

    const parcelas = formRef.current.getFieldValue('modal_pagamento_parcelas');
    const vencimento = formRef.current.getFieldValue(
      'modal_pagamento_vencimento'
    );
    const gatilho = formRef.current.getFieldValue('modal_pagamento_gatilho');
    const obs_gatilho = formRef.current.getFieldValue(
      'modal_pagamento_obs_gatilho'
    );
    const vinculo_financeiro =
      formRef.current.getFieldValue('modal_pagamento_vinculo_financeiro')[0] ||
      'false';

    if (tmp_valor) {
      const valores: number[] = [];

      if (parcelas) {
        for (let i = 1; i <= parcelas; i++) {
          valores.push(parseFloat((tmp_valor / parcelas).toFixed(2)));
        }
        // eslint-disable-next-line no-extra-boolean-cast
      } else if (typeof gatilho === 'number' || gatilho) {
        valores.push(parseFloat(tmp_valor.toFixed(2)));
      }

      const formattedVencimento = formatDate(vencimento);

      if (!formattedVencimento) {
        return;
      }

      let tmp_vencimento: Date = formattedVencimento;

      let pagamentos_length = pagamentos.length;

      for (let i = 0; i < valores.length; i++) {
        const valor = valores[i];

        let des_gatilho;

        if (typeof gatilho === 'number' || gatilho) {
          switch (
            gatilho // 0 - Protocolo / 1 - Conclusão / 2 - Outros 3 / 3 - Entrega Parcial
          ) {
            case 0:
              des_gatilho = 'Protocolo';
              break;
            case 1:
              des_gatilho = 'Conclusão';
              break;
            case 2:
              des_gatilho = 'Outros 3';
              break;
            case 3:
              des_gatilho = 'Entrega Parcial';
              break;
            case 4:
              des_gatilho = 'Outros 1';
              break;
            case 5:
              des_gatilho = 'Outros 2';
              break;
            default:
              console.error('Gatilho desconhecido');
              return;
          }
        }

        const tmp_pagamento = {
          id: pagamentos_length + 1,
          condicao_pagamento: condicao,
          des_condicao_pagamento: des_condicao,
          valor,
          valor_string: `R$ ${valor.toFixed(2).toString().replace('.', ',')}`,
          vencimento: tmp_vencimento,
          des_gatilho: des_gatilho || undefined,
          gatilho: typeof gatilho === 'number' ? gatilho : undefined,
          obs_gatilho: obs_gatilho.length !== 0 ? obs_gatilho : undefined,
          vinculo_financeiro,
        };

        pagamentos_length += 1;

        setPagamentos(arr => [...arr, tmp_pagamento]);

        tmp_vencimento = addMonths(tmp_vencimento, 1);
      }

      formRef.current.reset();
      form.current?.clearField('pagamento_percentual');
      form.current?.clearField('pagamento_valor');
      form.current?.clearField('pagamento_condicao_id');
      setValores.forEach(setValue => {
        setValue(0);
      });

      handleCancel();
    }
  }, [
    condicao,
    pagamentos,
    des_condicao,
    setPagamentos,
    tmp_valor,
    handleCancel,
    form,
    setValores,
  ]);

  const checkNumberInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (parseInt(e.target.value) < 1) {
        e.target.value = '1';
      }
    },
    []
  );

  return (
    <>
      <button
        type="button"
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal main"
        wrapClassName="wrapForm"
      >
        <Title>Adicionar {des_condicao}</Title>
        <Body>
          <Form ref={formRef} onSubmit={console.log}>
            {condicao === 0 ? (
              <>
                <FormRow>
                  <InputHolder style={{ width: '45%' }}>
                    <label htmlFor="modal_pagamento_parcelas">
                      Qtd Parcelas*
                    </label>
                    <Input
                      name="modal_pagamento_parcelas"
                      type="number"
                      min="1"
                      placeholder="Quantidade de parcelas"
                      onChange={e => checkNumberInput(e)}
                    />
                  </InputHolder>
                  <InputHolder style={{ width: '45%' }}>
                    <label htmlFor="modal_pagamento_vencimento">
                      1º Vencimento*
                    </label>
                    <Input name="modal_pagamento_vencimento" type="date" />
                  </InputHolder>
                </FormRow>
                {vinculo && (
                  <FormRow>
                    <InputHolder>
                      <Checkbox
                        name="modal_pagamento_vinculo_financeiro"
                        options={[
                          {
                            name: 'vinculo_financeiro',
                            value: 'true',
                            label: 'Possuí vinculo financeiro?',
                          },
                        ]}
                      />
                    </InputHolder>
                  </FormRow>
                )}
              </>
            ) : condicao === 1 ? (
              <>
                <FormRow>
                  <InputHolder style={{ width: '45%' }}>
                    <label htmlFor="modal_pagamento_parcelas">
                      Qtd Parcelas*
                    </label>
                    <Input
                      name="modal_pagamento_parcelas"
                      type="number"
                      min="1"
                      placeholder="Quantidade de parcelas"
                      onChange={e => checkNumberInput(e)}
                    />
                  </InputHolder>
                  <InputHolder style={{ width: '45%' }}>
                    <label htmlFor="modal_pagamento_vencimento">
                      1º Vencimento*
                    </label>
                    <Input name="modal_pagamento_vencimento" type="date" />
                  </InputHolder>
                </FormRow>
                {vinculo && (
                  <FormRow>
                    <InputHolder>
                      <Checkbox
                        name="modal_pagamento_vinculo_financeiro"
                        options={[
                          {
                            name: 'vinculo_financeiro',
                            value: 'true',
                            label: 'Possuí vinculo financeiro?',
                          },
                        ]}
                      />
                    </InputHolder>
                  </FormRow>
                )}
              </>
            ) : condicao === 2 ? (
              <>
                <FormRow>
                  <InputHolder style={{ width: '90%' }}>
                    <label htmlFor="modal_pagamento_gatilho">Gatilho*</label>
                    <Select name="modal_pagamento_gatilho" options={gatilhos} />
                  </InputHolder>
                </FormRow>
                <FormRow>
                  <InputHolder style={{ width: '45%' }}>
                    <label htmlFor="modal_pagamento_vencimento">
                      Previsão*
                    </label>
                    <Input name="modal_pagamento_vencimento" type="date" />
                  </InputHolder>
                </FormRow>
                <FormRow>
                  <InputHolder style={{ width: '100%' }}>
                    <label htmlFor="modal_pagamento_obs_gatilho">
                      Observação do Gatilho
                    </label>
                    <Textarea
                      name="modal_pagamento_obs_gatilho"
                      placeholder="Observação do Gatilho"
                    />
                  </InputHolder>
                </FormRow>
                {vinculo && (
                  <FormRow>
                    <InputHolder>
                      <Checkbox
                        name="modal_pagamento_vinculo_financeiro"
                        options={[
                          {
                            name: 'vinculo_financeiro',
                            value: 'true',
                            label: 'Possuí vinculo financeiro?',
                          },
                        ]}
                      />
                    </InputHolder>
                  </FormRow>
                )}
              </>
            ) : (
              'Condição não conhecida'
            )}
          </Form>
        </Body>
        <ConfirmButton type="button" onClick={() => addPagamento()}>
          Confirmar
        </ConfirmButton>
      </Modal>
    </>
  );
};

export default PagamentoModal;
