/* eslint-disable react/destructuring-assignment */
import React, { useState, useCallback, useRef, SetStateAction } from 'react';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import Select from 'components/Select';
import { FormHandles } from '@unform/core';
import Input from 'components/Input';

// Styled components
import Textarea from 'components/Input/textarea';
import { AxiosError } from 'axios';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import getValidationErrors from 'utils/getValidationErrors';
import Spinner from 'components/Spinner';
import { IUsuariosDTO } from 'utils/DTOS';
import { Title, Body, FormRow, InputHolder, ConfirmButton } from './styles';

interface IModalAttatchment {
  usuario_criador?: IUsuariosDTO;
  escopo: string;
  projeto_id: number;
  update: boolean;
  setUpdate: React.Dispatch<SetStateAction<boolean>>;
}

const EscopoModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const { escopo, projeto_id, children, update, setUpdate, usuario_criador } =
    props;

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const editEscopo = useCallback(async () => {
    if (!formRef.current) {
      return;
    }

    try {
      setLoading(true);

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        modal_escopo_escopo: Yup.string().required('Escopo obrigatório'),
      });

      await schema.validate(
        {
          modal_escopo_escopo: formRef.current.getFieldValue(
            'modal_escopo_escopo'
          ),
        },
        {
          abortEarly: false,
        }
      );

      api
        .put(`/projetos/projeto/${projeto_id}`, {
          escopo_padrao: formRef.current.getFieldValue('modal_escopo_escopo'),
        })
        .then(res => {
          addToast({
            type: 'success',
            title: 'Escopo atualizado com sucesso',
          });
          setUpdate(!update);
          handleCancel();
        })
        .catch((err: AxiosError) => {
          addToast({
            type: 'error',
            title:
              typeof err.response?.data.message === 'string'
                ? err.response?.data.message
                : 'Ocorreu um erro',
            description:
              'Ocorreu um erro ao atualizar o escopo, cheque as informações e tente novamente.',
          });
          console.error(`Erro: ${err}`);
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }

      addToast({
        type: 'error',
        title: 'Erro na atualização do escopo',
        description:
          'Ocorreu um erro ao atualizar o escopo, cheque as informações e tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, handleCancel, projeto_id, update, setUpdate]);

  const handleSubmit = useCallback(async data => {
    true;
  }, []);

  return (
    <>
      <button
        type="button"
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal main"
        wrapClassName="wrapForm"
      >
        <Title>Editar / Visualizar Escopo</Title>
        <Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormRow>
              <InputHolder style={{ width: '100%' }}>
                <label htmlFor="modal_escopo_escopo">Escopo:</label>
                <Textarea
                  name="modal_escopo_escopo"
                  placeholder="Digite o escopo aqui..."
                  defaultValue={escopo}
                />
              </InputHolder>
            </FormRow>
            {usuario_criador && (
              <FormRow>
                <InputHolder style={{ width: '100%' }}>
                  <label htmlFor="usuario_criador">
                    Usuário que cadastrou o projeto: {usuario_criador.nome}
                  </label>
                </InputHolder>
              </FormRow>
            )}
          </Form>
        </Body>
        <ConfirmButton
          disabled={loading}
          type="button"
          onClick={() => editEscopo()}
        >
          {loading ? <Spinner size={15} /> : 'Atualizar escopo'}
        </ConfirmButton>
      </Modal>
    </>
  );
};

export default EscopoModal;
