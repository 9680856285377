import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import BoxContainer from 'components/BoxContainer';
import Listagem, { IRow } from 'components/Listagem';

import { FilterHolder, FilterItemHolder } from 'styles/others';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Select from 'components/Select';
import Input from 'components/Input';
import Button from 'components/Button';
import { RiFilterOffFill } from 'react-icons/ri';
import { FaSearch } from 'react-icons/fa';
import { Container, TitleHolder, ListHolder } from '../styles';

export interface IFilter {
  nome?: string;
  cpf_cnpj?: string;
}

const Terceiros: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [filter, setFilter] = useState<IFilter | undefined>(undefined);

  const onFilter = useCallback((data: IFilter) => {
    setFilter(data);
  }, []);

  const clearFilter = useCallback(() => {
    formRef.current?.clearField('status');
    formRef.current?.clearField('lps');
    setFilter(undefined);
  }, []);

  const rows: IRow[] = [
    {
      label: '#',
      data: 'id',
    },
    {
      label: 'Nome',
      data: 'nome',
    },
    {
      label: 'CPF / CNPJ',
      data: 'cpf_cnpj',
    },
    {
      label: 'Banco',
      data: 'banco',
    },
    {
      label: 'Agência',
      data: 'agencia',
    },
    {
      label: 'Conta Corrente',
      data: 'conta_corrente',
    },
    {
      label: 'Telefone',
      data: 'telefone',
    },
    {
      label: 'E-mail',
      data: 'email',
      style: {
        textTransform: 'none',
      },
    },
  ];

  return (
    <Container>
      <TitleHolder>
        Terceiros:
        <button
          type="button"
          onClick={() => history.push('/cadastros/terceiros/novo')}
        >
          Novo terceiro
        </button>
      </TitleHolder>
      <FilterHolder>
        <Form initialData={filter} ref={formRef} onSubmit={onFilter}>
          <FilterItemHolder>
            <label htmlFor="nome">Nome</label>
            <Input name="nome" id="nome" placeholder="Digite aqui..." />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="cpf_cnpj">CPF / CNPJ</label>
            <Input name="cpf_cnpj" id="cpf_cnpj" placeholder="Digite aqui..." />
          </FilterItemHolder>
          <FilterItemHolder className="submit">
            <Button onClick={() => clearFilter()} type="button">
              <RiFilterOffFill size={18} />
            </Button>
            <Button type="submit">
              <FaSearch size={18} />
            </Button>
          </FilterItemHolder>
        </Form>
      </FilterHolder>
      <ListHolder>
        <BoxContainer borderColor="quaternary">
          <Listagem
            filter={filter}
            rows={rows}
            module="terceiros"
            template="100px 2.5fr 2fr 1.2fr 1.6fr 2fr 2.5fr 4fr"
            editUrl="/cadastros/terceiros/terceiro"
            indexUrl="/cadastros/terceiros/terceiro"
            deleteUrl="/terceiros/terceiro"
          />
          {/* <PaginationHolder>
            div.
          </PaginationHolder> */}
        </BoxContainer>
      </ListHolder>
    </Container>
  );
};

export default Terceiros;
