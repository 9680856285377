import TarefaModal from 'components/TarefaModal';
import { format } from 'date-fns';
import React, { SetStateAction, useState } from 'react';
import { FaClock } from 'react-icons/fa';
import { ITarefasDTO } from 'utils/DTOS';
import { formatBRDate } from 'utils/formatData';
import { Container, StatusColuna, Title, Tarefa } from './styles';

interface IProps {
  tarefas: ITarefasDTO[];
  update?: boolean;
  setUpdate?: React.Dispatch<SetStateAction<boolean>>;
  extraData?: any;
}

interface IStatus {
  status: number;
  des_status: string;
}

export const TarefasKanban: React.FC<IProps> = props => {
  const { tarefas, update, setUpdate, extraData } = props;
  // 0 - Iniciar / 1 - Andamento / 2 - Protocolado / 3 - Concluido / 4 - Stand-by / 5 - Cancelado
  const [status, setStatus] = useState<IStatus[]>([
    {
      status: 0,
      des_status: 'Iniciar',
    },
    {
      status: 1,
      des_status: 'Andamento',
    },
    {
      status: 2,
      des_status: 'Protocolado',
    },
    {
      status: 3,
      des_status: 'Concluido',
    },
    {
      status: 4,
      des_status: 'Stand-by',
    },
    {
      status: 5,
      des_status: 'Cancelado',
    },
  ]);

  return (
    <Container>
      {status.map(stat => (
        <StatusColuna>
          <Title>
            <strong>{stat.des_status}</strong>
          </Title>
          {tarefas.map(
            tarefa =>
              tarefa.status === stat.status && (
                <TarefaModal
                  id={tarefa.id}
                  style={{ width: '100%', marginTop: 10 }}
                  usuarios={
                    extraData && extraData.usuarios
                      ? extraData.usuarios
                      : undefined
                  }
                  lps={extraData && extraData.lps ? extraData.lps : undefined}
                  clientes={
                    extraData && extraData.clientes
                      ? extraData.clientes
                      : undefined
                  }
                  servicos={
                    extraData && extraData.servicos
                      ? extraData.usuarios
                      : undefined
                  }
                >
                  <Tarefa>
                    <strong>{tarefa.nome}</strong>
                    <span>
                      <FaClock size={15} />
                      {formatBRDate(tarefa.data_entrega)}
                    </span>
                  </Tarefa>
                </TarefaModal>
              )
          )}
        </StatusColuna>
      ))}
    </Container>
  );
};
