/* eslint-disable react/destructuring-assignment */
import React, { useState, useCallback, useRef, SetStateAction } from 'react';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Input from 'components/Input';

// Styled components
import { AxiosError } from 'axios';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import getValidationErrors from 'utils/getValidationErrors';
import Spinner from 'components/Spinner';
import { Title, Body, FormRow, InputHolder, ConfirmButton } from './styles';

interface IModalAttatchment {
  progresso: number;
  projeto_id: number;
  update: boolean;
  setUpdate: React.Dispatch<SetStateAction<boolean>>;
}

const ProgressoModal: React.FC<IModalAttatchment> = props => {
  const formRef = useRef<FormHandles>(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const { progresso, projeto_id, children, update, setUpdate } = props;

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const editProgresso = useCallback(async () => {
    if (!formRef.current) {
      return;
    }

    try {
      setLoading(true);

      formRef.current?.setErrors({});

      api
        .put(`/projetos/projeto/${projeto_id}`, {
          progresso: parseInt(
            formRef.current.getFieldValue('modal_progresso_progresso')
          ),
        })
        .then(res => {
          addToast({
            type: 'success',
            title: 'Progresso atualizado com sucesso',
          });
          setUpdate(!update);
          handleCancel();
        })
        .catch((err: AxiosError) => {
          addToast({
            type: 'error',
            title:
              typeof err.response?.data.message === 'string'
                ? err.response?.data.message
                : 'Ocorreu um erro',
            description:
              'Ocorreu um erro ao atualizar o progresso, cheque as informações e tente novamente.',
          });
          console.error(`Erro: ${err}`);
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }

      addToast({
        type: 'error',
        title: 'Erro na atualização do progresso',
        description:
          'Ocorreu um erro ao atualizar o progresso, cheque as informações e tente novamente.',
      });
      console.error(`Erro: ${err}`);
    } finally {
      setLoading(false);
    }
  }, [addToast, handleCancel, projeto_id, update, setUpdate]);

  const handleSubmit = useCallback(async data => {
    true;
  }, []);

  return (
    <>
      <button
        type="button"
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal main"
        wrapClassName="wrapForm"
      >
        <Title>Editar Progresso</Title>
        <Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormRow>
              <InputHolder style={{ width: '100%' }}>
                <label htmlFor="modal_progresso_progresso">Progresso:</label>
                <Input
                  name="modal_progresso_progresso"
                  placeholder="Digite o progresso aqui..."
                  defaultValue={progresso}
                  type="number"
                />
              </InputHolder>
            </FormRow>
          </Form>
        </Body>
        <ConfirmButton
          disabled={loading}
          type="button"
          onClick={() => editProgresso()}
        >
          {loading ? <Spinner size={15} /> : 'Atualizar progresso'}
        </ConfirmButton>
      </Modal>
    </>
  );
};

export default ProgressoModal;
