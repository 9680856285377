import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 2%;
  }
`;

export const TitleHolder = styled.h2`
  width: 100%;
  padding: 0 15px 10px;

  border-bottom: 1px solid rgb(175 175 175 / 30%);

  color: var(--text-color-2);
  font-size: 2.4rem;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 8px 15px;
    font-weight: 300;
    font-size: 1.2rem;
    text-decoration: none;
    color: var(--text-color-2);
    border-radius: 30px;
    border: 1px solid var(--text-color-2);
    transition: 0.3s all;
    box-shadow: 0 0 4px var(--shadow-color);
    background: transparent;

    &:hover {
      padding: 8px 20px;
      box-shadow: 0 0 8px var(--shadow-color);
    }
  }
`;

export const SwitchKanban = styled.div`
  width: 100%;
  height: 100%;
  flex: 1 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-size: 14px;
  color: #333;

  .switch {
    margin-left: 10px;
  }

  margin-right: 10px;
`;

export const SwitchFiltro = styled.div`
  width: 100%;
  height: 45px;
  flex: 1 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-size: 14px;
  color: #333;

  .switch {
    margin: 0 10px;
  }

  form {
    flex: 1 1;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    .selectHolder {
      width: 250px;
      height: 40px;

      margin-left: 15px;
    }

    .buttonHolder {
      width: 45px;
      height: 45px;
      margin-left: 10px;
    }

    padding: 0 10px;
  }

  padding: 0 10px;
`;

export const KanbanHolder = styled.div`
  width: 100%;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  overflow-x: scroll !important;

  padding-bottom: 20px;

  div.container {
    padding: 15px;
  }
`;

export const ListagemHolder = styled.div`
  width: 100%;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  div.container {
    padding: 15px;
  }

  span.status {
    color: #fff;
    padding: 5px 10px;
    line-height: 0.8;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 0 4px var(--shadow-color);
    text-shadow: 0 0 4px rgba(0, 0, 0.3);
  }
`;
