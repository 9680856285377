/* eslint-disable no-param-reassign */
import { AxiosError, AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FaSearch } from 'react-icons/fa';
import { FormHandles } from '@unform/core';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import { useToast } from 'hooks/toast';
import api from 'services/api';
import BoxContainer from 'components/BoxContainer';
import Select from 'components/Select';
import Input from 'components/Input';
import Button from 'components/Button';
import ListagemNoGetPaginated, {
  IPageInfo,
  IRow,
} from 'components/Listagem/no-get-paginated';
import { useHistory } from 'react-router';
import {
  IClientesDTO,
  IPaginateDTO,
  IProjetosDTO,
  ISelectDTO,
  IServicosDTO,
} from 'utils/DTOS';

import ModalEdicaoProjeto from 'components/EdicaoProjetoFinanceiroModal';
import { AiOutlineEdit } from 'react-icons/ai';
import { createFilteredUrl, IOrder, tratarValorToString } from 'utils/etc';
import { RiFilterOffFill } from 'react-icons/ri';
import { formatDateInput, formatBRDate } from 'utils/formatData';
import Checkbox from 'components/Checkbox';
import { FilterHolder, FilterItemHolder } from 'styles/others';
import { Container, TitleHolder, ListHolder, IdHolder } from './styles';

export interface IFilter {
  [key: string]: any;
  status?: string;
  cliente?: string;
  condicao?: string;
  recorrente?: string;
  projeto_id?: string;
  vencimento_inicio?: string;
  vencimento_fim?: string;
}

const Financeiro: React.FC | any = ({ location }: any) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [oldUrl, setOldUrl] = useState<string | undefined>(undefined);
  const [filter, setFilter] = useState<IFilter | undefined>({ status: '1' });
  const [update, setUpdate] = useState<boolean>(false);
  const [loadingAll, setLoadingAll] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [actualPage, setActualPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(25);
  const [pageInfo, setPageInfo] = useState<IPageInfo | undefined>(undefined);
  const [pagamentos, setPagamentos] = useState<IPaginateDTO | undefined>(
    undefined
  );
  const [clientes, setClientes] = useState<ISelectDTO[]>([]);
  const [servicos, setServicos] = useState<ISelectDTO[]>([]);
  const [projetos, setProjetos] = useState<ISelectDTO[]>([]);
  const [orderBySel, setOrderBy] = useState<string>('id');
  const [orderByDir, setOrderByDir] = useState<'ASC' | 'DESC'>('DESC');

  const history = useHistory();

  const getQueryParams = useCallback(() => {
    setFilter(undefined);
    setFilter(qs.parse(location.search, { ignoreQueryPrefix: true }));
  }, [location]);

  // useEffect(() => {
  //   getQueryParams();
  // }, [getQueryParams]);

  const getClientes = useCallback(async () => {
    await api
      .get('/clientes')
      .then(async (res: AxiosResponse) => {
        const tmp_clientes: IClientesDTO[] = res.data;
        const final_clientes: ISelectDTO[] = [];

        for (let i = 0; i < tmp_clientes.length; i++) {
          const tmp_cliente = tmp_clientes[i];

          final_clientes.push({
            label: tmp_cliente.nome,
            value: tmp_cliente.id.toString(),
          });
        }

        setClientes(final_clientes);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os clientes, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getClientes();
  }, [getClientes]);

  const getServicos = useCallback(async () => {
    await api
      .get('/servicos/ativos')
      .then(async (res: AxiosResponse) => {
        const tmp_servicos: IServicosDTO[] = res.data;
        const final_servicos: ISelectDTO[] = [];

        for (let i = 0; i < tmp_servicos.length; i++) {
          const tmp_servico = tmp_servicos[i];

          final_servicos.push({
            label: tmp_servico.nome,
            value: tmp_servico.id.toString(),
          });
        }

        setServicos(final_servicos);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os serviços, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getServicos();
  }, [getServicos]);

  const getProjetos = useCallback(async () => {
    await api
      .get('/projetos')
      .then(async (res: AxiosResponse) => {
        const tmp_projetos: IProjetosDTO[] = res.data;
        const final_projetos: ISelectDTO[] = [];

        for (let i = 0; i < tmp_projetos.length; i++) {
          const tmp_projeto = tmp_projetos[i];

          final_projetos.push({
            label: `#${tmp_projeto.id}`,
            value: tmp_projeto.id.toString(),
          });
        }

        setProjetos(final_projetos);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os pagamentos, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      });
  }, [addToast]);

  useEffect(() => {
    getProjetos();
  }, [getProjetos]);

  const rows: IRow[] = [
    {
      label: '#',
      data: 'id',
    },
    {
      label: 'Vencimento',
      data: 'vencimento_formatted',
    },
    {
      label: 'Status',
      data: 'status',
    },
    {
      label: 'Condição',
      data: 'des_condicao_pagamento',
    },
    {
      label: 'Valor',
      data: 'valor_tratado',
    },
    {
      label: 'Cliente',
      data: '__projeto__.__cliente__.nome',
    },
    {
      label: 'G/T',
      data: 'faturar_por_sigla',
    },
    {
      label: 'OS',
      data: '__projeto__.id',
    },
    {
      label: 'Dep.',
      data: 'depart',
    },
    {
      label: 'Proj. Vinc.',
      data: '__projeto__.__vinculo__.id',
    },
    {
      label: 'NF',
      data: 'nf',
    },
    {
      label: 'Rec.',
      data: 'data_pagamento_formatted',
    },
  ];

  const onFilter = useCallback(
    (data: any) => {
      if (
        (data.vencimento_inicio && !data.vencimento_fim) ||
        (!data.vencimento_inicio && data.vencimento_fim)
      ) {
        addToast({
          type: 'error',
          title: 'Selecione o início e fim da data de criação',
        });
      }

      const data_final: IFilter = data;

      data_final.recorrente = data.recorrente[0] === 'true' ? 'true' : '';

      setFilter(data_final);

      let query = '';
      Object.keys(data_final).map(key => {
        if (data_final[key]) {
          query += `${query === '' ? '?' : '&'}${key}=${data_final[key]}`;
        }
      });

      history.push({
        pathname: location.pathname,
        search: query,
      });
    },
    [addToast, location, history]
  );

  const getPagamentos = useCallback(async () => {
    setLoading(true);

    const order: IOrder = {
      orderBy: orderBySel,
      orderByDir,
    };

    const url = createFilteredUrl(
      `/pagamentos-projetos/paginated?page=${actualPage}&per_page=${perPage}`,
      filter,
      order
    );

    if (oldUrl === url) {
      return;
    }

    setOldUrl(url);

    await api
      .get(url)
      .then(async (res: AxiosResponse) => {
        const tmp_pagamentos: any = res.data.data;

        setPageInfo({
          current_page: res.data.current_page,
          last_page: res.data.last_page,
        });

        for (let i = 0; i < tmp_pagamentos.length; i++) {
          const pagamento = tmp_pagamentos[i];
          pagamento.data_pagamento =
            pagamento.data_pagamento &&
            formatDateInput(pagamento.data_pagamento);
          pagamento.data_pagamento_formatted =
            pagamento.data_pagamento && formatBRDate(pagamento.data_pagamento);
          pagamento.vencimento =
            pagamento.vencimento && formatDateInput(pagamento.vencimento);
          pagamento.vencimento_formatted =
            pagamento.vencimento && formatBRDate(pagamento.vencimento);
          pagamento.cliente_id = pagamento.__projeto__.__cliente__.id;
          pagamento.cliente_telefone =
            pagamento.__projeto__.__cliente__.telefone;
          pagamento.cliente_email = pagamento.__projeto__.__cliente__.email;
          pagamento.servico_id = pagamento.__projeto__.__servico__.id;
          pagamento.faturar_por = pagamento.__projeto__.faturar_por;
          pagamento.faturar_por_sigla =
            pagamento.__projeto__.des_faturar_por.charAt(0);
          pagamento.recebimento = pagamento.__projeto__.recebimento;
          pagamento.observacao = pagamento.__projeto__.observacoes_pagamento;
          pagamento.observacao_nf = pagamento.__projeto__.observacao_nf;
          pagamento.valor_tratado = tratarValorToString(pagamento.valor);
          const departamentos =
            pagamento.__projeto__.__servico__.__departamento__.nome.split(' ');

          let depart = '';

          departamentos.forEach((dep: string) => {
            depart += dep[0];
          });

          pagamento.depart = depart;

          if (pagamento.condicao_pagamento === 2) {
            pagamento.des_condicao_pagamento = pagamento.des_gatilho;
          }
        }
        res.data.data = tmp_pagamentos;
        setPagamentos(res.data);
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os pagamentos, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    addToast,
    actualPage,
    perPage,
    filter,
    oldUrl,
    orderByDir,
    orderBySel,
    update,
  ]);

  useEffect(() => {
    getPagamentos();
  }, [getPagamentos]);

  const getAllPagamentos = useCallback(async () => {
    setLoadingAll(true);

    const response = await api
      .get(
        createFilteredUrl(
          `/pagamentos-projetos/paginated?page=1&per_page=1000000000000000000`,
          filter
        )
      )
      .then(async (res: AxiosResponse) => {
        const tmp_pagamentos: any = res.data.data;
        for (let i = 0; i < tmp_pagamentos.length; i++) {
          const pagamento = tmp_pagamentos[i];
          pagamento.data_pagamento =
            pagamento.data_pagamento &&
            formatDateInput(pagamento.data_pagamento);
          pagamento.data_pagamento_formatted =
            pagamento.data_pagamento && formatBRDate(pagamento.data_pagamento);
          pagamento.vencimento =
            pagamento.vencimento && formatDateInput(pagamento.vencimento);
          pagamento.vencimento_formatted =
            pagamento.vencimento && formatBRDate(pagamento.vencimento);
          pagamento.cliente_id = pagamento.__projeto__.__cliente__.id;
          pagamento.cliente_telefone =
            pagamento.__projeto__.__cliente__.telefone;
          pagamento.cliente_email = pagamento.__projeto__.__cliente__.email;
          pagamento.servico_id = pagamento.__projeto__.__servico__.id;
          pagamento.faturar_por = pagamento.__projeto__.faturar_por;
          pagamento.faturar_por_sigla =
            pagamento.__projeto__.des_faturar_por.charAt(0);
          pagamento.recebimento = pagamento.__projeto__.recebimento;
          pagamento.observacao = pagamento.__projeto__.observacoes_pagamento;
          pagamento.observacao_nf = pagamento.__projeto__.observacao_nf;
          pagamento.valor_tratado = tratarValorToString(pagamento.valor);
          const departamentos =
            pagamento.__projeto__.__servico__.__departamento__.nome.split(' ');

          let depart = '';

          departamentos.forEach((dep: string) => {
            depart += dep[0];
          });

          pagamento.depart = depart;

          if (pagamento.condicao_pagamento === 2) {
            pagamento.des_condicao_pagamento = pagamento.des_gatilho;
          }
        }
        res.data.data = tmp_pagamentos;

        return res.data.data;
      })
      .catch((err: AxiosError) => {
        addToast({
          type: 'error',
          title:
            typeof err.response?.data.message === 'string'
              ? err.response?.data.message
              : 'Ocorreu um erro',
          description:
            'Ocorreu um erro ao buscar os pagamentos, tente novamente.',
        });
        console.error(`Erro: ${err}`);
      })
      .finally(() => {
        setLoadingAll(false);
      });

    return response;
  }, [addToast, actualPage, perPage, filter, update]);

  const status: ISelectDTO[] = [
    {
      label: 'Pendente',
      value: '0',
    },
    {
      label: 'Liberado',
      value: '1',
    },
    {
      label: 'Faturado',
      value: '2',
    },
    {
      label: 'Recebido',
      value: '4',
    },
  ];

  const condicao: ISelectDTO[] = [
    {
      label: 'Parcela',
      value: '0',
    },
    {
      label: 'Medição',
      value: '1',
    },
    {
      label: 'Gatilho',
      value: '2',
    },
  ];

  const clearFilter = useCallback(() => {
    setFilter(undefined);

    formRef.current?.clearField('status');
    formRef.current?.clearField('cliente');
    formRef.current?.clearField('condicao');
    formRef.current?.clearField('recorrente');
    formRef.current?.clearField('projeto_id');
    formRef.current?.clearField('vencimento_inicio');
    formRef.current?.clearField('vencimento_fim');
  }, []);

  // 'id' | 'vencimento' | 'status' | 'condicao_pagamento' | 'valor' | 'cliente' | 'projeto' | 'created_at' | 'updated_at',
  const orderBy: ISelectDTO[] = [
    {
      label: '# ID',
      value: 'id',
    },
    {
      label: 'Vencimento',
      value: 'vencimento',
    },
    {
      label: 'Status',
      value: 'status',
    },
    {
      label: 'Condição de Pagamento',
      value: 'condicao_pagamento',
    },
    {
      label: 'Valor',
      value: 'valor',
    },
    {
      label: 'Cliente',
      value: 'cliente',
    },
    {
      label: 'Projeto',
      value: 'projeto',
    },
    {
      label: 'Criação',
      value: 'created_at',
    },
    {
      label: 'Atualização',
      value: 'updated_at',
    },
  ];

  return (
    <Container>
      <TitleHolder>Financeiros:</TitleHolder>
      <FilterHolder>
        <Form initialData={{ status: '1' }} ref={formRef} onSubmit={onFilter}>
          <FilterItemHolder>
            <label htmlFor="status">Status</label>
            <Select options={status} name="status" id="status" />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="cliente">Cliente</label>
            <Select options={clientes} name="cliente" id="cliente" />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="condicao">Condição</label>
            <Select options={condicao} name="condicao" id="condicao" />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="vencimento_inicio">Vencimento - Início</label>
            <Input
              type="date"
              name="vencimento_inicio"
              id="vencimento_inicio"
            />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="vencimento_fim">Vencimento - Fim</label>
            <Input type="date" name="vencimento_fim" id="vencimento_fim" />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="projeto_id">OS</label>
            <Select options={projetos} name="projeto_id" id="projeto_id" />
          </FilterItemHolder>
          <FilterItemHolder>
            <label htmlFor="recorrente"> </label>
            <Checkbox
              options={[
                {
                  label: 'Recorrente',
                  value: 'true',
                  name: 'recorrente',
                },
              ]}
              name="recorrente"
            />
          </FilterItemHolder>
          <FilterItemHolder className="submit">
            <Button onClick={() => clearFilter()} type="button">
              <RiFilterOffFill size={18} />
            </Button>
            <button type="submit" className="buttonHolder">
              <FaSearch size={20} />
            </button>
          </FilterItemHolder>
        </Form>
      </FilterHolder>
      <ListHolder>
        <BoxContainer borderColor="quaternary">
          {/* <ProjectsList filter={filter} /> */}
          <ListagemNoGetPaginated
            customEdit
            data={pagamentos}
            otherId="projeto"
            editUrl="/projetos/projeto"
            indexUrl="/projetos/projeto"
            module="pagamento de projeto"
            template="60px 130px 120px 1fr 1fr 2.5fr 70px 70px 70px 110px 70px 130px"
            deleteUrl="/pagamentos-projetos/pagamento-projeto"
            rows={rows}
            loading={loading}
            setUpdate={setUpdate}
            update={update}
            actualPage={actualPage}
            setActualPage={setActualPage}
            perPage={perPage}
            setPerPage={setPerPage}
            pageInfo={pageInfo}
            utilsBar
            pageActions
            csvExport
            getAllData={getAllPagamentos}
            csvLoading={loadingAll}
            orderby={orderBy}
            setOrderBy={setOrderBy}
            orderByDir={orderByDir}
            setOrderByDir={setOrderByDir}
          >
            <ModalEdicaoProjeto
              pagamento={pagamentos?.data[0]}
              update={update}
              setUpdate={setUpdate}
              clientes={clientes}
              servicos={servicos}
            >
              <IdHolder>
                <AiOutlineEdit size={20} />
                {/* {pagamento.id} */}
              </IdHolder>
            </ModalEdicaoProjeto>
          </ListagemNoGetPaginated>
        </BoxContainer>
      </ListHolder>
    </Container>
  );
};

export default withRouter(Financeiro);
