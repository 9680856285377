import React from 'react';
import { IoMdClose } from 'react-icons/io';

import { Line, List, LineItem } from 'styles/others';
import { ITerceiros } from '.';

interface IProps {
  terceiros: ITerceiros[];
  removeFnc: (terceiro_id: number) => void;
}

const TerceirosList: React.FC<IProps> = props => {
  const { removeFnc, terceiros } = props;

  return (
    <>
      {terceiros.length !== 0 && (
        <List>
          <Line
            style={{
              gridTemplateColumns: '1fr 3.5fr 3fr 5fr 1fr',
            }}
            className="indice"
          >
            <LineItem className="indice">#</LineItem>
            <LineItem className="indice">Terceiro</LineItem>
            <LineItem className="indice">Valor</LineItem>
            <LineItem className="indice">Descrição</LineItem>
            <LineItem className="indice">Del.</LineItem>
          </Line>
          {terceiros.map(terceiro => (
            <Line
              style={{
                gridTemplateColumns: '1fr 3.5fr 3fr 5fr 1fr',
              }}
              key={terceiro.id}
            >
              <LineItem id="index">{terceiro.id}</LineItem>
              <LineItem id="terceiro">{terceiro.nome}</LineItem>
              <LineItem id="valor">{terceiro.valor_string}</LineItem>
              <LineItem id="descricao">{terceiro.descricao}</LineItem>
              <LineItem id="remover">
                <IoMdClose onClick={() => removeFnc(terceiro.id)} size={15} />
              </LineItem>
            </Line>
          ))}
        </List>
      )}
    </>
  );
};

export default TerceirosList;
