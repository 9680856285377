import styled from 'styled-components';

export const Title = styled.div`
  width: 100%;
  height: 50px;

  border-bottom: 1px solid #bbb;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  min-height: 150px;
`;

export const Row = styled.div`
  width: 100%;
  height: auto;

  margin: 20px 0;
  padding: 0 15px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  p {
    width: 100%;
    height: auto;
    overflow: visible;
    text-overflow: clip;
  }

  &.user_updated_at {
    width: 100%;
    height: auto;

    padding-bottom: 5px;
    border-bottom: 1px solid #bbb;
    margin: 10px 0;

    p {
      min-width: 200px;
      width: auto;
      font-size: 12px;
      color: #777;
      text-align: right;

      margin: 0;
    }
  }

  &.titulo {
    height: auto;

    p {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin: 0;
    }

    margin: 5px 0 10px;
  }

  &.mensagem {
    width: 100%;
    margin: 0;

    p {
      font-size: 14px;
      font-weight: 400;
    }

    flex: 1 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 0;
  }
`;

export const ModalRoot = styled.div`
  width: 100%;
  height: 100%;
`;
