/* eslint-disable react/destructuring-assignment */
import React, { useState, useCallback, useRef, SetStateAction } from 'react';
import 'antd/dist/antd.compact.min.css';
import { Modal } from 'antd';

import { formatDateWithHour } from 'utils/formatData';
import { Title, Body, Row } from './styles';

interface IModal {
  id: number;
  username: string;
  titulo: string;
  mensagem?: string;
  usuario_marcado_nome?: string;
  updated_at: Date;
}

const ChatModal: React.FC<IModal> = props => {
  const [visible, setVisible] = useState(false);

  const {
    id,
    username,
    titulo,
    mensagem,
    usuario_marcado_nome,
    updated_at,
    children,
  } = props;

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      <button
        type="button"
        onClick={e => {
          // To stop the page reloading
          e.preventDefault();
          // Lets track that custom click
          showModal();
        }}
      >
        {children}
      </button>
      <Modal
        visible={visible}
        footer={false}
        confirmLoading={false}
        onCancel={handleCancel}
        className="modal main"
        wrapClassName="wrapForm"
      >
        <Title>Registro #{id}:</Title>
        <Body>
          <Row className="user_updated_at">
            <p style={{ width: '100%', textAlign: 'left' }}>{`${username} ${
              usuario_marcado_nome ? `> ${usuario_marcado_nome}` : ''
            }`}</p>
            <p>{formatDateWithHour(updated_at)}</p>
          </Row>
          <Row className="titulo">
            <p>{titulo}</p>
          </Row>
          <Row className="mensagem">
            <p>{mensagem}</p>
          </Row>
        </Body>
      </Modal>
    </>
  );
};

export default ChatModal;
