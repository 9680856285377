import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router';

import { useToast } from 'hooks/toast';
import api from 'services/api';
import FormConstructor, { IInput, ISaveBtn } from 'components/FormConstructor';
import getValidationErrors from 'utils/getValidationErrors';

import { Container, Title, Desc } from '../novo-styles';

const NovoLPS: React.FC = () => {
  const module = 'lps';
  const module_label = 'LPS';
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { addToast } = useToast();

  const searchCEP = useCallback(
    event => {
      const cep = event.target.value.replace(/\D/g, '');
      api
        .get(`/extras/cep/${cep}`)
        .then((res: AxiosResponse) => {
          addToast({
            type: 'success',
            title: `CEP encontrado com sucesso!`,
            description: `Informações do endereço preenchidas!`,
          });

          formRef.current?.setFieldValue('bairro', res.data.bairro);
          formRef.current?.setFieldValue(
            'endereco',
            `${res.data.logradouro} - ${res.data.complemento}`
          );
          formRef.current?.setFieldValue('cidade', res.data.localidade);
        })
        .catch((err: AxiosError) => {
          addToast({
            type: 'error',
            title:
              typeof err.response?.data.message === 'string'
                ? err.response?.data.message.charAt(0).toUpperCase() +
                  err.response?.data.message.slice(1)
                : 'Ocorreu um erro',
            description: `Ocorreu um erro ao buscar o cep, cheque as informações e tente novamente.`,
          });
          console.error(`Erro: ${err}`);
        });
    },
    [addToast]
  );

  const saveBtn: ISaveBtn = {
    label: 'Enviar',
    loading,
  };

  const inputs: IInput[] = [
    {
      name: 'nome',
      label: 'Nome',
      tipo: 'input',
      required: true,
    },
    {
      name: 'cep',
      label: 'CEP',
      tipo: 'mask',
      mask: '99999-999',
      required: true,
      onBlurFnc: searchCEP,
    },
    {
      name: 'endereco',
      label: 'Endereço',
      tipo: 'input',
      required: true,
    },
    {
      name: 'bairro',
      label: 'Bairro',
      tipo: 'input',
      required: true,
    },
    {
      name: 'cidade',
      label: 'Cidade',
      tipo: 'input',
      required: true,
    },
    {
      name: 'contato_tecnico',
      label: 'Contato Técnico',
      tipo: 'input',
      required: true,
    },
    {
      name: 'telefone_tecnico',
      label: 'Telefone Técnico',
      tipo: 'phone',
      required: true,
    },
    {
      name: 'email_tecnico',
      label: 'E-mail Técnico',
      tipo: 'input',
      type: 'email',
      required: true,
    },
    {
      name: 'contato_tecnico_2',
      label: 'Contato Técnico 2',
      tipo: 'input',
      required: false,
    },
    {
      name: 'telefone_tecnico_2',
      label: 'Telefone Técnico 2',
      tipo: 'phone',
      required: false,
    },
    {
      name: 'email_tecnico_2',
      label: 'E-mail Técnico 2',
      tipo: 'input',
      type: 'email',
      required: false,
    },
    {
      name: 'contato_tecnico_3',
      label: 'Contato Técnico 3',
      tipo: 'input',
      required: false,
    },
    {
      name: 'telefone_tecnico_3',
      label: 'Telefone Técnico 3',
      tipo: 'phone',
      required: false,
    },
    {
      name: 'email_tecnico_3',
      label: 'E-mail Técnico 3',
      tipo: 'input',
      type: 'email',
      required: false,
    },
  ];

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          endereco: Yup.string().required('Endereço obrigatório'),
          bairro: Yup.string().required('Bairro obrigatório'),
          cidade: Yup.string().required('Cidade obrigatória'),
          cep: Yup.string()
            .required('CEP obrigatório')
            .matches(/^\d{5}-\d{3}$/, 'Formato do cep inválido'),
          contato_tecnico: Yup.string().required('Nome do contato obrigatório'),
          telefone_tecnico: Yup.string().required(
            'Telefone do técnico obrigatório'
          ),
          email_tecnico: Yup.string()
            .email('E-mail inválido')
            .required('E-mail obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api
          .post(`/${module}`, data)
          .then((res: AxiosResponse) => {
            const { id } = res.data;
            addToast({
              type: 'success',
              title: `${module_label}${
                id && ` de id: ${id}`
              } criado com sucesso`,
            });
            history.push(`/cadastros/${module}`);
          })
          .catch((err: AxiosError) => {
            addToast({
              type: 'error',
              title:
                typeof err.response?.data.message === 'string'
                  ? err.response?.data.message.charAt(0).toUpperCase() +
                    err.response?.data.message.slice(1)
                  : 'Ocorreu um erro',
              description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
            });
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: err.message || `Erro na criação do ${module}`,
          description: `Ocorreu um erro ao criar o ${module}, cheque as informações e tente novamente.`,
        });
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [addToast, history]
  );

  return (
    <Container>
      <Title>Novo {module_label}:</Title>
      <Desc>
        Campos com <b>* (asterisco)</b> são obrigatórios
        <br />
        Para um preenchimento mais rápido, preencha o <b>cep</b> e clique fora
        da <b>caixa de texto</b>, para preencher as informações encontradas
      </Desc>
      <FormConstructor
        color="quaternary"
        formColumns="1fr 1fr"
        formRef={formRef}
        inputs={inputs}
        saveBtn={saveBtn}
        submitFnc={handleSubmit}
      />
    </Container>
  );
};

export default NovoLPS;
